import React, { Component } from "react";
// import "./Analysis.css";
import ReactEcharts from "echarts-for-react";
import AutoFitImage from 'react-image-autofit-frame';
// import Figure from 'react-bootstrap/Figure';
// import FigureImage from 'react-bootstrap/FigureImage';
// import FigureCaption from 'react-bootstrap/FigureCaption'

import airallhist from  "./figure/air_all_hist.png";
import cohists from  "./figure/CO hist s.png";import cohistb from  "./figure/CO hist b.png";
import so2hists from "./figure/SO2 hist s.png";import so2histb from "./figure/SO2 hist b.png";
import no2hists from  "./figure/NO2 hist s.png";import no2histb from  "./figure/NO2 hist b.png";
import ozonehists from "./figure/Ozone hist s.png";import ozonehistb from "./figure/Ozone hist b.png";
import pm10hists from  "./figure/PM10 hist s.png";import pm10histb from  "./figure/PM10 hist b.png";
import pm25hists from "./figure/PM25 hist s.png";import pm25histb from "./figure/PM25 hist b.png";

import coboxb from  "./figure/CO box b.png";import ozoneboxb from  "./figure/Ozone box b.png";
import so2boxb from "./figure/SO2 box b.png";import pm10boxb from "./figure/PM10 box b.png";
import no2boxb from  "./figure/NO2 box b.png";import pm25boxb from  "./figure/PM25 box b.png";

import cohist from  "./figure/CO_hist_annual.png";import ozonehist from  "./figure/Ozone_hist_annual.png";
import so2hist from "./figure/SO2_hist_annual.png";import pm10hist from "./figure/PM10_hist_annual.png";
import no2hist from  "./figure/NO2_hist_annual.png";import pm25hist from  "./figure/PM25_hist_annual.png";

import reshist from  "./figure/Acute Lower Respiratory Infection_hist.png";
import hearthist from  "./figure/Ischaemic Heart Disease_hist.png";
import lunghist from  "./figure/Lung Cancer_hist.png";
import copdhist from  "./figure/COPD_hist.png";
import strokehist from  "./figure/Stroke_hist.png";

import spearman from  "./figure/scatter air vs. disease/Spearman Rho.png";import COLung from  "./figure/scatter air vs. disease/COLung.png";import COStroke from  "./figure/scatter air vs. disease/COStroke.png";
import NO2Lung from  "./figure/scatter air vs. disease/NO2Lung.png";import NO2Stroke from  "./figure/scatter air vs. disease/NO2Stroke.png";import PM10Lung from  "./figure/scatter air vs. disease/PM10Lung.png";
import PM10Stroke from  "./figure/scatter air vs. disease/PM10Stroke.png";import PM25Heart from  "./figure/scatter air vs. disease/PM25Heart.png";import PM25Lung from  "./figure/scatter air vs. disease/PM25Lung.png";
import PM25Stroke from  "./figure/scatter air vs. disease/PM25Stroke.png";import SO2Lung from  "./figure/scatter air vs. disease/SO2Lung.png";import SO2Stroke from  "./figure/scatter air vs. disease/SO2Stroke.png";



import ImageZoom from 'react-medium-image-zoom';
// import ImageLayout from 'react-image-layout';
// import { render } from 'react-dom';
// import Gallery from 'react-grid-gallery';
import ScrollUpButton from "react-scroll-up-button"; 
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import mapDataUS from './us-all.js';

// insert image
console.log(cohists);console.log(cohistb);console.log(so2hists);console.log(so2histb);console.log(no2hists);console.log(no2histb);
console.log(ozonehists);console.log(ozonehistb);console.log(pm10hists);console.log(pm10histb);console.log(pm25hists);console.log(pm25histb);
console.log(coboxb);console.log(so2boxb);console.log(no2boxb);console.log(ozoneboxb);console.log(pm10boxb);console.log(pm25boxb);
console.log(cohist);console.log(so2hist);console.log(no2hist);console.log(ozonehist);console.log(pm10hist);console.log(pm25hist);
console.log(airallhist);console.log(reshist);console.log(hearthist);console.log(lunghist);console.log(copdhist);console.log(strokehist);
console.log(spearman);console.log(COLung);console.log(COStroke);console.log(NO2Lung);console.log(NO2Stroke);console.log(PM10Lung);
console.log(PM10Stroke);console.log(PM25Heart);console.log(PM25Lung);console.log(PM25Stroke);console.log(SO2Lung);console.log(SO2Stroke);



export class Analysis extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            graphOption: {
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line'
                }]
            }
        }
    }


componentDidMount() {
    // this.echartsInstance = this.echartsReactRef.getEchartsInstance();
    // this.zr = this.echartsInstance.getZr();
    //this.zr.on('click', this.onChartClick);
}

onChartClick = (...rest) => {
    console.log('App:onClickChart', rest);
};
    render() {
        return (    
            <div className="Analysis">
            <div className="lander">
                <header className="App-header">
                <h1>Air Pollutants Data Exploration</h1>
                    {/* <ReactEcharts
                        style={{height: '50vh', width: '80vw'}}
                        ref={(e) => {
                            this.echartsReactRef = e;
                        }}
                        option={this.state.graphOption}
                    /> */}
                {/* <p>A serverless air quality visualising app</p> */}
                <h3>Histogram of Daily/Annual Mean of Air Pollutants Concentration</h3>                         
                    {/* <Figure>
                    <Figure.Image
                        width={1000}
                        height={400}
                        alt="airallhist"
                        src={airallhist} 
                    /> */}
                    {/* <Figure.Caption>
                        Histogram of Daily/Annual Mean of Air Pollutants Concentration of All 51 States (1999-2017)
                    </Figure.Caption> */}
                    {/* </Figure> */}
                <h3>Daily Air Pollutants Concentration</h3>                         
                <h5>Left/Middle: Daily Max 1-hour SO2/NO2 Concentration (Unit: parts per billion (ppb))
                <br />Right: Daily Max 8-hour CO Concentration (Unit: parts per million (ppm))</h5>
                    <ImageZoom
                        image={{
                        src: so2histb,
                        alt: 'so2histb',
                        className: 'img',
                        style: { width: '22em' , height:'20em',margin: '12px',borderColor: '#F44336',}
                        }}
                        zoomImage={{
                        src: so2histb,
                        alt: 'so2histb',
                        // style: { width: '44em' , height:'80em'}
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: no2histb,
                        alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: no2histb,
                        alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />                   
                    <ImageZoom
                        image={{
                        src: cohistb,
                        alt: 'cohistb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: cohistb,
                        alt: 'cohistb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                <h5>Left: Daily Max 8-hour Ozone Concentration (Unit: parts per million (ppm))
                <br />Middle/Right: Daily Mean PM10/PM2.5 Concentration (Unit: μg/m3)</h5>
                    <ImageZoom
                        image={{
                        src: ozonehistb,
                        alt: 'ozonehistb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: ozonehistb,
                        alt: 'ozonehistb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />    
                    <ImageZoom
                        image={{
                        src: pm10histb,
                        alt: 'pm10histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: pm10histb,
                        alt: 'pm10histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />  
                    <ImageZoom
                        image={{
                        src: pm25histb,
                        alt: 'pm25histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: pm25histb,
                        alt: 'pm25histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                {/* <h3>Boxplot of Daily Air Pollutants Concentration</h3>                          */}
                <h5>Left/Middle: Daily Max 1-hour SO2/NO2 Concentration (Unit: parts per billion (ppb))
                <br/>Right: Daily Max 8-hour CO Concentration (Unit: parts per million (ppm))</h5>             
                    <ImageZoom
                        image={{
                        src: so2boxb,
                        alt: 'so2boxb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: so2boxb,
                        alt: 'so2boxb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: no2boxb,
                        alt: 'no2boxb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: no2boxb,
                        alt: 'no2boxb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />                   
                    <ImageZoom
                        image={{
                        src: coboxb,
                        alt: 'coboxb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: coboxb,
                        alt: 'coboxb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <h5>Left: Daily Max 8-hour Ozone Concentration (Unit: parts per million (ppm))
                    <br/>Middle/Right: Daily Mean PM10/PM2.5 Concentration (Unit: μg/m3)</h5>
                    <ImageZoom
                        image={{
                        src: ozoneboxb,
                        alt: 'ozoneboxb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: ozoneboxb,
                        alt: 'ozoneboxb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />    
                    <ImageZoom
                        image={{
                        src: pm10boxb,
                        alt: 'pm10boxb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: pm10boxb,
                        alt: 'pm10boxb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />  
                    <ImageZoom
                        image={{
                        src: pm25boxb,
                        alt: 'pm25boxb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: pm25boxb,
                        alt: 'pm25boxb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                <h3>Annual Mean of Air Pollutants Concentration</h3>                         
                <h5>Left/Middle: Annual Mean of Daily Max 1-hour SO2/NO2 Concentration (Unit: parts per billion (ppb))
                <br/>Right: Annual Mean of Daily Max 8-hour CO Concentration (Unit: parts per million (ppm))</h5>
                    <ImageZoom
                        image={{
                        src: so2hist,
                        alt: 'so2hist',
                        className: 'img',
                        style: { width: '22em' , height:'20em',margin: '12px',}
                        }}
                        zoomImage={{
                        src: so2hist,
                        alt: 'so2hist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: no2hist,
                        alt: 'no2hist',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: no2hist,
                        alt: 'no2hist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />                   
                    <ImageZoom
                        image={{
                        src: cohist,
                        alt: 'cohist',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: cohist,
                        alt: 'cohist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                <h5>Left: Annual Mean of Daily Max 8-hour Ozone Concentration (Unit: parts per million (ppm))
                <br/>Middle/Right: Annual Mean of Daily Mean PM10/PM2.5 Concentration (Unit: μg/m3)</h5>
                    <ImageZoom
                        image={{
                        src: ozonehist,
                        alt: 'ozonehist',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: ozonehist,
                        alt: 'ozonehist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />    
                    <ImageZoom
                        image={{
                        src: pm10hist,
                        alt: 'pm10hist',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: pm10hist,
                        alt: 'pm10hist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />  
                    <ImageZoom
                        image={{
                        src: pm25hist,
                        alt: 'pm25hist',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'20em'}
                        }}
                        zoomImage={{
                        src: pm25hist,
                        alt: 'pm25hist'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                <h3>Distribution of Deaths of Five Diseases</h3>                         
                <h5>Acute Lower Respiratory Infection,Ischaemic Heart Disease, Lung Cancer, Chronic Obstructive Pulmonary Disease (COPD), Stroke</h5>
                    <ImageZoom
                        image={{
                        src: reshist,
                        alt: 'reshist',
                        className: 'img',
                        style: { width: '13em' ,margin: '10px',}
                        }}
                        zoomImage={{
                        src: reshist,
                        alt: 'reshist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: hearthist,
                        alt: 'hearthist',
                        className: 'img',
                        style: { width: '13em',margin: '10px', }
                        }}
                        zoomImage={{
                        src: hearthist,
                        alt: 'hearthist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: lunghist,
                        alt: 'lunghist',
                        className: 'img',
                        style: { width: '13em' ,margin: '10px',}
                        }}
                        zoomImage={{
                        src: lunghist,
                        alt: 'lunghist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: copdhist,
                        alt: 'copdhist',
                        className: 'img',
                        style: { width: '13em',margin: '10px', }
                        }}
                        zoomImage={{
                        src: copdhist,
                        alt: 'copdhist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: strokehist,
                        alt: 'strokehist',
                        className: 'img',
                        style: { width: '13em' ,margin: '10px',}
                        }}
                        zoomImage={{
                        src: strokehist,
                        alt: 'strokehist'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                <h3>Spearman's Rank Order Correlation Coefficient</h3>  
                    {/* <Figure>
                    <Figure.Image
                        width={650}
                        height={400}
                        alt="spearman"
                        src={spearman} 
                    /> */}
                    {/* <Figure.Caption>
                        Histogram of Daily/Annual Mean of Air Pollutants Concentration of All 51 States (1999-2017)
                    </Figure.Caption> */}
                    {/* </Figure> */}
                <h5>Carbon Monoxide (CO) vs. Lung Cancer, Stroke</h5>
                    <ImageZoom
                        image={{
                        src: COLung,
                        alt: 'COLung',
                        className: 'img',
                        style: { width: '33em' ,margin: '20px',}
                        }}
                        zoomImage={{
                        src: COLung,
                        alt: 'COLung'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: COStroke,
                        alt: 'COStroke',
                        className: 'img',
                        style: { width: '33em' ,margin: '20px'}
                        }}
                        zoomImage={{
                        src: COStroke,
                        alt: 'COStroke'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                <h5>Nitrogen dioxide (NO2) vs. Lung Cancer, Stroke</h5>
                    <ImageZoom
                        image={{
                        src: NO2Lung,
                        alt: 'NO2Lung',
                        className: 'img',
                        style: { width: '33em',margin: '20px' }
                        }}
                        zoomImage={{
                        src: NO2Lung,
                        alt: 'NO2Lung'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: NO2Stroke,
                        alt: 'NO2Stroke',
                        className: 'img',
                        style: { width: '33em',margin: '20px' }
                        }}
                        zoomImage={{
                        src: NO2Stroke,
                        alt: 'NO2Stroke'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                <h5>Particulate Matter 10 (PM10) vs. Lung Cancer, Stroke</h5>
                    <ImageZoom
                        image={{
                        src: PM10Lung,
                        alt: 'PM10Lung',
                        className: 'img',
                        style: { width: '33em' ,margin: '20px'}
                        }}
                        zoomImage={{
                        src: PM10Lung,
                        alt: 'PM10Lung'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: PM10Stroke,
                        alt: 'PM10Stroke',
                        className: 'img',
                        style: { width: '33em' ,margin: '20px'}
                        }}
                        zoomImage={{
                        src: PM10Stroke,
                        alt: 'PM10Stroke'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                <h5>Particulate Matter 2.5 (PM2.5) vs. Ischaemic Heart Disease, Lung Cancer, Stroke</h5>
                    <ImageZoom
                        image={{
                        src: PM25Heart,
                        alt: 'PM25Heart',
                        className: 'img',
                        style: { width: '22em' ,margin: '12px'}
                        }}
                        zoomImage={{
                        src: PM25Heart,
                        alt: 'PM25Heart'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: PM25Lung,
                        alt: 'PM25Lung',
                        className: 'img',
                        style: { width: '22em' ,margin: '12px'}
                        }}
                        zoomImage={{
                        src: PM25Lung,
                        alt: 'PM25Lung'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: PM25Stroke,
                        alt: 'PM25Stroke',
                        className: 'img',
                        style: { width: '22em' ,margin: '12px'}
                        }}
                        zoomImage={{
                        src: PM25Stroke,
                        alt: 'PM25Stroke'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                <h5>Sulfur dioxide (SO2) vs. Lung Cancer, Stroke</h5>
                    <ImageZoom
                        image={{
                        src: SO2Lung,
                        alt: 'SO2Lung',
                        className: 'img',
                        style: { width: '33em',margin: '20px' }
                        }}
                        zoomImage={{
                        src: SO2Lung,
                        alt: 'SO2Lung'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: SO2Stroke,
                        alt: 'SO2Stroke',
                        className: 'img',
                        style: { width: '33em' ,margin: '20px'}
                        }}
                        zoomImage={{
                        src: SO2Stroke,
                        alt: 'SO2Stroke'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    {/* <ReactEcharts
                        style={{height: '50vh', width: '80vw'}}
                        ref={(e) => {
                            this.echartsReactRef = e;
                        }}
                        option={this.state.scatterOption} */}
                    {/* /> */}
                    <ScrollUpButton style={{width: 25,height:25}} ToggledStyle={{right: 10}}/>
          </header>
        </div>
    </div>
    );
  }
}
export default Analysis;
