// formal
var dataMap = {};
function dataFormatter(obj) {
    var pList = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District Of Columbia', 'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];
    var temp;
    for (var year = 1980; year <= 2018; year++) {
        // var max = 0;
        // var sum = 0;
        temp = obj[year];
        for (var i = 0, l = temp.length; i < l; i++) {
            // max = Math.max(max, temp[i]);
            // sum += temp[i];
            obj[year][i] = {
                name : pList[i],
                value : temp[i]
            }
        }
        // obj[year + 'max'] = Math.floor(max / 100) * 100;
        // obj[year + 'sum'] = sum;
    }
    return obj;
}

dataMap.CO = dataFormatter({
    //max : 60000,
    1980 : [2.184, 3.216, 3.268, 0.178, 2.633, 3.232, 4.305, 1.955, 2.847, 2.142, 2.953, 0.272, 4.807, 2.747, 3.221, 2.562, 2.12, 2.211, 0.539, 2.338, 2.194, 3.153, 1.829, 3.746, 1.456, 2.546, 3.124, 1.884, 3.975, 3.782, 2.768, 2.797, 1.973, 3.696, 0.03, 2.263, 2.238, 4.392, 3.125, 3.374, 2.346, 0.003, 2.871, 1.394, 3.056, 1.363, 1.837, 4.058, 1.64, 1.542, 0.005] ,
    1981 : [2.209, 2.863, 2.781, 0.534, 2.269, 3.233, 3.671, 1.962, 3.312, 2.188, 2.377, 1.994, 4.648, 2.605, 2.785, 2.555, 1.865, 2.245, 2.152, 1.628, 3.405, 3.078, 1.631, 3.235, 1.669, 1.898, 2.345, 2.042, 3.53, 4.48, 2.645, 2.837, 2.026, 3.118, 0.089, 2.073, 2.014, 4.079, 2.739, 2.283, 2.309, 0.01, 3.21, 1.575, 3.111, 1.179, 1.871, 4.03, 1.326, 1.279, 0.014] ,
    1982 : [2.075, 3.24, 3.092, 1.009, 2.115, 3.077, 3.302, 1.827, 2.736, 2.091, 2.371, 1.855, 4.506, 2.516, 2.265, 2.414, 2.167, 2.444, 1.549, 1.537, 2.856, 3.211, 1.622, 3.22, 1.45, 1.769, 2.552, 2.2, 2.97, 4.943, 2.774, 2.644, 1.942, 2.988, 0.148, 2.075, 1.875, 3.59, 1.998, 2.48, 1.584, 0.017, 2.851, 1.677, 3.553, 1.466, 1.726, 3.601, 2.009, 1.906, 0.023] ,
    1983 : [2.642, 3.705, 2.666, 0.746, 2.103, 2.992, 3.255, 2.25, 3.097, 2.013, 2.296, 1.76, 4.448, 2.507, 2.154, 2.492, 2.18, 2.549, 1.749, 2.101, 2.654, 3.27, 1.635, 3.152, 1.699, 1.586, 2.359, 1.651, 2.345, 4.676, 3.061, 2.795, 2.193, 3.01, 0.207, 1.994, 1.517, 3.667, 1.913, 2.376, 1.808, 0.024, 2.77, 1.724, 3.304, 1.492, 1.724, 3.498, 1.664, 1.771, 0.032] ,
    1984 : [2.422, 3.194, 2.749, 0.46, 2.172, 2.878, 3.408, 2.753, 3.078, 1.823, 2.06, 1.609, 3.401, 2.395, 2.031, 2.441, 1.757, 2.4, 2.111, 1.79, 3.025, 2.867, 1.669, 3.342, 1.586, 1.729, 2.052, 1.623, 2.777, 4.023, 3.204, 2.608, 2.839, 3.023, 0.267, 1.991, 1.38, 3.507, 1.86, 2.378, 2.288, 0.031, 2.924, 1.816, 3.298, 1.547, 1.671, 3.327, 1.568, 1.786, 0.041] ,
    1985 : [2.045, 3.09, 2.881, 0.421, 2.122, 2.668, 2.879, 2.456, 2.829, 1.774, 1.359, 1.521, 3.288, 2.026, 1.842, 1.628, 2.204, 1.972, 1.956, 2.312, 2.735, 2.608, 1.369, 3.082, 1.831, 1.449, 2.33, 1.229, 3.23, 4.102, 2.807, 2.154, 3.011, 2.614, 0.326, 1.817, 1.386, 3.492, 1.649, 2.171, 1.925, 0.037, 2.413, 1.949, 3.281, 1.922, 1.365, 3.363, 1.222, 1.407, 0.05] ,
    1986 : [2.326, 2.73, 3.146, 0.383, 2.008, 2.732, 2.901, 2.132, 2.77, 1.616, 1.936, 1.503, 2.949, 2.111, 1.644, 1.556, 2.074, 1.864, 2.018, 2.245, 2.556, 2.555, 1.409, 2.87, 1.564, 1.62, 2.817, 1.8, 2.957, 3.841, 2.598, 2.367, 2.915, 2.933, 0.385, 1.808, 1.591, 3.448, 1.708, 2.251, 1.668, 0.044, 2.586, 2.018, 3.333, 1.885, 1.386, 3.498, 1.29, 1.317, 0.06] ,
    1987 : [2.303, 2.812, 2.673, 0.344, 1.86, 2.551, 2.617, 1.784, 2.625, 1.592, 1.46, 1.432, 2.436, 2.139, 1.44, 1.511, 1.742, 1.844, 1.772, 2.272, 2.316, 2.276, 0.74, 2.826, 1.55, 1.55, 3.116, 1.7, 2.71, 3.05, 2.339, 2.19, 2.924, 2.581, 0.445, 1.883, 1.569, 3.218, 1.727, 2.233, 1.609, 0.051, 2.747, 1.762, 3.098, 1.803, 1.371, 3.801, 1.188, 1.33, 0.069] ,
    1988 : [2.021, 2.644, 2.323, 0.306, 1.881, 1.81, 2.424, 1.527, 2.424, 1.541, 1.385, 1.27, 2.421, 1.766, 1.465, 1.251, 1.59, 1.785, 1.686, 2.037, 2.202, 2.064, 1.269, 2.919, 1.979, 1.403, 3.261, 1.799, 2.742, 2.506, 2.291, 1.801, 2.315, 2.423, 0.504, 1.854, 1.455, 3.163, 1.584, 2.036, 1.784, 0.058, 2.183, 1.787, 2.853, 1.865, 1.251, 3.365, 1.146, 1.66, 0.078] ,
    1989 : [1.893, 2.49, 2.422, 0.267, 1.934, 1.666, 2.39, 1.588, 1.475, 1.67, 1.599, 1.319, 3.606, 1.692, 1.211, 1.231, 1.495, 1.785, 1.305, 1.586, 2.027, 1.949, 1.26, 2.701, 1.85, 1.299, 2.807, 1.53, 2.652, 1.851, 2.188, 1.85, 2.274, 2.345, 0.563, 1.683, 1.583, 3.265, 1.633, 1.777, 1.783, 0.064, 2.131, 1.853, 2.713, 1.592, 1.226, 3.207, 1.12, 1.523, 0.087] ,
    1990 : [1.83, 2.639, 2.125, 0.229, 1.767, 1.503, 2.199, 1.297, 2.158, 1.518, 1.666, 0.993, 4.335, 1.638, 1.188, 1.388, 1.386, 1.61, 1.06, 0.272, 1.997, 1.821, 1.1, 2.17, 1.473, 1.308, 2.283, 1.579, 2.239, 1.843, 2.036, 1.598, 2.1, 2.221, 0.496, 1.539, 1.38, 3.193, 1.549, 1.708, 1.683, 0.071, 2.034, 1.702, 2.548, 1.453, 1.236, 3.275, 1.282, 1.161, 0.096] ,
    1991 : [1.641, 2.683, 1.964, 0.212, 1.729, 1.528, 2.287, 1.354, 2.19, 1.364, 1.61, 0.982, 3.63, 1.654, 1.101, 1.343, 1.154, 1.51, 1.147, 0.049, 1.782, 1.709, 1.039, 2.326, 1.317, 1.173, 2.557, 1.589, 2.004, 1.87, 2.049, 1.54, 2.054, 2.091, 1.11, 1.331, 1.375, 2.99, 1.374, 1.566, 1.417, 0.078, 1.89, 1.69, 2.469, 1.194, 1.184, 3.452, 1.276, 1.105, 0.105] ,
    1992 : [1.705, 2.626, 2.037, 0.737, 1.552, 1.572, 2.038, 1.369, 2.008, 1.291, 1.489, 0.886, 3.59, 1.646, 1.211, 1.246, 1.014, 1.393, 1.366, 0.055, 1.604, 1.695, 1.035, 2.1, 1.224, 1.144, 2.461, 1.552, 1.914, 1.776, 2.044, 1.466, 1.863, 1.882, 0.768, 1.371, 1.271, 2.76, 1.308, 1.573, 1.17, 0.085, 1.881, 1.742, 2.067, 1.118, 1.093, 3.32, 1.139, 1.024, 0.114] ,
    1993 : [1.698, 2.262, 1.865, 2.101, 1.451, 1.457, 1.912, 1.258, 2.139, 1.274, 1.484, 0.843, 2.901, 1.561, 1.151, 1.371, 1.029, 1.348, 1.244, 0.094, 1.524, 1.647, 0.984, 2.06, 1.028, 1.155, 2.435, 1.306, 1.76, 1.873, 1.948, 1.407, 1.761, 1.732, 0.731, 1.186, 1.204, 2.615, 1.221, 1.634, 1.066, 0.092, 1.931, 1.56, 2.124, 1.088, 1.119, 3.054, 1.179, 1.101, 0.124] ,
    1994 : [1.61, 2.83, 1.98, 2.196, 1.434, 1.52, 1.954, 1.152, 2.071, 1.202, 1.568, 0.804, 2.389, 1.621, 1.291, 1.192, 1.054, 1.344, 1.294, 0.133, 1.501, 1.692, 1.043, 1.723, 0.82, 1.133, 2.208, 1.25, 1.901, 1.855, 1.964, 1.342, 1.746, 1.607, 0.734, 1.237, 1.191, 2.614, 1.256, 1.441, 1.218, 0.098, 1.737, 1.528, 2.181, 1.029, 1.152, 2.64, 1.424, 1.406, 0.133] ,
    1995 : [1.314, 2.443, 2.205, 2.038, 1.333, 1.539, 1.684, 1.092, 1.721, 1.263, 1.701, 0.797, 2.28, 1.379, 1.159, 1.199, 0.915, 1.197, 1.168, 0.172, 1.346, 1.397, 0.977, 1.59, 0.767, 1.128, 2.152, 1.266, 1.732, 1.421, 1.696, 1.289, 1.518, 1.448, 0.564, 1.054, 1.017, 2.423, 1.083, 1.346, 1.245, 0.105, 1.465, 1.414, 2.219, 0.822, 0.99, 2.468, 1.083, 0.793, 0.142] ,
    1996 : [1.215, 2.146, 2.062, 1.855, 1.221, 1.474, 1.474, 1.08, 1.714, 1.136, 1.505, 0.82, 1.849, 1.202, 1.061, 1.175, 0.896, 1.118, 1.19, 0.211, 1.325, 1.533, 0.925, 1.455, 0.614, 1.08, 1.914, 1.076, 1.524, 1.628, 1.681, 1.079, 1.456, 1.388, 0.535, 1.068, 1.127, 2.378, 1.119, 1.371, 1.094, 0.112, 1.399, 1.279, 1.994, 0.869, 0.939, 2.319, 0.998, 0.698, 0.151] ,
    1997 : [1.3, 2.143, 1.794, 1.803, 1.17, 1.441, 1.532, 1.02, 1.56, 1.121, 1.349, 0.753, 1.966, 1.186, 1.162, 1.004, 0.817, 1.048, 1.04, 0.25, 1.211, 1.347, 0.786, 1.208, 0.588, 1.123, 1.772, 1.068, 1.594, 1.532, 1.512, 0.872, 1.276, 1.382, 0.506, 0.958, 1.125, 2.302, 0.842, 1.176, 1.053, 0.119, 1.38, 1.187, 1.822, 0.853, 0.984, 2.264, 0.923, 0.73, 0.16] ,
    1998 : [1.445, 2.465, 1.617, 2.334, 1.166, 1.4, 1.494, 0.875, 1.67, 1.067, 1.373, 0.681, 1.805, 1.305, 1.108, 1.029, 0.995, 1.023, 1.146, 0.289, 1.303, 1.233, 0.79, 1.267, 0.565, 1.159, 1.706, 1.253, 1.273, 1.363, 1.515, 1.017, 1.172, 1.376, 0.476, 0.975, 1.083, 2.184, 0.793, 1.313, 1.102, 0.125, 1.338, 1.036, 1.78, 0.812, 0.87, 2.01, 1.04, 0.778, 0.169] ,
    1999 : [1.791, 2.016, 1.726, 1.774, 1.179, 1.384, 1.278, 0.77, 1.548, 1.026, 1.258, 0.594, 2.116, 1.319, 1.097, 0.92, 0.957, 0.793, 1.083, 0.328, 1.069, 1.093, 0.743, 0.971, 0.585, 1.039, 1.423, 1.352, 1.315, 1.295, 1.455, 1.036, 1.137, 1.284, 0.447, 0.921, 0.946, 2.022, 0.719, 1.139, 1.059, 0.132, 1.267, 1.149, 1.74, 0.757, 0.867, 1.865, 0.849, 0.701, 0.179] ,
    2000 : [1.63, 1.691, 1.557, 1.504, 1.027, 1.224, 1.194, 0.77, 1.549, 0.98, 1.063, 0.606, 1.8, 1.111, 1.004, 0.723, 0.872, 0.525, 1.017, 0.368, 0.883, 0.908, 0.586, 0.844, 0.607, 0.936, 1.269, 0.931, 1.088, 0.998, 1.266, 0.94, 1.028, 1.121, 0.418, 0.882, 0.752, 1.82, 0.641, 1.105, 0.911, 0.139, 1.132, 1.047, 1.597, 0.692, 0.796, 1.731, 0.897, 0.546, 0.188] ,
    2001 : [2.266, 1.519, 1.378, 1.105, 0.939, 1.202, 1.233, 0.72, 1.415, 0.931, 1.094, 0.627, 1.737, 1.127, 0.98, 0.766, 0.639, 0.642, 1.117, 0.367, 0.805, 0.833, 0.512, 0.742, 0.79, 0.838, 1.202, 0.929, 1.034, 0.897, 1.134, 0.972, 0.903, 1.069, 0.389, 0.827, 0.704, 1.684, 0.646, 1.012, 0.885, 0.146, 1.13, 0.978, 1.365, 0.689, 0.776, 1.554, 0.909, 0.611, 0.216] ,
    2002 : [1.194, 1.608, 1.314, 0.888, 0.878, 1.131, 1.27, 0.637, 1.411, 0.879, 0.856, 0.614, 1.15, 1.092, 1.003, 0.685, 0.702, 0.598, 0.915, 0.319, 0.824, 0.8, 0.535, 0.631, 0.63, 0.854, 1.026, 0.928, 1.009, 0.657, 0.993, 0.791, 0.771, 1.008, 0.36, 0.769, 0.613, 1.575, 0.523, 0.977, 0.802, 0.153, 0.987, 0.841, 1.313, 0.902, 0.689, 1.484, 1.437, 0.656, 0.325] ,
    2003 : [0.794, 1.124, 1.188, 0.713, 0.802, 1.044, 1.187, 0.623, 1.28, 0.88, 0.739, 0.749, 1.279, 0.976, 0.964, 0.683, 0.541, 0.756, 1.041, 0.359, 0.708, 0.778, 0.504, 0.531, 0.684, 0.791, 1.058, 0.87, 1.047, 0.816, 0.972, 0.739, 0.811, 0.971, 0.331, 0.773, 0.508, 1.435, 0.525, 0.869, 0.657, 0.159, 1.051, 0.871, 1.185, 0.755, 0.669, 1.425, 1.195, 0.629, 0.276] ,
    2004 : [0.953, 1.39, 1.015, 0.577, 0.714, 0.936, 1.083, 0.6, 1.025, 0.855, 0.647, 0.739, 1.456, 0.949, 0.856, 0.664, 0.593, 0.87, 0.895, 0.271, 0.68, 0.529, 0.48, 0.476, 0.591, 0.64, 0.905, 0.828, 1.089, 0.758, 0.871, 0.57, 0.683, 0.874, 0.302, 0.749, 0.28, 1.307, 0.542, 0.749, 0.523, 0.166, 0.962, 0.703, 1.039, 0.71, 0.565, 1.349, 0.875, 0.667, 0.172] ,
    2005 : [1.276, 1.06, 0.944, 0.587, 0.665, 0.852, 1.173, 0.598, 1.118, 0.728, 0.671, 0.584, 1.348, 0.85, 0.74, 0.436, 0.671, 0.734, 0.727, 0.213, 0.626, 0.536, 0.451, 0.469, 0.646, 0.639, 0.843, 0.803, 1.019, 0.789, 0.845, 0.559, 0.611, 0.8, 0.273, 0.691, 0.308, 1.144, 0.455, 0.724, 0.442, 0.173, 0.845, 0.664, 1.028, 0.618, 0.588, 1.313, 0.545, 0.583, 0.235] ,
    2006 : [1.382, 1.115, 0.848, 0.765, 0.677, 0.758, 0.967, 0.528, 1.15, 0.698, 0.658, 0.486, 0.902, 0.744, 0.725, 0.415, 0.619, 0.903, 0.535, 0.2, 0.675, 0.586, 0.437, 0.378, 1.578, 0.573, 0.796, 0.783, 0.968, 0.733, 0.693, 0.526, 0.576, 0.737, 0.244, 0.6, 0.352, 1.027, 0.442, 0.673, 0.481, 0.18, 0.804, 0.596, 0.936, 0.544, 0.574, 1.218, 0.46, 0.473, 0.189] ,
    2007 : [1.089, 1.174, 0.745, 0.729, 0.621, 0.706, 0.711, 0.422, 1.09, 0.631, 0.561, 0.43, 0.759, 0.628, 0.677, 0.333, 0.631, 0.795, 0.496, 0.189, 0.561, 0.471, 0.341, 0.371, 1.334, 0.55, 0.627, 0.739, 0.85, 0.672, 0.564, 0.477, 0.532, 0.64, 0.215, 0.572, 0.407, 0.855, 0.408, 0.458, 0.436, 0.186, 0.628, 0.527, 0.947, 0.6, 0.457, 0.973, 0.489, 0.19, 0.215] ,
    2008 : [1.091, 1.188, 0.618, 0.559, 0.583, 0.642, 0.657, 0.398, 1.085, 0.531, 0.478, 0.434, 0.923, 0.578, 0.676, 0.342, 0.543, 0.589, 0.43, 0.211, 0.448, 0.44, 0.342, 0.292, 1.09, 0.433, 0.577, 0.689, 0.795, 0.64, 0.518, 0.483, 0.466, 0.587, 0.214, 0.537, 0.355, 0.709, 0.322, 0.324, 0.322, 0.193, 0.467, 0.43, 0.817, 0.532, 0.408, 0.663, 0.507, 0.161, 0.178] ,
    2009 : [0.853, 0.91, 0.605, 0.534, 0.537, 0.585, 0.611, 0.344, 1.017, 0.467, 0.394, 0.406, 0.671, 0.569, 0.736, 0.332, 0.475, 0.843, 0.309, 0.242, 0.411, 0.442, 0.4, 0.306, 0.845, 0.385, 0.526, 0.697, 0.721, 0.613, 0.513, 0.506, 0.437, 0.523, 0.204, 0.462, 0.433, 0.668, 0.322, 0.342, 0.236, 0.192, 0.445, 0.377, 0.727, 0.495, 0.34, 0.541, 0.492, 0.125, 0.223] ,
    2010 : [0.558, 0.985, 0.585, 0.562, 0.484, 0.581, 0.53, 0.438, 1.015, 0.395, 0.425, 0.34, 0.422, 0.568, 0.758, 0.331, 0.588, 0.565, 0.361, 0.27, 0.422, 0.359, 0.413, 0.434, 0.59, 0.389, 0.482, 0.681, 0.623, 0.592, 0.481, 0.444, 0.458, 0.526, 0.21, 0.522, 0.375, 0.587, 0.38, 0.297, 0.252, 0.135, 0.466, 0.367, 0.648, 0.472, 0.342, 0.569, 0.44, 0.155, 0.239] ,
    2011 : [0.505, 0.634, 0.523, 0.538, 0.511, 0.515, 0.501, 0.369, 0.857, 0.343, 0.423, 0.38, 0.422, 0.57, 0.743, 0.286, 0.434, 0.53, 0.312, 0.257, 0.351, 0.4, 0.407, 0.207, 0.315, 0.371, 0.248, 0.492, 0.639, 0.403, 0.589, 0.53, 0.426, 0.497, 0.197, 0.46, 0.331, 0.519, 0.333, 0.333, 0.298, 0.201, 0.437, 0.355, 0.621, 0.439, 0.366, 0.43, 0.444, 0.173, 0.159] ,
    2012 : [0.512, 0.777, 0.555, 0.56, 0.487, 0.479, 0.448, 0.358, 0.793, 0.333, 0.434, 0.423, 0.429, 0.525, 0.753, 0.313, 0.545, 0.434, 0.323, 0.288, 0.303, 0.372, 0.394, 0.173, 0.311, 0.377, 0.124, 0.47, 0.65, 0.296, 0.581, 0.462, 0.371, 0.43, 0.209, 0.495, 0.269, 0.464, 0.322, 0.298, 0.265, 0.211, 0.413, 0.354, 0.556, 0.37, 0.386, 0.549, 0.441, 0.162, 0.231] ,
    2013 : [0.461, 0.691, 0.501, 0.469, 0.494, 0.477, 0.415, 0.364, 0.532, 0.29, 0.424, 0.491, 0.412, 0.394, 0.57, 0.285, 0.48, 0.334, 0.35, 0.208, 0.265, 0.346, 0.358, 0.172, 0.298, 0.368, 0.116, 0.399, 0.615, 0.232, 0.529, 0.337, 0.35, 0.429, 0.178, 0.512, 0.251, 0.435, 0.246, 0.296, 0.231, 0.198, 0.38, 0.31, 0.596, 0.373, 0.374, 0.502, 0.373, 0.21, 0.148] ,
    2014 : [0.531, 0.699, 0.546, 0.418, 0.45, 0.517, 0.364, 0.307, 0.544, 0.345, 0.444, 0.41, 0.406, 0.322, 0.567, 0.283, 0.375, 0.352, 0.339, 0.219, 0.288, 0.339, 0.396, 0.234, 0.326, 0.369, 0.158, 0.472, 0.57, 0.199, 0.501, 0.326, 0.344, 0.419, 0.189, 0.38, 0.232, 0.396, 0.325, 0.497, 0.237, 0.236, 0.337, 0.318, 0.645, 0.255, 0.354, 0.471, 0.38, 0.255, 0.143] ,
    2015 : [0.532, 0.671, 0.525, 0.377, 0.456, 0.549, 0.392, 0.402, 0.58, 0.349, 0.601, 0.432, 0.502, 0.338, 0.608, 0.29, 0.402, 0.325, 0.56, 0.261, 0.32, 0.332, 0.439, 0.272, 0.417, 0.391, 0.2, 0.462, 0.518, 0.205, 0.5, 0.317, 0.324, 0.405, 0.201, 0.359, 0.317, 0.489, 0.374, 0.565, 0.23, 0.256, 0.371, 0.362, 0.562, 0.244, 0.375, 0.509, 0.316, 0.311, 0.109] ,
    2016 : [0.521, 0.623, 0.49, 0.376, 0.444, 0.508, 0.366, 0.373, 0.65, 0.423, 0.739, 0.395, 0.425, 0.288, 0.493, 0.274, 0.334, 0.353, 0.468, 0.233, 0.271, 0.319, 0.44, 0.303, 0.32, 0.411, 0.231, 0.398, 0.496, 0.188, 0.446, 0.328, 0.298, 0.341, 0.194, 0.362, 0.345, 0.406, 0.351, 0.521, 0.262, 0.241, 0.36, 0.402, 0.586, 0.221, 0.343, 0.418, 0.338, 0.295, 0.163] ,
    2017 : [0.468, 0.595, 0.464, 0.475, 0.458, 0.458, 0.365, 0.344, 0.478, 0.409, 0.655, 0.358, 0.455, 0.296, 0.594, 0.279, 0.314, 0.305, 0.478, 0.243, 0.244, 0.308, 0.494, 0.347, 0.356, 0.374, 0.215, 0.379, 0.566, 0.185, 0.413, 0.314, 0.217, 0.371, 0.213, 0.317, 0.361, 0.353, 0.33, 0.522, 0.271, 0.234, 0.332, 0.429, 0.515, 0.272, 0.345, 0.364, 0.335, 0.247, 0.193] ,
    2018 : [0.399, 0.548, 0.475, 0.343, 0.447, 0.451, 0.362, 0.325, 0.445, 0.379, 0.599, 0.378, 0.56, 0.314, 0.491, 0.279, 0.297, 0.31, 0.451, 0.208, 0.264, 0.345, 0.495, 0.429, 0.201, 0.369, 0.186, 0.377, 0.568, 0.189, 0.438, 0.316, 0.322, 0.365, 0.206, 0.32, 0.36, 0.42, 0.356, 0.47, 0.192, 0.221, 0.346, 0.398, 0.29, 0.265, 0.347, 0.32, 0.292, 0.292, 0.127]
});

dataMap.NO2 = dataFormatter({
    //max : 4000,
    1980 : [61.681, 11.204, 52.168, 14.798, 35.387, 59.994, 46.537, 61.557, 51.465, 39.352, 52.68, 14.073, 0.472, 57.752, 27.396, 15.559, 13.504, 27.4, 41.149, 19.917, 46.214, 55.043, 33.043, 47.997, 35.765, 46.659, 19.037, 9.723, 77.958, 2.307, 48.87, 40.798, 45.608, 10.59, 5.361, 46.214, 40.44, 42.905, 50.911, 54.339, 25.495, 0.137, 28.644, 42.038, 44.222, 3.65, 42.421, 36.68, 29.102, 18.395, 0.187] ,
    1981 : [54.96, 21.923, 40.499, 28.13, 53.416, 54.826, 40.965, 64.959, 53.423, 34.009, 40.372, 8.418, 1.416, 50.521, 25.687, 14.342, 30.271, 30.864, 36.312, 11.586, 40.323, 52.871, 39.976, 43.082, 36.64, 35.179, 11.436, 5.03, 74.831, 6.928, 46.152, 45.962, 47.29, 31.8, 6.174, 44.18, 34.961, 35.512, 46.356, 47.385, 15.277, 0.412, 30.532, 36.656, 46.346, 10.96, 39.364, 48.685, 37.393, 23.285, 0.562] ,
    1982 : [37.438, 19.367, 32.586, 16.966, 49.383, 50.777, 45.978, 37.479, 50.567, 30.292, 48.218, 1.219, 2.359, 48.978, 23.84, 14.167, 37.135, 31.26, 40.156, 6.026, 45.057, 44.216, 27.157, 36.038, 51.097, 30.774, 9.123, 3.1, 45.713, 11.542, 53.854, 43.225, 49.352, 35.752, 5.499, 45.347, 35.264, 33.336, 44.954, 47.206, 15.808, 0.687, 30.371, 33.244, 50.558, 18.26, 39.728, 65.014, 34.161, 22.729, 0.937] ,
    1983 : [29.454, 18.105, 48.2, 19.693, 52.576, 62.042, 47.333, 35.0, 42.223, 28.688, 38.05, 1.124, 3.302, 40.472, 27.257, 14.48, 28.66, 35.764, 45.125, 5.334, 40.625, 39.993, 44.648, 34.472, 65.715, 31.873, 1.851, 3.1, 47.067, 16.157, 53.137, 33.548, 48.023, 38.2, 5.32, 45.546, 33.356, 35.578, 42.864, 42.988, 32.941, 0.962, 21.313, 32.421, 46.041, 25.56, 42.274, 31.452, 32.89, 24.09, 1.312] ,
    1984 : [28.86, 16.842, 22.563, 22.447, 49.182, 56.075, 46.666, 46.284, 45.665, 31.136, 41.776, 3.368, 4.247, 45.611, 25.618, 14.793, 32.974, 32.844, 42.254, 9.13, 40.663, 46.107, 37.007, 35.314, 61.447, 33.405, 2.492, 3.1, 65.052, 20.777, 51.211, 23.519, 43.362, 34.112, 4.993, 44.583, 34.486, 35.729, 43.822, 42.796, 20.067, 1.236, 31.285, 32.592, 53.825, 32.87, 43.244, 36.38, 32.943, 25.555, 1.687] ,
    1985 : [28.266, 15.578, 21.45, 21.079, 51.044, 55.662, 45.061, 46.842, 51.945, 27.718, 42.858, 5.612, 5.191, 45.995, 23.082, 15.106, 37.289, 31.152, 38.362, 12.926, 47.958, 42.66, 18.512, 35.425, 57.179, 31.855, 8.528, 3.1, 52.578, 25.398, 51.886, 22.951, 41.953, 34.56, 6.89, 42.319, 31.83, 33.382, 41.253, 45.064, 12.718, 1.511, 32.445, 32.323, 56.658, 36.071, 42.091, 44.226, 29.062, 27.606, 2.062] ,
    1986 : [27.672, 14.316, 34.297, 23.512, 43.849, 67.333, 44.065, 46.705, 52.484, 29.683, 44.441, 7.853, 6.134, 44.57, 23.898, 15.419, 43.508, 32.476, 39.399, 16.717, 48.279, 42.001, 16.837, 35.667, 52.917, 32.607, 8.312, 3.1, 50.335, 27.861, 47.601, 20.4, 48.188, 35.093, 7.452, 40.514, 30.913, 34.373, 41.349, 43.104, 10.794, 1.786, 34.208, 35.979, 51.306, 34.47, 42.139, 44.068, 30.966, 34.676, 2.437] ,
    1987 : [27.079, 13.054, 44.611, 23.81, 56.955, 54.099, 42.825, 48.774, 43.442, 33.606, 42.133, 10.094, 7.078, 43.628, 28.53, 15.731, 27.269, 35.279, 40.538, 20.507, 47.684, 45.402, 19.844, 32.87, 48.655, 31.145, 7.992, 3.1, 57.813, 46.464, 52.202, 25.806, 45.178, 31.625, 7.132, 45.298, 26.539, 34.274, 41.756, 42.289, 7.571, 2.061, 37.88, 39.93, 49.703, 33.358, 44.949, 30.019, 30.193, 29.609, 2.811] ,
    1988 : [26.485, 11.79, 32.896, 24.893, 51.067, 38.661, 43.405, 48.682, 39.405, 31.576, 46.377, 12.339, 8.022, 44.305, 29.279, 16.045, 26.983, 34.628, 37.349, 24.303, 44.431, 43.404, 24.552, 33.082, 44.387, 33.532, 10.54, 3.1, 70.189, 46.011, 52.338, 27.837, 41.534, 34.415, 5.91, 41.76, 29.127, 35.002, 40.64, 38.88, 9.424, 2.336, 35.339, 38.306, 53.652, 31.329, 42.368, 28.714, 32.301, 30.294, 3.187] ,
    1989 : [25.891, 11.705, 27.08, 22.316, 45.179, 36.697, 41.647, 48.849, 38.179, 29.357, 43.028, 14.583, 8.966, 44.879, 29.597, 16.358, 43.197, 34.887, 33.735, 28.099, 43.129, 41.652, 25.737, 24.912, 40.119, 33.28, 10.419, 3.1, 67.063, 42.57, 47.768, 28.294, 46.995, 30.298, 6.531, 41.679, 27.579, 37.682, 39.198, 39.736, 7.787, 2.611, 28.734, 34.766, 52.589, 30.684, 42.567, 27.819, 26.862, 34.061, 3.562] ,
    1990 : [25.297, 14.076, 42.164, 23.064, 39.299, 30.049, 40.051, 45.493, 47.963, 27.088, 40.288, 16.824, 9.91, 36.652, 25.147, 16.671, 42.931, 32.643, 34.333, 29.253, 46.662, 38.754, 22.622, 23.826, 35.857, 30.052, 9.604, 3.1, 53.642, 43.678, 46.785, 25.946, 48.207, 32.566, 7.645, 37.75, 24.572, 55.576, 37.956, 40.011, 19.067, 2.885, 25.847, 32.837, 46.195, 28.76, 41.236, 26.924, 28.319, 31.429, 3.936] ,
    1991 : [30.16, 17.333, 34.396, 21.408, 33.419, 35.454, 40.917, 42.611, 45.158, 24.406, 37.301, 19.065, 10.853, 34.298, 25.172, 16.983, 41.283, 30.226, 33.225, 26.623, 47.575, 39.561, 21.296, 31.114, 31.595, 29.948, 10.399, 3.1, 45.689, 29.549, 45.736, 11.088, 46.214, 32.308, 8.37, 38.224, 24.879, 40.733, 37.611, 41.951, 14.763, 3.16, 25.193, 33.837, 96.512, 28.837, 42.125, 26.029, 26.163, 31.008, 4.311] ,
    1992 : [28.962, 23.13, 39.235, 23.347, 35.051, 35.892, 37.278, 26.366, 43.82, 24.997, 38.1, 22.179, 11.797, 40.115, 24.084, 17.297, 23.15, 30.17, 23.724, 23.165, 44.75, 37.527, 22.237, 22.401, 27.327, 31.548, 8.91, 3.1, 40.607, 26.122, 46.272, 21.452, 39.327, 31.723, 8.957, 36.627, 23.533, 39.517, 35.143, 38.001, 15.44, 3.435, 28.387, 35.102, 51.181, 26.457, 39.518, 25.134, 24.557, 34.345, 4.686] ,
    1993 : [26.574, 22.264, 41.858, 22.581, 33.538, 32.527, 39.658, 33.529, 44.521, 26.083, 34.627, 11.691, 12.742, 40.924, 25.211, 17.61, 17.356, 29.245, 21.8, 17.205, 45.167, 39.269, 25.133, 37.084, 23.059, 29.879, 7.261, 3.1, 45.797, 27.927, 44.889, 26.13, 41.919, 30.588, 9.279, 37.457, 23.22, 35.79, 36.057, 37.097, 19.779, 3.71, 26.899, 31.647, 48.099, 28.078, 37.369, 24.238, 25.367, 35.56, 5.061] ,
    1994 : [25.878, 21.399, 41.696, 25.852, 34.688, 34.162, 42.292, 33.438, 46.043, 23.191, 29.546, 10.496, 16.724, 44.822, 27.894, 17.923, 32.027, 33.808, 21.185, 9.216, 43.705, 38.797, 30.791, 33.597, 18.797, 32.614, 7.624, 3.1, 39.48, 25.971, 46.381, 25.179, 47.862, 29.5, 9.706, 38.515, 24.645, 33.141, 38.254, 34.758, 16.78, 3.984, 28.923, 36.563, 46.703, 29.44, 34.133, 23.343, 25.195, 36.256, 5.436] ,
    1995 : [23.79, 20.535, 44.552, 24.475, 35.838, 31.541, 34.405, 30.852, 39.605, 25.07, 27.12, 11.284, 30.496, 41.048, 29.294, 18.235, 56.379, 32.131, 21.4, 15.548, 35.825, 33.495, 27.85, 33.16, 14.535, 30.236, 9.243, 3.1, 40.312, 24.851, 41.936, 25.513, 43.71, 32.211, 9.53, 33.817, 22.86, 32.285, 35.41, 31.005, 17.343, 4.259, 25.745, 36.788, 41.728, 27.882, 32.454, 22.449, 24.256, 30.695, 5.811] ,
    1996 : [24.368, 19.669, 45.012, 24.454, 36.99, 30.149, 35.981, 34.251, 39.375, 23.691, 28.838, 5.356, 32.224, 38.544, 28.06, 18.549, 52.436, 30.57, 21.406, 20.12, 38.306, 32.37, 28.022, 29.918, 10.375, 28.919, 6.681, 3.1, 41.209, 29.301, 42.761, 21.795, 43.888, 31.033, 10.288, 39.45, 24.115, 28.516, 36.459, 35.98, 16.522, 4.534, 27.687, 34.208, 45.963, 27.139, 32.699, 32.387, 23.636, 27.424, 6.186] ,
    1997 : [25.222, 18.804, 47.68, 22.836, 38.141, 29.273, 34.896, 31.634, 38.335, 23.793, 28.235, 10.969, 35.052, 37.639, 28.265, 18.862, 40.227, 29.468, 21.381, 20.962, 36.381, 29.478, 30.348, 28.063, 15.805, 28.496, 7.286, 3.1, 30.974, 27.301, 41.461, 21.375, 41.997, 34.471, 10.571, 38.79, 22.774, 25.135, 34.371, 34.497, 17.84, 4.809, 27.264, 31.96, 43.462, 27.627, 32.096, 23.843, 29.479, 24.336, 6.561] ,
    1998 : [21.642, 17.939, 47.005, 24.092, 39.291, 29.223, 36.046, 29.911, 40.113, 23.14, 25.926, 10.166, 36.451, 37.08, 35.729, 19.174, 31.026, 30.084, 22.104, 18.984, 36.891, 27.835, 37.349, 32.661, 17.499, 28.346, 5.018, 3.1, 27.798, 23.505, 41.652, 19.996, 41.619, 34.96, 11.823, 39.925, 21.435, 30.241, 33.817, 32.001, 17.088, 5.084, 26.161, 29.091, 43.389, 27.042, 34.342, 27.112, 33.321, 23.347, 6.936] ,
    1999 : [23.141, 17.075, 49.311, 26.415, 34.442, 28.987, 34.621, 33.807, 38.266, 24.219, 30.758, 10.121, 36.899, 38.053, 34.302, 19.487, 23.801, 29.838, 23.508, 15.959, 37.215, 29.506, 35.367, 28.501, 22.019, 30.537, 5.618, 3.1, 31.051, 20.483, 41.138, 20.419, 44.163, 35.363, 8.543, 38.173, 23.678, 27.318, 32.42, 34.362, 18.193, 5.358, 26.344, 28.693, 45.322, 27.197, 32.091, 32.341, 46.928, 29.182, 7.31] ,
    2000 : [22.495, 16.209, 47.874, 22.62, 32.11, 38.944, 32.89, 26.385, 35.503, 23.798, 28.796, 10.294, 33.828, 36.164, 32.133, 16.714, 19.622, 28.633, 22.749, 18.614, 32.918, 27.827, 26.946, 28.411, 17.801, 26.476, 9.522, 3.1, 26.763, 19.191, 38.497, 20.797, 42.131, 35.072, 8.742, 36.23, 21.681, 21.6, 30.711, 31.609, 18.371, 5.633, 27.651, 24.564, 43.315, 23.412, 29.408, 34.781, 44.309, 32.027, 7.685] ,
    2001 : [22.163, 15.343, 41.171, 24.364, 43.12, 33.173, 37.556, 32.004, 41.016, 21.987, 27.754, 10.253, 37.718, 38.405, 26.096, 16.444, 18.555, 28.101, 22.155, 25.126, 32.683, 26.8, 35.494, 27.671, 16.179, 25.753, 9.939, 3.1, 26.305, 20.678, 38.888, 19.695, 41.554, 33.556, 8.901, 37.186, 21.26, 19.032, 31.132, 31.918, 20.125, 5.896, 27.633, 24.412, 43.231, 24.784, 29.208, 34.982, 36.583, 31.034, 6.191] ,
    2002 : [14.804, 14.479, 39.897, 32.332, 37.375, 36.191, 37.334, 34.084, 41.18, 20.942, 24.94, 11.096, 23.695, 37.313, 26.939, 16.137, 18.084, 25.789, 19.81, 27.881, 34.748, 27.319, 34.846, 23.859, 11.657, 25.799, 8.197, 3.1, 28.211, 21.755, 37.874, 20.563, 40.292, 30.092, 6.268, 35.637, 21.075, 23.234, 28.98, 28.946, 16.222, 4.92, 24.765, 23.102, 44.158, 23.353, 32.164, 33.967, 28.868, 22.945, 5.634] ,
    2003 : [13.381, 13.614, 36.58, 30.014, 31.63, 33.656, 34.695, 34.968, 40.279, 20.471, 31.05, 10.959, 18.792, 38.299, 28.001, 16.758, 19.083, 24.113, 20.837, 25.497, 37.052, 33.312, 35.086, 18.737, 9.653, 25.502, 6.067, 3.1, 27.887, 21.648, 36.081, 22.109, 38.857, 30.052, 7.723, 34.539, 20.475, 29.822, 27.341, 29.353, 15.208, 17.733, 20.115, 22.768, 40.478, 27.106, 30.119, 28.378, 38.263, 16.744, 11.76] ,
    2004 : [10.799, 12.749, 36.845, 27.849, 27.237, 30.917, 31.581, 31.357, 36.706, 19.346, 23.775, 9.314, 20.639, 36.336, 22.832, 14.031, 17.811, 22.101, 19.632, 21.343, 33.077, 22.695, 29.326, 15.928, 11.328, 24.029, 3.256, 3.1, 27.496, 17.858, 34.3, 20.659, 36.895, 28.183, 7.16, 32.387, 15.358, 21.289, 26.688, 28.413, 15.982, 14.705, 22.08, 21.478, 39.987, 24.675, 28.483, 23.721, 34.96, 23.841, 11.866] ,
    2005 : [20.251, 11.883, 34.638, 24.5, 24.558, 27.753, 34.364, 32.718, 36.332, 18.665, 22.653, 10.348, 17.278, 36.779, 24.544, 15.983, 18.957, 22.978, 20.723, 24.844, 31.212, 30.222, 32.019, 20.117, 17.585, 23.845, 7.352, 3.1, 26.247, 18.06, 35.879, 21.323, 38.243, 27.581, 6.582, 34.868, 21.733, 22.43, 22.04, 28.183, 16.382, 6.723, 20.654, 22.249, 39.015, 24.488, 28.392, 24.193, 36.733, 27.368, 13.965] ,
    2006 : [23.093, 11.018, 40.778, 27.016, 25.691, 23.3, 30.293, 34.925, 32.466, 18.889, 23.376, 10.549, 15.895, 35.79, 20.363, 20.423, 18.896, 22.555, 19.914, 13.868, 29.129, 21.845, 27.986, 19.9, 23.047, 23.926, 10.881, 3.1, 26.45, 16.631, 32.682, 22.555, 35.281, 27.989, 7.826, 31.519, 18.408, 20.987, 24.369, 25.718, 15.421, 5.635, 20.165, 21.234, 37.007, 21.207, 26.301, 14.54, 20.0, 25.371, 14.51] ,
    2007 : [25.277, 10.154, 36.641, 25.686, 25.768, 26.1, 26.994, 32.319, 32.388, 16.849, 9.45, 9.99, 9.539, 24.917, 20.448, 18.376, 16.299, 20.624, 18.643, 6.657, 28.013, 22.152, 28.581, 19.11, 14.424, 21.877, 11.217, 3.1, 30.075, 15.014, 29.992, 20.904, 33.46, 28.77, 7.532, 27.66, 16.998, 18.336, 21.236, 23.917, 14.657, 4.562, 15.052, 17.57, 38.621, 21.671, 24.429, 17.078, 20.0, 24.389, 11.27] ,
    2008 : [27.463, 9.288, 35.265, 22.794, 25.175, 19.724, 23.679, 32.224, 31.245, 14.851, 18.818, 9.072, 9.732, 37.429, 20.396, 18.567, 15.447, 20.467, 17.781, 15.121, 29.43, 22.894, 26.634, 17.278, 15.592, 19.842, 2.908, 3.1, 32.41, 16.179, 29.642, 19.627, 31.777, 25.706, 7.968, 24.874, 14.373, 20.269, 22.928, 22.662, 15.487, 6.241, 20.795, 18.848, 46.25, 20.301, 21.901, 20.461, 20.0, 22.433, 8.634] ,
    2009 : [29.65, 8.422, 33.033, 18.0, 30.731, 17.355, 21.708, 27.348, 30.789, 14.624, 13.659, 10.686, 22.463, 32.758, 21.429, 17.419, 14.516, 18.126, 16.556, 14.924, 29.864, 21.099, 19.0, 17.792, 12.773, 17.914, 6.167, 3.1, 41.383, 21.05, 36.071, 28.477, 26.473, 16.75, 12.278, 21.445, 13.992, 19.455, 21.474, 20.146, 14.125, 5.263, 15.043, 16.649, 29.625, 18.027, 20.313, 14.355, 20.0, 23.616, 7.056] ,
    2010 : [31.834, 7.558, 31.759, 23.821, 30.458, 18.165, 21.017, 25.43, 30.273, 16.376, 14.989, 7.532, 21.563, 30.393, 23.956, 18.477, 14.549, 17.616, 16.591, 14.065, 30.174, 19.926, 20.962, 14.413, 14.129, 21.259, 5.276, 3.1, 27.003, 14.119, 26.613, 18.689, 22.752, 23.795, 8.175, 20.754, 15.704, 18.195, 21.73, 16.178, 16.405, 6.711, 18.089, 17.563, 22.874, 17.353, 20.604, 11.481, 20.0, 18.993, 7.396] ,
    2011 : [34.017, 6.693, 32.888, 22.925, 30.185, 16.333, 21.428, 21.437, 29.374, 14.417, 15.112, 9.005, 15.587, 28.775, 21.25, 17.006, 16.956, 15.03, 16.808, 13.885, 29.264, 22.213, 11.667, 16.552, 12.988, 27.501, 5.003, 3.1, 26.946, 12.761, 30.627, 18.885, 20.832, 20.749, 7.278, 19.634, 17.522, 18.47, 21.879, 19.26, 15.324, 9.599, 20.264, 12.415, 19.498, 17.794, 19.439, 12.237, 20.0, 13.162, 8.659] ,
    2012 : [36.204, 5.828, 34.471, 23.983, 29.912, 15.084, 17.562, 18.291, 28.546, 14.478, 15.267, 8.781, 22.8, 28.617, 21.342, 13.241, 16.772, 15.714, 16.249, 13.258, 24.918, 18.551, 19.848, 13.131, 12.013, 28.113, 4.187, 3.1, 27.778, 3.883, 26.952, 18.167, 24.265, 20.27, 7.764, 26.329, 15.437, 18.01, 19.08, 15.574, 13.845, 11.499, 21.093, 16.779, 19.429, 15.291, 18.353, 16.064, 20.0, 12.633, 9.013] ,
    2013 : [37.011, 4.962, 31.46, 21.337, 29.639, 16.126, 20.267, 12.253, 24.183, 13.08, 11.655, 8.313, 25.224, 27.384, 18.852, 13.568, 15.164, 14.624, 14.766, 12.139, 22.343, 19.13, 18.996, 16.389, 11.821, 26.069, 3.916, 3.1, 28.442, 3.291, 26.09, 16.625, 27.16, 26.8, 7.231, 22.994, 16.657, 17.431, 16.496, 15.382, 13.916, 9.592, 24.161, 16.013, 20.036, 15.027, 17.297, 13.954, 20.0, 12.665, 8.536] ,
    2014 : [25.8, 13.92, 30.947, 20.328, 29.366, 17.59, 20.281, 15.502, 23.454, 14.25, 15.303, 8.54, 26.638, 22.643, 19.348, 12.994, 14.547, 16.2, 16.042, 11.189, 20.996, 19.264, 19.21, 18.494, 12.083, 24.521, 3.941, 3.1, 26.624, 5.443, 28.109, 16.541, 25.133, 17.507, 7.328, 25.919, 15.319, 18.685, 17.91, 19.527, 14.019, 9.287, 19.314, 15.82, 17.891, 15.75, 17.615, 22.872, 20.0, 20.593, 9.174] ,
    2015 : [24.283, 22.592, 27.303, 18.917, 29.094, 18.719, 21.223, 23.187, 27.058, 13.678, 18.866, 8.752, 26.63, 26.209, 17.61, 12.456, 12.29, 16.749, 14.582, 15.214, 21.096, 19.078, 20.282, 16.922, 12.687, 21.603, 3.179, 3.1, 28.824, 7.273, 27.615, 12.933, 25.649, 16.342, 6.861, 24.562, 18.268, 20.551, 18.59, 21.21, 15.484, 9.178, 19.777, 15.745, 24.005, 15.677, 17.374, 24.11, 20.0, 17.878, 8.838] ,
    2016 : [26.94, 22.287, 28.523, 12.65, 28.82, 22.608, 25.811, 24.429, 25.093, 14.987, 30.488, 10.167, 24.271, 26.849, 16.843, 11.931, 11.013, 8.433, 15.57, 10.418, 22.063, 22.25, 19.936, 12.08, 5.075, 20.676, 3.316, 3.1, 31.602, 6.795, 25.625, 15.614, 23.686, 15.163, 6.09, 22.269, 16.775, 19.801, 16.383, 19.137, 13.262, 8.847, 18.23, 15.442, 14.088, 13.814, 16.72, 23.61, 20.0, 21.976, 7.569] ,
    2017 : [21.871, 23.128, 31.307, 17.887, 28.547, 19.077, 25.66, 22.244, 23.339, 16.105, 26.262, 10.257, 25.665, 26.27, 19.554, 10.932, 10.686, 14.902, 14.114, 12.832, 20.433, 16.452, 19.212, 16.386, 13.517, 20.505, 3.085, 3.1, 33.496, 6.286, 24.006, 14.675, 23.064, 14.688, 6.47, 21.145, 17.308, 19.618, 14.781, 18.674, 12.711, 8.058, 17.024, 14.792, 14.574, 13.911, 16.441, 23.152, 20.0, 20.493, 8.286] ,
    2018 : [21.884, 22.134, 29.88, 17.592, 19.196, 17.145, 21.817, 20.213, 21.068, 15.339, 23.715, 10.838, 24.0, 23.834, 19.883, 7.067, 10.747, 14.656, 14.049, 13.214, 18.46, 17.069, 19.812, 17.368, 12.998, 20.057, 3.472, 3.1, 32.707, 5.701, 23.443, 15.208, 25.039, 14.328, 6.435, 20.874, 17.67, 19.514, 13.057, 20.925, 11.007, 8.321, 16.817, 14.776, 15.414, 13.719, 15.373, 23.814, 20.0, 20.617, 7.574] 
});

dataMap.PM10 = dataFormatter({
    //max : 26600,
1980 : [] ,
1981 : [] ,
1982 : [] ,
1983 : [] ,
1984 : [] ,
1985 : [] ,
1986 : [] ,
1987 : [] ,
1988 : [40.849, 21.179, 43.026, 29.862, 13.463, 28.843, 43.041, 33.252, 15.982, 29.06, 40.628, 19.455, 43.063, 46.099, 41.45, 36.596, 38.86, 37.985, 27.225, 23.649, 43.363, 31.505, 37.576, 31.427, 30.06, 39.578, 39.315, 40.309, 39.283, 34.568, 35.403, 41.252, 32.24, 33.176, 15.748, 40.253, 28.349, 45.892, 44.363, 32.527, 30.807, 27.711, 39.61, 41.352, 46.454, 23.814, 35.661, 40.805, 44.812, 26.032, 41.775] ,
1989 : [36.913, 21.846, 44.727, 27.785, 35.483, 30.496, 40.842, 35.123, 42.958, 29.423, 38.935, 18.38, 38.169, 45.586, 37.963, 33.397, 37.609, 37.142, 29.816, 23.756, 40.645, 21.622, 39.213, 32.963, 26.181, 40.16, 30.555, 39.92, 51.726, 36.396, 35.481, 39.136, 28.696, 28.242, 18.552, 38.719, 29.638, 44.539, 37.499, 35.529, 29.405, 25.262, 33.937, 48.454, 45.831, 24.616, 33.313, 34.466, 43.102, 33.634, 29.905] ,
1990 : [14.5, 28.0, 37.308, 10.288, 32.338, 26.385, 25.25, 31.929, 33.241, 27.548, 40.53, 13.148, 35.095, 34.557, 33.711, 31.144, 33.333, 33.076, 27.328, 20.809, 28.8, 20.893, 34.764, 33.254, 27.764, 35.806, 29.0, 26.091, 47.648, 35.234, 33.231, 30.542, 23.375, 27.778, 25.013, 34.025, 25.833, 36.589, 38.194, 34.012, 28.641, 28.15, 34.32, 41.818, 33.151, 23.031, 31.999, 31.074, 42.189, 27.606, 27.26] ,
1991 : [34.927, 25.988, 30.628, 23.572, 36.02, 26.882, 40.784, 31.07, 28.911, 25.808, 31.467, 16.995, 37.524, 34.7, 34.005, 30.438, 40.6, 30.365, 26.569, 19.864, 35.886, 20.363, 33.26, 27.851, 23.136, 38.009, 26.89, 35.831, 37.586, 33.619, 34.51, 28.23, 24.394, 28.705, 20.836, 33.043, 26.668, 36.194, 35.815, 34.14, 31.13, 27.543, 33.264, 31.742, 41.457, 23.054, 31.886, 32.148, 41.118, 26.033, 29.767] ,
1992 : [36.138, 26.793, 20.052, 24.387, 23.512, 28.357, 33.653, 26.476, 23.952, 12.263, 29.286, 16.141, 40.446, 37.215, 28.918, 32.299, 39.051, 24.29, 26.814, 20.541, 29.985, 18.022, 16.483, 25.058, 22.657, 34.766, 25.742, 31.856, 33.076, 27.408, 29.458, 24.586, 14.999, 25.515, 19.234, 29.529, 23.753, 28.086, 30.265, 28.419, 35.889, 28.819, 29.014, 28.374, 36.555, 22.021, 22.942, 32.748, 33.942, 23.137, 29.154] ,
1993 : [30.495, 23.128, 27.754, 24.568, 32.178, 27.106, 47.427, 28.353, 24.368, 24.843, 29.024, 13.872, 37.084, 31.969, 26.997, 32.711, 36.994, 26.592, 24.1, 19.894, 29.789, 18.446, 29.449, 24.742, 21.973, 28.457, 23.028, 30.1, 33.492, 17.298, 32.626, 22.371, 20.542, 25.556, 16.115, 30.374, 24.218, 28.261, 31.671, 30.627, 32.475, 25.739, 30.646, 26.553, 40.429, 19.351, 23.265, 31.123, 32.796, 23.244, 26.225] ,
1994 : [26.188, 21.512, 27.58, 15.551, 32.376, 25.528, 29.354, 31.126, 24.643, 24.717, 27.721, 23.0, 35.022, 37.716, 27.352, 32.796, 33.819, 27.444, 26.299, 19.395, 29.586, 19.223, 37.307, 12.161, 21.857, 30.237, 24.86, 31.943, 31.551, 16.852, 35.789, 24.624, 21.291, 23.624, 15.694, 30.654, 24.065, 25.177, 32.604, 26.668, 32.147, 34.046, 29.145, 28.228, 33.322, 19.267, 21.622, 25.802, 35.836, 23.378, 36.971] ,
1995 : [26.707, 22.747, 30.76, 26.201, 33.089, 23.479, 24.422, 28.106, 25.064, 25.233, 28.334, 15.119, 25.449, 33.996, 28.897, 33.224, 34.466, 24.234, 23.72, 16.885, 27.829, 15.798, 37.75, 23.591, 21.199, 26.667, 20.427, 27.972, 34.639, 16.078, 28.778, 30.494, 19.654, 23.208, 15.034, 29.156, 24.814, 20.003, 27.902, 21.071, 32.22, 30.041, 26.904, 29.045, 29.126, 18.353, 22.309, 23.9, 33.327, 22.04, 26.652] ,
1996 : [25.543, 22.944, 30.687, 24.321, 27.625, 23.723, 25.355, 27.584, 22.378, 23.82, 25.601, 14.821, 30.042, 31.389, 25.963, 33.583, 35.089, 22.63, 22.977, 17.876, 27.892, 19.147, 27.913, 23.193, 21.09, 47.533, 20.732, 30.948, 37.037, 17.391, 31.272, 39.194, 23.243, 23.214, 13.503, 27.382, 25.214, 19.972, 34.056, 23.439, 31.841, 29.208, 26.12, 31.763, 32.26, 19.251, 21.741, 24.436, 32.062, 23.969, 23.079] ,
1997 : [24.879, 22.4, 46.839, 22.374, 24.568, 24.041, 23.088, 25.015, 28.082, 20.822, 25.705, 15.159, 34.607, 30.363, 28.929, 33.908, 30.094, 21.924, 24.709, 15.852, 26.639, 17.939, 26.858, 23.667, 24.311, 33.645, 20.031, 30.693, 33.241, 18.888, 28.247, 29.612, 20.896, 21.71, 10.958, 26.065, 23.758, 21.329, 24.737, 23.457, 30.11, 29.859, 30.907, 33.439, 28.284, 20.324, 18.477, 25.044, 27.604, 23.305, 20.244] ,
1998 : [28.548, 22.135, 46.399, 34.05, 23.51, 25.065, 21.827, 23.084, 26.104, 23.606, 27.918, 17.229, 24.807, 31.994, 27.742, 31.441, 24.802, 23.081, 17.516, 17.636, 23.843, 21.294, 28.505, 22.527, 25.806, 31.821, 23.658, 32.606, 30.505, 17.532, 25.669, 28.839, 21.498, 23.947, 15.441, 27.823, 26.857, 19.689, 26.638, 22.835, 31.99, 24.472, 32.523, 36.129, 28.25, 20.038, 21.754, 23.626, 29.739, 25.645, 20.306] ,
1999 : [34.462, 20.35, 40.005, 32.049, 28.439, 24.582, 27.326, 27.0, 21.708, 20.246, 24.862, 16.139, 28.061, 32.231, 28.308, 31.124, 24.236, 22.966, 26.344, 16.8, 21.81, 25.541, 27.459, 22.804, 23.409, 34.667, 21.947, 39.514, 34.555, 17.431, 27.397, 18.241, 16.225, 21.93, 12.11, 27.414, 29.089, 20.075, 26.34, 33.5, 32.472, 26.185, 31.347, 39.675, 32.932, 16.977, 20.365, 23.806, 26.153, 22.75, 23.926] ,
2000 : [34.478, 18.455, 39.07, 27.533, 33.493, 24.065, 29.636, 25.836, 16.133, 21.464, 26.324, 16.771, 27.567, 32.132, 25.893, 27.971, 22.897, 17.685, 26.298, 16.365, 22.2, 22.802, 32.156, 25.252, 22.309, 34.313, 22.023, 39.325, 30.09, 15.196, 29.796, 21.456, 19.136, 21.903, 4.24, 26.233, 22.192, 19.387, 24.435, 22.882, 31.407, 23.999, 29.177, 37.805, 33.242, 14.23, 20.783, 25.18, 25.964, 19.088, 21.551] ,
2001 : [29.697, 18.596, 30.144, 26.145, 38.473, 24.838, 34.295, 21.677, 25.377, 21.365, 25.051, 17.687, 26.563, 31.612, 24.72, 25.991, 22.057, 22.1, 26.397, 19.384, 21.851, 22.115, 33.558, 28.052, 19.955, 39.287, 21.067, 29.383, 30.155, 16.152, 28.176, 20.4, 18.202, 21.98, 15.408, 26.366, 23.923, 18.564, 24.414, 22.529, 29.268, 26.24, 28.83, 36.091, 33.436, 15.484, 18.918, 25.962, 28.434, 18.165, 22.877] ,
2002 : [14.025, 9.0, 33.233, 24.181, 40.06, 27.228, 30.973, 23.552, 26.639, 19.102, 21.43, 15.911, 23.6, 31.936, 25.125, 25.177, 22.152, 20.519, 22.593, 16.766, 23.695, 19.552, 36.082, 32.937, 13.51, 43.043, 19.04, 30.879, 33.368, 10.563, 26.371, 29.774, 19.814, 20.413, 14.839, 25.024, 24.134, 23.56, 22.216, 18.033, 26.27, 26.023, 26.875, 40.578, 33.205, 15.165, 18.082, 25.754, 26.854, 17.072, 24.026] ,
2003 : [31.85, 23.724, 29.53, 24.653, 33.409, 24.591, 38.413, 20.831, 22.834, 20.576, 22.185, 16.805, 12.023, 18.125, 27.18, 25.631, 22.27, 20.805, 24.837, 16.115, 19.738, 20.281, 42.293, 26.135, 18.674, 37.002, 21.241, 29.897, 29.503, 18.722, 27.255, 38.853, 18.336, 19.631, 16.343, 24.156, 23.991, 18.091, 22.288, 19.582, 25.213, 24.126, 32.09, 41.18, 28.915, 19.303, 17.539, 27.208, 25.87, 17.772, 22.671] ,
2004 : [30.015, 19.334, 29.14, 23.97, 32.069, 23.185, 36.81, 19.369, 26.999, 21.595, 22.815, 14.619, 20.02, 29.211, 25.913, 24.321, 18.583, 20.001, 23.406, 15.604, 22.974, 19.753, 34.768, 23.512, 18.974, 35.657, 19.906, 25.604, 26.044, 24.158, 23.195, 33.45, 11.936, 20.64, 11.499, 23.641, 20.835, 18.267, 22.147, 16.752, 27.247, 26.393, 33.245, 33.396, 30.638, 17.533, 18.565, 23.494, 25.394, 18.434, 20.877] ,
2005 : [30.732, 21.167, 41.592, 24.752, 8.483, 17.879, 19.415, 21.951, 35.831, 21.071, 24.166, 17.292, 20.269, 30.026, 27.338, 15.812, 20.566, 22.323, 24.292, 15.643, 22.616, 20.259, 32.867, 23.268, 18.898, 40.732, 13.229, 30.164, 25.909, 18.304, 29.698, 34.828, 12.967, 20.884, 11.438, 27.149, 32.683, 11.748, 23.286, 18.509, 27.711, 17.538, 32.177, 33.309, 26.058, 17.926, 21.479, 22.78, 22.685, 19.455, 20.589] ,
2006 : [29.739, 21.681, 60.197, 21.575, 32.527, 22.994, 19.647, 21.632, 25.543, 22.507, 22.841, 15.602, 19.865, 27.215, 23.609, 30.111, 22.519, 21.485, 33.531, 16.275, 24.359, 16.663, 28.552, 22.21, 22.809, 41.638, 23.544, 30.73, 25.383, 14.877, 25.719, 36.941, 11.885, 20.207, 12.202, 22.753, 24.166, 18.068, 21.348, 17.41, 24.815, 18.292, 33.767, 40.158, 26.623, 14.716, 20.581, 20.911, 10.625, 16.732, 22.053] ,
2007 : [32.376, 20.689, 55.148, 23.49, 27.022, 24.365, 18.105, 23.23, 22.47, 20.814, 27.087, 17.817, 20.508, 30.193, 25.384, 32.867, 21.659, 23.602, 30.639, 17.109, 24.56, 16.604, 28.761, 21.446, 20.789, 31.495, 24.1, 29.073, 24.94, 13.581, 26.19, 29.361, 12.655, 19.74, 12.22, 24.6, 22.357, 16.088, 20.978, 14.863, 23.848, 19.864, 33.127, 31.79, 31.258, 10.298, 21.086, 19.609, 24.937, 18.036, 23.467] ,
2008 : [26.365, 20.61, 45.677, 19.592, 19.068, 25.005, 17.874, 19.232, 19.171, 19.163, 22.131, 17.145, 9.467, 26.888, 23.515, 27.967, 21.215, 20.171, 26.859, 17.145, 19.657, 16.045, 24.698, 20.431, 9.772, 27.513, 21.221, 31.495, 21.929, 13.023, 27.005, 34.763, 12.042, 17.641, 12.578, 21.975, 20.764, 15.601, 19.368, 16.268, 20.978, 19.17, 29.24, 27.902, 29.34, 5.06, 19.26, 22.694, 19.349, 15.237, 21.263] ,
2009 : [20.676, 17.063, 39.818, 17.744, 27.267, 23.118, 15.752, 17.048, 17.355, 17.574, 21.185, 15.959, 19.103, 21.455, 27.25, 26.217, 19.858, 18.229, 25.886, 15.981, 15.881, 15.299, 19.88, 21.479, 9.331, 12.25, 21.041, 23.948, 20.371, 12.984, 26.452, 28.615, 10.685, 15.43, 10.282, 20.147, 21.275, 15.191, 17.223, 13.933, 19.24, 17.205, 18.948, 18.857, 28.28, 7.149, 15.745, 20.592, 17.028, 15.52, 17.109] ,
2010 : [23.587, 15.84, 31.919, 21.259, 25.51, 23.278, 14.091, 18.0, 15.133, 17.656, 18.369, 15.137, 16.422, 24.473, 22.953, 27.416, 21.313, 20.913, 23.524, 14.526, 15.915, 13.53, 20.594, 23.594, 16.986, 26.117, 18.563, 34.534, 17.802, 11.485, 26.15, 28.892, 11.085, 17.218, 11.412, 21.498, 22.022, 12.267, 18.999, 13.827, 19.495, 17.836, 21.449, 23.033, 27.106, 9.034, 16.297, 16.379, 17.984, 15.182, 16.871] ,
2011 : [22.925, 14.773, 41.208, 21.335, 26.769, 22.532, 12.956, 18.0, 18.046, 17.82, 19.957, 15.391, 17.577, 23.178, 20.788, 26.867, 22.189, 19.149, 23.856, 13.999, 15.0, 14.754, 20.724, 23.027, 17.281, 26.232, 21.718, 29.622, 18.594, 8.811, 29.951, 30.763, 11.149, 14.756, 12.613, 20.681, 23.194, 12.982, 17.63, 12.952, 19.747, 16.815, 20.324, 27.476, 21.589, 8.187, 16.027, 18.8, 16.414, 14.473, 15.896] ,
2012 : [18.508, 16.173, 38.605, 19.668, 26.723, 24.202, 11.814, 18.0, 16.488, 17.686, 18.879, 15.278, 20.521, 29.205, 22.235, 28.75, 22.189, 19.376, 21.66, 15.667, 12.982, 14.156, 27.513, 20.342, 27.745, 25.23, 22.563, 17.333, 21.673, 9.334, 28.944, 32.933, 11.212, 13.773, 15.047, 21.429, 22.471, 12.463, 17.208, 12.476, 17.659, 20.341, 20.108, 27.114, 21.74, 6.931, 13.652, 18.811, 7.875, 13.863, 20.671] ,
2013 : [16.599, 15.312, 33.88, 17.661, 27.479, 20.697, 11.683, 18.0, 16.704, 16.291, 16.945, 14.931, 20.129, 29.28, 20.018, 31.251, 17.884, 17.437, 20.092, 13.893, 12.431, 14.619, 21.926, 19.531, 19.958, 20.76, 17.242, 25.996, 23.151, 11.084, 21.376, 31.302, 11.276, 12.431, 13.57, 19.472, 18.922, 13.55, 16.9, 13.449, 15.268, 18.486, 20.08, 27.152, 21.603, 8.688, 11.865, 20.075, 13.457, 12.923, 15.215] ,
2014 : [19.161, 20.077, 34.888, 17.526, 29.412, 20.779, 11.748, 18.0, 16.588, 15.584, 17.638, 15.826, 16.873, 34.019, 20.285, 30.743, 20.213, 17.633, 19.447, 13.316, 12.978, 13.592, 23.04, 19.491, 17.835, 22.051, 17.138, 24.208, 21.155, 9.991, 16.952, 29.699, 11.34, 12.986, 13.35, 19.482, 21.773, 11.282, 16.768, 12.345, 15.861, 18.045, 22.305, 32.86, 18.633, 9.696, 12.445, 19.768, 13.422, 12.24, 14.97] ,
2015 : [17.721, 16.696, 26.452, 17.719, 24.332, 17.924, 13.577, 18.0, 12.0, 15.926, 16.86, 16.025, 19.779, 27.92, 19.235, 29.48, 19.148, 16.86, 19.424, 13.71, 13.373, 11.992, 23.98, 21.829, 19.183, 21.624, 21.312, 22.912, 17.971, 11.212, 18.75, 22.154, 11.404, 12.452, 14.202, 19.66, 18.242, 12.867, 16.91, 15.218, 15.583, 18.681, 24.736, 31.919, 19.018, 10.892, 12.751, 22.284, 12.862, 11.699, 15.398] ,
2016 : [17.752, 18.138, 31.277, 17.285, 15.302, 18.806, 11.947, 18.0, 16.721, 16.276, 16.511, 15.723, 16.586, 21.698, 18.16, 26.436, 18.783, 15.6, 18.447, 11.211, 11.864, 11.958, 21.344, 20.455, 17.057, 19.529, 15.922, 21.936, 18.93, 9.771, 18.346, 27.438, 11.468, 11.256, 10.54, 16.755, 17.699, 10.644, 15.465, 11.928, 15.573, 16.649, 18.097, 25.092, 18.89, 8.541, 12.199, 16.695, 14.746, 10.782, 13.907] ,
2017 : [16.856, 13.187, 34.359, 16.944, 28.055, 19.3, 11.374, 18.0, 15.966, 16.822, 16.664, 13.223, 19.493, 22.816, 19.729, 31.319, 20.196, 16.413, 17.923, 9.129, 11.265, 10.702, 21.25, 22.92, 15.055, 21.388, 18.711, 22.737, 19.71, 10.159, 15.26, 27.724, 13.365, 13.045, 13.432, 15.536, 17.757, 16.573, 15.208, 11.34, 15.271, 16.635, 16.001, 21.884, 20.269, 7.254, 10.926, 20.97, 13.086, 11.658, 15.222] ,
2018 : [17.295, 12.612, 33.806, 16.83, 27.836, 20.961, 13.292, 18.0, 15.91, 16.583, 14.659, 12.406, 19.834, 22.651, 19.105, 32.254, 19.695, 17.209, 19.598, 8.89, 10.543, 7.826, 21.373, 23.505, 19.864, 20.246, 16.995, 18.619, 20.926, 9.505, 14.334, 26.347, 13.31, 13.667, 11.871, 15.608, 19.492, 15.915, 14.693, 12.65, 14.276, 17.193, 16.377, 23.65, 21.5, 6.61, 10.216, 19.589, 13.279, 12.796, 15.077] 
});

dataMap.PM25 = dataFormatter({
    //max : 25000,
1980 : [] ,
1981 : [] ,
1982 : [] ,
1983 : [] ,
1984 : [] ,
1985 : [] ,
1986 : [] ,
1987 : [] ,
1988 : [] ,
1989 : [] ,
1990 : [] ,
1991 : [] ,
1992 : [] ,
1993 : [] ,
1994 : [] ,
1995 : [] ,
1996 : [] ,
1997 : [] ,
1998 : [] ,
1999 : [20.468, 5.207, 10.468, 12.775, 17.163, 6.542, 12.648, 15.005, 15.971, 12.424, 19.437, 4.773, 22.427, 17.306, 16.528, 11.721, 12.443, 16.008, 14.513, 6.298, 14.244, 11.571, 13.488, 9.008, 14.392, 13.934, 7.061, 10.759, 9.181, 10.617, 12.461, 8.34, 11.397, 15.535, 7.904, 17.614, 10.529, 7.942, 14.303, 10.73, 14.151, 7.753, 16.648, 11.338, 8.616, 8.828, 13.777, 9.533, 14.56, 13.351, 5.423] ,
2000 : [19.481, 5.194, 10.402, 14.337, 15.809, 8.022, 13.123, 15.259, 15.836, 12.438, 16.933, 4.166, 17.592, 16.393, 16.458, 11.382, 11.399, 16.084, 12.568, 4.931, 16.076, 11.304, 12.734, 10.247, 14.086, 14.982, 8.306, 10.529, 8.397, 8.76, 14.357, 7.051, 12.661, 15.312, 6.198, 16.967, 11.385, 8.606, 14.621, 10.636, 14.413, 6.775, 16.167, 11.247, 9.212, 7.4, 13.826, 10.876, 13.736, 12.711, 5.749] ,
2001 : [11.825, 4.701, 9.03, 13.591, 15.154, 9.077, 14.016, 16.512, 15.1, 10.55, 16.177, 3.977, 10.585, 16.109, 16.815, 12.126, 12.599, 16.7, 11.413, 6.066, 16.512, 12.775, 13.633, 9.581, 12.69, 14.111, 6.546, 10.741, 8.78, 8.315, 14.542, 6.884, 12.995, 14.117, 6.692, 17.287, 11.706, 8.09, 16.485, 11.65, 13.226, 6.508, 15.039, 10.696, 10.595, 9.025, 13.868, 9.754, 13.183, 12.949, 5.385] ,
2002 : [15.478, 5.079, 7.582, 12.27, 19.029, 8.283, 12.929, 14.913, 15.301, 9.7, 14.055, 3.796, 8.865, 14.833, 14.904, 11.337, 11.542, 16.041, 10.309, 6.068, 15.333, 12.062, 10.798, 9.352, 11.923, 13.925, 6.254, 9.613, 9.739, 9.217, 14.114, 7.112, 11.796, 13.451, 5.994, 16.064, 10.712, 9.325, 14.688, 10.756, 12.421, 6.51, 14.436, 10.614, 10.593, 5.147, 13.006, 9.509, 12.737, 10.989, 4.713] ,
2003 : [15.602, 4.644, 6.784, 12.171, 15.765, 8.591, 13.134, 14.853, 13.197, 9.928, 14.072, 4.337, 7.9, 14.642, 14.573, 11.042, 10.525, 14.906, 11.558, 5.901, 14.967, 12.126, 13.203, 10.336, 12.556, 13.352, 7.434, 9.375, 9.56, 8.569, 15.013, 7.236, 11.796, 12.969, 6.666, 15.407, 10.69, 10.184, 14.621, 11.16, 12.146, 6.742, 14.535, 11.578, 8.227, 8.161, 13.096, 8.49, 12.521, 10.061, 4.929] ,
2004 : [15.593, 11.545, 6.289, 11.672, 13.668, 7.609, 12.179, 14.507, 13.379, 10.826, 14.554, 3.68, 8.065, 14.027, 14.25, 10.228, 9.283, 13.888, 11.118, 5.338, 15.395, 11.425, 12.145, 9.565, 12.333, 12.16, 5.43, 8.75, 8.433, 8.391, 13.698, 6.159, 10.877, 13.334, 5.903, 14.865, 9.753, 9.678, 14.079, 10.336, 13.264, 6.477, 13.704, 11.131, 12.299, 7.991, 13.095, 8.645, 13.382, 10.189, 4.583] ,
2005 : [16.883, 5.525, 6.943, 14.102, 13.192, 8.126, 12.283, 14.658, 13.561, 10.807, 14.91, 4.22, 8.591, 15.358, 15.986, 11.971, 10.797, 15.873, 12.717, 5.323, 15.671, 11.146, 13.611, 9.522, 13.9, 14.681, 6.76, 10.826, 8.253, 8.602, 14.274, 6.044, 11.347, 13.881, 5.992, 16.876, 11.07, 9.212, 14.728, 10.463, 14.203, 7.966, 15.78, 11.79, 10.452, 8.434, 13.322, 8.298, 14.874, 11.093, 4.21] ,
2006 : [16.384, 9.582, 6.631, 11.923, 12.877, 7.819, 11.184, 14.273, 12.413, 10.301, 14.97, 3.859, 7.523, 13.627, 13.514, 10.076, 4.762, 14.245, 12.411, 6.546, 14.097, 9.854, 12.051, 8.183, 12.894, 12.077, 8.903, 9.897, 8.299, 7.692, 12.984, 6.121, 10.095, 13.537, 6.541, 13.411, 9.515, 7.788, 14.976, 9.841, 14.31, 7.583, 14.202, 10.907, 9.146, 7.12, 12.597, 7.761, 14.746, 11.612, 4.727] ,
2007 : [15.905, 8.473, 7.235, 12.232, 13.723, 8.881, 10.676, 14.02, 10.769, 10.087, 15.008, 3.664, 8.181, 13.696, 14.104, 12.364, 10.986, 13.73, 11.148, 6.264, 13.571, 10.816, 11.397, 9.151, 12.582, 13.055, 9.293, 11.804, 8.298, 7.298, 12.391, 6.512, 11.281, 10.956, 6.871, 14.634, 11.527, 7.983, 15.21, 9.481, 12.883, 7.225, 14.4, 11.044, 11.18, 7.769, 12.145, 7.834, 14.962, 13.065, 4.689] ,
2008 : [8.859, 8.554, 7.033, 11.149, 14.09, 7.256, 10.095, 12.876, 10.544, 8.771, 12.731, 9.343, 8.495, 11.825, 12.919, 11.068, 10.344, 10.976, 10.203, 3.939, 12.28, 10.104, 10.445, 8.844, 11.296, 12.072, 7.026, 11.201, 8.059, 7.438, 11.666, 6.917, 10.53, 7.92, 6.404, 12.519, 10.698, 8.446, 14.246, 9.041, 11.704, 6.842, 12.604, 10.866, 10.528, 7.812, 10.884, 7.443, 12.564, 11.75, 4.381] ,
2009 : [10.954, 9.62, 6.45, 10.385, 12.14, 6.885, 8.797, 10.949, 9.232, 8.138, 11.193, 7.011, 8.486, 11.783, 13.023, 11.045, 9.252, 9.085, 9.195, 5.558, 10.446, 9.162, 10.061, 8.671, 10.188, 11.035, 7.31, 8.584, 6.971, 7.689, 9.741, 6.642, 9.239, 9.709, 6.388, 12.316, 10.574, 8.236, 12.523, 7.715, 10.24, 6.458, 10.933, 10.09, 10.471, 7.061, 9.172, 7.985, 11.35, 10.609, 3.97] ,
2010 : [12.113, 8.155, 6.986, 11.652, 10.405, 6.975, 8.297, 10.969, 10.099, 8.456, 12.076, 7.176, 8.639, 12.427, 13.459, 11.968, 9.65, 10.052, 9.887, 5.666, 10.926, 8.91, 10.166, 8.658, 11.278, 11.549, 7.832, 9.844, 6.105, 6.903, 9.283, 5.605, 9.187, 10.443, 8.273, 13.1, 10.633, 6.129, 12.798, 7.538, 10.232, 6.905, 12.51, 9.941, 8.919, 6.9, 9.78, 6.114, 12.784, 10.116, 3.657] ,
2011 : [11.874, 7.2, 8.893, 11.341, 13.223, 7.029, 9.514, 18.896, 11.018, 8.784, 12.21, 6.01, 7.735, 12.173, 12.461, 10.652, 11.205, 10.463, 9.63, 5.924, 10.999, 9.531, 9.426, 8.184, 7.71, 11.014, 7.87, 9.341, 6.485, 7.548, 9.62, 6.388, 9.393, 10.115, 6.939, 8.247, 10.759, 7.299, 12.243, 8.37, 10.236, 6.218, 11.886, 10.338, 7.704, 8.354, 9.244, 6.812, 11.652, 9.711, 3.872] ,
2012 : [10.309, 8.158, 8.112, 10.207, 17.041, 7.473, 8.405, 10.169, 10.822, 7.826, 10.593, 7.006, 9.941, 11.029, 11.39, 10.027, 10.044, 9.706, 8.955, 5.584, 9.792, 8.424, 9.082, 7.124, 10.453, 10.418, 9.082, 8.776, 7.251, 5.86, 8.711, 6.424, 8.372, 9.075, 6.405, 10.934, 9.808, 6.895, 11.197, 7.387, 9.443, 6.707, 11.11, 10.085, 7.588, 7.705, 8.386, 6.749, 11.538, 8.738, 5.033] ,
2013 : [12.027, 9.104, 7.461, 9.606, 11.086, 6.388, 9.774, 8.975, 9.791, 7.106, 9.568, 6.075, 9.403, 10.2, 10.983, 9.782, 10.487, 9.17, 8.189, 6.601, 8.943, 8.452, 11.152, 6.743, 9.591, 9.79, 6.919, 8.942, 8.944, 6.822, 8.73, 6.001, 8.175, 8.624, 5.943, 10.345, 9.382, 8.913, 10.727, 7.257, 8.444, 7.184, 9.719, 9.579, 10.342, 6.469, 7.682, 7.252, 10.517, 8.204, 3.704] ,
2014 : [10.565, 9.925, 7.586, 9.39, 22.342, 6.448, 8.493, 9.235, 6.535, 7.563, 10.1, 5.39, 8.495, 10.219, 11.583, 9.818, 10.206, 9.412, 8.797, 6.592, 9.034, 7.821, 9.17, 6.145, 10.071, 9.804, 6.622, 7.964, 7.21, 6.861, 8.613, 5.352, 7.672, 8.877, 4.947, 10.719, 9.0, 6.801, 10.927, 6.262, 8.738, 5.494, 9.553, 9.422, 6.948, 6.084, 7.722, 6.473, 10.451, 8.243, 3.727] ,
2015 : [10.161, 10.448, 6.701, 8.862, 16.905, 6.339, 8.271, 8.522, 6.672, 7.311, 8.893, 6.173, 9.14, 9.597, 10.048, 9.208, 8.391, 9.593, 9.157, 6.273, 8.623, 7.42, 8.937, 5.939, 9.313, 8.816, 8.558, 7.785, 6.536, 7.066, 8.653, 5.194, 7.558, 8.074, 5.824, 9.621, 8.017, 7.544, 11.125, 7.307, 8.018, 6.194, 9.037, 8.871, 6.745, 5.834, 7.546, 6.997, 9.377, 6.783, 3.967] ,
2016 : [9.438, 7.784, 7.029, 8.583, 11.461, 5.869, 6.816, 7.749, 9.031, 7.45, 8.87, 6.317, 7.059, 8.37, 8.587, 8.218, 6.581, 9.239, 8.766, 5.185, 7.255, 6.873, 8.058, 5.32, 8.519, 7.558, 7.214, 7.071, 6.329, 4.797, 8.539, 5.458, 6.368, 7.954, 3.758, 8.15, 7.718, 5.661, 10.128, 6.48, 8.255, 4.523, 6.62, 8.218, 6.06, 4.57, 6.806, 5.649, 8.212, 6.523, 3.473] ,
2017 : [9.502, 8.208, 7.622, 8.993, 10.618, 6.207, 6.066, 7.602, 9.579, 8.031, 8.71, 5.469, 9.983, 8.695, 8.27, 8.427, 7.043, 8.975, 8.369, 5.525, 7.223, 7.907, 8.005, 5.763, 8.741, 7.731, 13.46, 8.346, 6.127, 4.656, 8.268, 5.081, 6.391, 7.806, 5.323, 8.108, 8.202, 9.427, 10.324, 6.035, 7.689, 5.307, 7.912, 8.402, 6.704, 4.933, 6.751, 7.905, 9.419, 5.971, 4.519] ,
2018 : [8.97, 6.47, 6.572, 6.22, 32.418, 7.124, 6.892, 7.362, 8.867, 7.571, 8.459, 5.412, 8.315, 9.435, 8.79, 8.495, 7.803, 8.918, 8.875, 5.092, 7.146, 15.2, 8.372, 6.426, 8.73, 8.045, 7.741, 7.646, 6.724, 4.746, 7.905, 5.786, 6.382, 7.507, 5.732, 8.341, 5.483, 8.837, 9.111, 6.818, 7.065, 5.279, 7.898, 8.691, 5.904, 5.775, 6.699, 7.715, 8.556, 6.852, 4.212]
});

dataMap.SO2 = dataFormatter({
    //max : 3600,
    1980 : [38.33, 10.87, 113.528, 9.519, 12.91, 36.92, 23.897, 21.583, 29.93, 22.121, 22.517, 3.541, 76.486, 40.673, 10.447, 20.125, 39.426, 44.943, 11.984, 35.799, 27.912, 27.848, 21.254, 17.686, 12.133, 38.512, 58.582, 16.384, 2.203, 54.279, 24.35, 28.522, 36.398, 36.112, 4.423, 17.218, 16.924, 32.496, 27.264, 35.283, 13.117, 0.069, 11.665, 11.146, 36.175, 15.09, 24.315, 34.72, 50.1, 24.338, 0.339] ,
    1981 : [34.954, 8.378, 152.372, 10.48, 10.216, 32.252, 25.138, 19.51, 28.013, 21.623, 25.586, 10.633, 86.709, 31.962, 40.383, 23.076, 43.45, 39.682, 11.742, 25.79, 32.08, 26.499, 23.207, 22.922, 10.702, 31.31, 18.606, 10.158, 5.24, 39.26, 26.651, 38.487, 36.932, 53.619, 19.933, 41.201, 17.105, 21.973, 39.72, 32.285, 21.892, 0.206, 36.037, 24.402, 62.799, 15.477, 21.035, 34.093, 37.918, 21.108, 1.017] ,
    1982 : [26.756, 4.008, 76.013, 9.167, 8.593, 22.65, 24.986, 22.381, 27.614, 21.748, 30.048, 7.699, 62.678, 27.311, 38.37, 36.5, 28.44, 36.1, 12.214, 23.465, 28.953, 26.302, 21.143, 27.033, 13.394, 32.417, 27.982, 11.288, 5.685, 37.571, 26.279, 36.493, 33.826, 20.523, 11.548, 39.79, 15.599, 16.999, 44.444, 30.076, 13.051, 0.343, 30.073, 24.476, 48.51, 18.751, 19.471, 29.503, 56.982, 29.408, 1.695] ,
    1983 : [25.825, 2.931, 89.572, 4.898, 8.203, 25.849, 22.693, 29.609, 25.06, 16.749, 17.678, 11.47, 57.334, 31.597, 38.455, 24.138, 20.972, 30.725, 16.271, 23.497, 27.322, 23.198, 19.42, 24.396, 13.992, 36.362, 45.336, 7.255, 5.673, 25.226, 26.512, 44.033, 33.545, 10.058, 7.62, 36.517, 21.306, 10.067, 43.653, 24.239, 13.983, 0.481, 29.758, 25.08, 36.171, 16.564, 17.653, 29.643, 59.873, 27.714, 2.372] ,
    1984 : [20.263, 0.77, 84.482, 2.631, 9.346, 20.736, 22.576, 28.15, 26.732, 19.05, 30.195, 24.54, 87.589, 33.598, 40.486, 29.918, 28.99, 29.245, 14.692, 25.95, 27.825, 24.783, 20.448, 14.202, 14.173, 33.816, 29.986, 6.734, 5.661, 25.815, 27.732, 35.381, 33.929, 10.312, 11.007, 39.497, 27.972, 10.234, 44.062, 24.57, 11.049, 0.618, 31.778, 25.681, 36.452, 17.856, 17.41, 32.686, 63.083, 29.788, 3.05] ,
    1985 : [17.841, 27.695, 63.173, 9.367, 7.874, 16.167, 23.19, 26.557, 23.29, 18.055, 31.216, 29.056, 78.028, 32.972, 39.437, 32.426, 23.076, 32.813, 16.632, 26.158, 23.167, 24.161, 18.724, 11.997, 17.551, 33.777, 26.332, 5.124, 5.649, 23.137, 25.109, 32.049, 32.573, 12.726, 11.042, 36.675, 44.77, 13.575, 34.621, 24.144, 8.789, 0.756, 30.53, 28.513, 26.134, 16.672, 16.744, 30.029, 54.544, 25.818, 3.729] ,
    1986 : [23.959, 21.664, 54.013, 5.632, 6.975, 14.924, 23.859, 27.784, 27.149, 19.724, 33.666, 129.074, 49.782, 33.262, 34.077, 35.317, 18.587, 30.252, 14.327, 42.045, 27.065, 27.944, 19.023, 12.718, 25.266, 34.078, 33.389, 1.043, 5.637, 24.865, 22.568, 39.715, 30.593, 13.301, 9.636, 33.727, 33.533, 19.569, 35.559, 29.518, 9.79, 0.893, 32.19, 24.411, 25.709, 14.814, 17.619, 32.249, 61.234, 23.105, 4.406] ,
    1987 : [67.528, 8.131, 24.784, 7.921, 5.783, 22.074, 22.443, 27.168, 25.366, 19.332, 26.673, 137.321, 43.102, 32.635, 34.246, 30.007, 28.302, 29.521, 13.4, 28.033, 26.278, 25.896, 20.953, 15.873, 15.61, 30.195, 29.742, 9.674, 5.625, 22.699, 22.069, 43.173, 28.253, 12.744, 7.17, 33.364, 25.786, 17.831, 36.562, 28.342, 7.686, 1.03, 34.46, 21.994, 38.493, 14.064, 16.586, 29.209, 64.49, 18.128, 5.084] ,
    1988 : [25.602, 2.798, 25.336, 10.383, 6.634, 9.338, 24.269, 32.542, 23.811, 18.393, 24.778, 67.243, 41.775, 32.939, 33.854, 35.958, 17.686, 30.325, 12.678, 26.819, 26.463, 25.875, 20.416, 15.818, 17.101, 30.172, 34.268, 9.616, 5.613, 24.58, 23.845, 30.364, 30.825, 13.53, 10.148, 34.789, 29.726, 12.136, 38.368, 32.865, 8.096, 1.168, 31.917, 23.701, 50.803, 15.275, 16.951, 28.424, 63.793, 22.028, 5.762] ,
    1989 : [12.417, 6.223, 18.044, 11.242, 5.921, 9.478, 22.233, 33.148, 19.222, 19.925, 22.13, 168.003, 39.772, 30.05, 34.09, 29.031, 10.264, 29.603, 14.283, 24.238, 25.418, 23.152, 22.679, 13.348, 14.416, 29.339, 28.207, 6.512, 5.601, 23.86, 23.366, 36.556, 30.727, 13.277, 9.11, 35.82, 27.006, 17.714, 37.09, 25.857, 7.522, 1.305, 27.745, 25.538, 44.829, 14.707, 22.297, 29.07, 57.919, 20.435, 6.44] ,
    1990 : [22.211, 12.021, 22.972, 15.241, 5.627, 9.676, 19.911, 28.09, 27.805, 14.798, 24.503, 75.015, 34.775, 26.129, 33.572, 29.325, 10.976, 28.563, 13.322, 19.555, 21.876, 21.19, 22.847, 11.604, 5.667, 25.651, 27.903, 5.805, 5.589, 24.268, 20.376, 33.844, 33.893, 9.99, 9.876, 34.846, 25.499, 16.039, 36.063, 22.114, 8.942, 1.443, 29.18, 22.542, 46.508, 14.416, 23.665, 24.667, 58.023, 21.911, 7.118] ,
    1991 : [21.528, 28.71, 17.246, 14.493, 5.217, 11.387, 18.513, 26.349, 27.301, 11.735, 18.696, 30.894, 5.736, 28.42, 31.499, 24.906, 11.673, 30.7, 15.027, 18.559, 21.008, 20.204, 21.137, 6.858, 11.831, 24.123, 23.341, 6.775, 5.578, 21.018, 19.396, 30.913, 27.835, 10.89, 8.22, 39.037, 20.355, 17.036, 31.483, 21.242, 8.198, 1.58, 25.768, 22.739, 39.338, 16.864, 18.832, 23.283, 53.391, 18.519, 7.795] ,
    1992 : [21.803, 45.686, 11.944, 20.654, 4.816, 13.282, 15.663, 27.24, 22.602, 18.966, 18.249, 32.034, 5.207, 24.412, 29.92, 21.584, 8.151, 27.704, 13.946, 17.215, 19.163, 19.44, 15.755, 9.036, 6.667, 21.075, 28.591, 6.163, 5.566, 21.761, 17.417, 30.308, 23.253, 9.871, 7.811, 27.311, 21.27, 17.438, 29.78, 21.303, 7.829, 1.717, 25.322, 25.516, 40.224, 13.522, 16.707, 24.404, 46.614, 15.453, 8.473] ,
    1993 : [28.384, 38.43, 25.379, 25.503, 3.987, 13.429, 14.511, 28.635, 21.953, 19.075, 16.394, 22.718, 4.679, 23.404, 31.544, 19.127, 7.232, 26.317, 13.483, 18.882, 20.626, 18.499, 16.811, 12.582, 12.132, 21.999, 27.672, 7.03, 5.554, 19.307, 15.635, 23.847, 20.837, 8.245, 7.566, 31.359, 13.674, 16.528, 37.878, 19.584, 7.095, 1.855, 24.187, 20.507, 30.664, 13.978, 18.839, 18.982, 54.18, 14.214, 9.152] ,
    1994 : [26.508, 1.041, 4.367, 13.232, 3.909, 13.974, 14.535, 28.076, 22.617, 18.23, 14.442, 16.796, 6.231, 25.18, 28.387, 28.44, 7.522, 26.067, 11.357, 16.872, 21.059, 17.023, 17.169, 7.371, 11.387, 24.024, 30.414, 12.077, 5.542, 17.205, 15.784, 20.193, 20.318, 9.636, 8.15, 28.511, 11.655, 13.233, 30.563, 17.607, 6.319, 1.992, 22.379, 17.518, 20.204, 16.016, 17.696, 21.584, 52.48, 13.732, 18.808] ,
    1995 : [21.753, 1.103, 11.555, 13.299, 4.631, 12.092, 11.252, 26.415, 19.051, 14.482, 11.356, 32.804, 16.397, 23.033, 21.915, 18.027, 7.478, 20.569, 12.432, 15.292, 15.962, 13.078, 14.956, 6.517, 10.858, 22.163, 26.384, 13.279, 5.53, 14.062, 12.553, 20.604, 15.732, 11.008, 7.511, 20.141, 4.167, 9.589, 23.871, 12.486, 5.221, 2.13, 17.441, 16.642, 11.421, 8.341, 15.184, 20.161, 32.478, 13.418, 72.404] ,
    1996 : [17.648, 1.165, 14.52, 12.772, 4.5, 13.536, 10.422, 25.242, 16.814, 14.716, 12.589, 28.06, 16.176, 17.398, 21.27, 15.123, 6.515, 19.607, 13.915, 14.357, 15.421, 13.846, 15.994, 7.02, 10.544, 24.569, 18.919, 15.981, 5.518, 14.75, 12.963, 15.0, 15.917, 10.161, 9.356, 21.318, 13.907, 7.526, 33.177, 16.12, 6.984, 2.267, 19.195, 16.047, 8.139, 9.092, 16.107, 16.893, 31.235, 13.194, 44.333] ,
    1997 : [18.249, 1.227, 10.695, 13.045, 4.239, 11.695, 10.661, 23.985, 16.132, 14.943, 9.111, 20.155, 0.667, 31.747, 21.758, 14.798, 4.125, 20.323, 13.461, 12.429, 15.708, 14.297, 15.197, 7.166, 6.603, 21.792, 16.775, 15.659, 5.506, 13.447, 12.559, 21.407, 16.176, 13.478, 8.227, 21.568, 18.241, 7.054, 25.357, 13.967, 6.701, 2.405, 21.926, 14.007, 7.099, 11.185, 20.78, 10.056, 33.764, 14.075, 36.815] ,
    1998 : [17.944, 1.289, 10.767, 8.806, 4.77, 11.686, 10.832, 24.391, 17.934, 16.096, 10.831, 16.412, 19.075, 21.532, 22.005, 12.424, 12.353, 22.549, 22.983, 11.517, 18.92, 15.129, 17.976, 7.15, 7.952, 21.331, 16.852, 6.253, 1.895, 15.64, 12.245, 21.085, 15.191, 11.497, 8.325, 21.237, 19.087, 6.583, 23.121, 20.833, 7.618, 2.542, 19.522, 13.202, 6.905, 8.149, 14.979, 15.246, 35.984, 13.337, 29.308] ,
    1999 : [32.325, 1.35, 19.193, 10.003, 5.428, 10.247, 11.575, 21.948, 18.803, 16.224, 12.027, 12.8, 25.482, 22.329, 21.133, 13.483, 11.954, 20.7, 12.784, 9.662, 21.162, 13.541, 18.27, 5.565, 8.489, 21.122, 15.206, 3.227, 1.737, 15.154, 11.76, 14.797, 15.135, 9.625, 8.103, 21.671, 15.298, 6.112, 22.558, 14.405, 7.748, 2.679, 19.186, 11.487, 7.626, 6.96, 13.934, 11.096, 37.609, 16.239, 21.8] ,
    2000 : [17.433, 1.412, 16.906, 12.674, 5.185, 9.675, 10.527, 17.942, 17.5, 14.219, 12.71, 14.141, 28.171, 18.067, 20.093, 10.915, 7.96, 19.297, 8.25, 8.484, 20.205, 12.504, 16.646, 5.889, 8.979, 18.787, 18.518, 1.742, 0.885, 11.981, 11.591, 10.244, 15.295, 11.775, 7.971, 20.12, 11.209, 5.64, 21.413, 12.742, 7.38, 2.817, 18.207, 10.543, 8.251, 8.176, 14.383, 7.269, 35.04, 15.456, 14.282] ,
    2001 : [11.998, 1.474, 20.033, 6.737, 5.068, 13.282, 11.755, 18.367, 16.249, 13.05, 11.534, 18.395, 48.797, 17.326, 20.633, 6.636, 5.311, 16.347, 11.866, 8.253, 19.189, 11.368, 14.589, 4.145, 6.6, 17.313, 14.783, 0.713, 2.005, 12.601, 12.443, 8.429, 14.579, 11.706, 7.536, 20.287, 10.729, 5.168, 21.741, 13.378, 7.815, 2.517, 18.517, 9.336, 10.0, 11.648, 13.426, 6.808, 32.867, 15.161, 6.764] ,
    2002 : [8.945, 1.536, 16.676, 5.342, 4.67, 16.333, 10.18, 16.888, 16.86, 12.435, 10.417, 26.32, 27.884, 15.212, 18.955, 8.526, 5.425, 13.733, 12.023, 7.339, 19.186, 9.941, 11.929, 3.181, 7.25, 16.145, 12.866, 2.012, 1.374, 10.821, 11.224, 4.761, 12.943, 14.567, 6.742, 19.686, 9.867, 4.697, 19.103, 11.644, 6.953, 2.399, 16.042, 7.258, 8.717, 12.275, 12.607, 5.355, 30.048, 16.39, 14.474] ,
    2003 : [10.806, 1.598, 16.484, 5.37, 3.991, 11.246, 9.39, 28.375, 18.019, 10.157, 10.554, 34.254, 32.079, 14.526, 19.566, 9.302, 7.486, 13.637, 11.463, 6.664, 18.607, 10.235, 12.424, 4.363, 6.047, 15.96, 11.782, 3.047, 2.923, 13.503, 11.812, 5.765, 12.551, 13.029, 7.198, 19.587, 12.0, 4.19, 23.697, 11.99, 7.054, 3.274, 15.928, 8.403, 6.716, 8.407, 11.958, 6.081, 31.339, 9.199, 10.87] ,
    2004 : [7.902, 1.659, 18.241, 4.432, 4.493, 10.341, 9.154, 16.663, 15.09, 8.489, 10.815, 27.403, 19.422, 14.093, 19.114, 9.874, 4.363, 14.764, 12.284, 6.649, 16.552, 8.62, 13.167, 3.551, 5.429, 12.315, 14.244, 4.519, 2.063, 16.319, 11.38, 3.639, 10.564, 11.262, 3.324, 19.338, 10.783, 2.0, 18.55, 12.331, 14.195, 2.052, 16.609, 7.367, 6.815, 7.255, 12.025, 5.357, 30.791, 11.186, 10.893] ,
    2005 : [9.853, 1.721, 12.866, 4.053, 3.537, 9.066, 9.821, 18.685, 11.718, 8.175, 11.043, 39.902, 14.944, 14.393, 18.052, 11.407, 3.528, 15.124, 12.638, 5.871, 18.433, 9.22, 13.122, 3.431, 6.477, 14.683, 14.386, 6.312, 5.514, 16.441, 11.458, 3.784, 9.584, 10.587, 5.482, 25.991, 11.071, 3.416, 20.117, 11.901, 7.656, 1.796, 14.059, 8.31, 7.564, 12.57, 11.668, 6.535, 29.784, 12.67, 11.189] ,
    2006 : [10.125, 1.783, 12.447, 5.703, 3.783, 9.448, 7.482, 9.125, 11.364, 7.442, 11.949, 44.27, 15.707, 13.51, 16.238, 8.92, 3.373, 13.5, 10.152, 3.904, 13.239, 7.166, 12.967, 3.066, 9.951, 16.871, 12.36, 4.029, 2.036, 14.905, 9.174, 3.552, 7.972, 10.376, 3.877, 16.605, 9.669, 3.316, 16.932, 8.771, 6.784, 1.288, 14.66, 7.282, 6.963, 11.046, 9.677, 5.448, 28.334, 12.153, 6.536] ,
    2007 : [9.538, 1.845, 14.265, 5.174, 2.937, 8.907, 6.244, 11.709, 11.779, 6.676, 10.357, 28.803, 14.801, 13.174, 16.035, 8.834, 3.877, 13.786, 15.123, 1.868, 12.975, 7.657, 18.807, 2.502, 8.164, 14.442, 13.302, 4.075, 0.932, 18.339, 7.739, 3.09, 7.598, 9.302, 4.294, 15.348, 7.434, 2.618, 16.58, 7.779, 6.095, 1.256, 14.2, 5.563, 6.492, 11.523, 10.646, 4.166, 28.969, 14.923, 4.031] ,
    2008 : [10.94, 1.907, 17.019, 3.724, 2.627, 7.658, 4.745, 6.5, 10.657, 5.368, 8.864, 67.072, 13.47, 11.522, 15.065, 3.574, 4.414, 10.932, 14.439, 3.441, 9.5, 6.4, 14.038, 2.578, 7.457, 18.48, 12.779, 2.714, 0.508, 15.671, 6.381, 1.184, 6.289, 9.274, 4.216, 13.578, 9.205, 2.035, 14.23, 5.53, 5.955, 1.672, 14.7, 4.405, 6.243, 8.616, 8.346, 2.598, 25.616, 12.181, 4.438] ,
    2009 : [6.901, 1.968, 14.539, 3.533, 2.185, 7.478, 4.25, 6.88, 8.76, 4.184, 5.649, 84.033, 9.723, 7.555, 11.287, 2.587, 3.805, 8.4, 13.837, 3.241, 7.67, 4.735, 7.385, 1.886, 5.524, 15.976, 7.962, 3.448, 2.113, 19.202, 4.633, 1.71, 4.756, 6.533, 4.215, 11.459, 5.945, 2.802, 12.418, 4.51, 3.935, 1.036, 8.655, 3.72, 6.241, 6.593, 6.694, 2.574, 19.871, 10.104, 3.853] ,
    2010 : [5.784, 2.03, 16.885, 3.049, 1.831, 7.512, 2.9, 4.501, 5.726, 4.369, 6.513, 92.315, 8.888, 7.831, 11.057, 3.147, 4.527, 9.129, 13.301, 2.083, 4.832, 4.631, 8.765, 1.612, 5.618, 16.457, 6.629, 3.234, 3.716, 19.52, 4.014, 2.609, 3.696, 5.54, 4.68, 10.546, 6.872, 2.423, 9.893, 3.063, 4.193, 1.115, 10.844, 3.908, 5.367, 5.793, 5.79, 3.543, 14.105, 9.467, 5.713] ,
    2011 : [6.604, 6.536, 13.505, 3.228, 1.832, 7.489, 3.356, 3.335, 4.5, 4.319, 6.024, 29.434, 11.411, 7.054, 9.439, 5.65, 3.742, 7.463, 15.962, 1.749, 4.154, 4.324, 7.214, 1.246, 3.773, 15.121, 5.202, 6.543, 2.013, 9.992, 6.604, 2.569, 3.397, 2.962, 3.559, 10.491, 7.388, 1.934, 7.251, 3.662, 3.318, 1.136, 8.74, 3.411, 3.352, 4.965, 4.837, 2.131, 12.467, 8.159, 4.979] ,
    2012 : [6.599, 14.775, 16.983, 3.303, 1.575, 5.212, 2.274, 2.598, 3.018, 3.333, 4.447, 47.821, 10.617, 6.435, 8.177, 5.853, 3.262, 6.084, 10.482, 1.724, 2.832, 3.485, 8.555, 1.187, 3.335, 13.482, 4.312, 6.478, 1.917, 2.34, 3.255, 1.92, 2.828, 2.318, 4.318, 8.674, 6.273, 1.437, 6.66, 3.261, 1.709, 1.073, 6.956, 2.464, 3.069, 2.453, 3.109, 2.04, 9.379, 8.441, 5.06] ,
    2013 : [3.702, 12.9, 14.63, 2.661, 1.569, 7.459, 1.916, 1.824, 2.222, 2.901, 5.007, 34.941, 8.201, 5.493, 8.013, 5.966, 2.49, 4.14, 6.272, 1.635, 2.411, 2.959, 6.054, 1.352, 3.915, 10.2, 4.099, 5.093, 2.093, 2.36, 2.114, 1.323, 2.459, 1.794, 3.408, 6.196, 4.554, 1.978, 5.506, 1.77, 1.568, 0.826, 3.927, 2.359, 3.415, 1.504, 3.264, 1.276, 7.582, 6.604, 4.195] ,
    2014 : [5.165, 12.601, 17.205, 2.785, 1.408, 5.831, 1.667, 2.002, 2.499, 3.782, 5.151, 35.268, 7.874, 5.285, 8.58, 7.331, 3.462, 5.668, 5.518, 1.175, 2.798, 2.21, 6.821, 1.368, 3.292, 7.566, 4.531, 4.963, 1.851, 2.281, 2.224, 0.73, 2.251, 1.485, 2.834, 6.273, 3.149, 1.485, 6.145, 1.578, 2.287, 0.844, 7.64, 2.084, 1.345, 1.473, 2.959, 1.024, 7.437, 7.053, 4.253] ,
    2015 : [4.032, 18.6, 16.937, 2.831, 1.252, 6.35, 0.964, 1.408, 2.917, 2.917, 3.862, 40.227, 6.213, 4.107, 4.581, 1.985, 2.826, 3.048, 4.211, 1.408, 2.269, 1.368, 5.294, 1.306, 2.135, 5.447, 2.836, 4.903, 1.878, 1.806, 0.961, 0.729, 2.005, 1.287, 2.622, 4.892, 2.502, 1.191, 5.471, 0.932, 1.035, 1.171, 6.033, 1.855, 1.087, 0.549, 1.748, 1.094, 5.907, 6.116, 3.042] ,
    2016 : [3.818, 10.45, 17.06, 2.324, 1.297, 5.13, 0.745, 1.055, 1.922, 2.471, 3.894, 48.177, 5.789, 3.89, 3.781, 2.491, 1.434, 2.027, 2.63, 0.945, 1.498, 1.291, 2.36, 1.665, 1.223, 2.681, 1.887, 3.956, 1.444, 1.485, 0.869, 1.169, 1.13, 1.247, 2.196, 2.885, 2.739, 0.694, 3.329, 0.657, 0.684, 0.546, 5.874, 2.0, 1.269, 0.34, 1.251, 0.811, 4.698, 3.845, 2.688] ,
    2017 : [6.561, 11.188, 17.069, 1.398, 1.277, 2.588, 0.568, 0.762, 1.624, 2.337, 2.885, 41.002, 6.559, 4.438, 3.049, 2.047, 1.533, 2.512, 3.264, 1.405, 6.402, 1.071, 4.184, 1.709, 0.78, 3.592, 2.769, 4.215, 1.359, 1.445, 0.562, 1.39, 1.935, 4.895, 2.731, 2.183, 3.05, 0.847, 3.389, 0.603, 0.658, 0.702, 7.452, 4.865, 1.045, 0.441, 7.499, 5.741, 5.231, 2.547, 3.42] ,
    2018 : [4.535, 10.309, 11.753, 1.416, 1.198, 1.924, 0.525, 0.737, 1.524, 2.25, 3.323, 28.542, 7.326, 5.071, 2.858, 1.679, 1.532, 2.354, 3.033, 0.775, 5.655, 0.908, 4.616, 1.499, 0.9, 5.574, 2.559, 2.127, 1.189, 1.678, 0.852, 0.964, 1.736, 4.164, 2.78, 2.079, 2.406, 0.868, 2.725, 0.54, 0.562, 0.609, 6.031, 5.709, 0.803, 0.582, 2.444, 5.587, 5.453, 2.663, 2.849]
});

dataMap.Ozone = dataFormatter({
    //max : 3200,
    1980 : [0.044, 0.026, 0.044, 0.042, 0.001, 0.039, 0.043, 0.037, 0.033, 0.037, 0.042, 0.019, 0.002, 0.033, 0.033, 0.037, 0.036, 0.038, 0.037, 0.034, 0.038, 0.036, 0.034, 0.034, 0.045, 0.036, 0.039, 0.035, 0.037, 0.034, 0.041, 0.038, 0.034, 0.043, 0.038, 0.034, 0.04, 0.03, 0.004, 0.042, 0.045, 0.002, 0.036, 0.016, 0.043, 0.042, 0.038, 0.023, 0.029, 0.033, 0.004] ,
    1981 : [0.045, 0.028, 0.042, 0.039, 0.004, 0.038, 0.037, 0.037, 0.029, 0.028, 0.04, 0.014, 0.006, 0.014, 0.018, 0.028, 0.033, 0.012, 0.041, 0.035, 0.037, 0.033, 0.033, 0.029, 0.042, 0.034, 0.034, 0.024, 0.037, 0.03, 0.036, 0.043, 0.031, 0.042, 0.037, 0.033, 0.04, 0.029, 0.034, 0.039, 0.043, 0.006, 0.034, 0.041, 0.038, 0.036, 0.037, 0.025, 0.032, 0.033, 0.011] ,
    1982 : [0.042, 0.032, 0.04, 0.037, 0.007, 0.026, 0.044, 0.034, 0.03, 0.036, 0.043, 0.013, 0.01, 0.033, 0.039, 0.035, 0.033, 0.038, 0.036, 0.037, 0.039, 0.033, 0.033, 0.031, 0.041, 0.037, 0.037, 0.027, 0.042, 0.033, 0.039, 0.041, 0.033, 0.014, 0.036, 0.014, 0.039, 0.031, 0.037, 0.038, 0.037, 0.011, 0.014, 0.017, 0.041, 0.034, 0.039, 0.026, 0.032, 0.035, 0.018] ,
    1983 : [0.043, 0.014, 0.039, 0.042, 0.01, 0.04, 0.051, 0.045, 0.033, 0.036, 0.048, 0.019, 0.014, 0.036, 0.041, 0.038, 0.036, 0.041, 0.033, 0.039, 0.042, 0.036, 0.032, 0.033, 0.044, 0.037, 0.04, 0.028, 0.035, 0.034, 0.042, 0.041, 0.035, 0.043, 0.035, 0.037, 0.022, 0.029, 0.038, 0.039, 0.045, 0.015, 0.038, 0.045, 0.038, 0.037, 0.043, 0.024, 0.04, 0.036, 0.025] ,
    1984 : [0.041, 0.009, 0.04, 0.041, 0.012, 0.039, 0.045, 0.041, 0.029, 0.038, 0.04, 0.017, 0.018, 0.035, 0.038, 0.032, 0.034, 0.037, 0.034, 0.041, 0.036, 0.036, 0.029, 0.034, 0.044, 0.037, 0.033, 0.028, 0.042, 0.029, 0.039, 0.042, 0.033, 0.041, 0.038, 0.035, 0.042, 0.029, 0.035, 0.041, 0.043, 0.019, 0.036, 0.043, 0.042, 0.039, 0.039, 0.025, 0.038, 0.035, 0.033] ,
    1985 : [0.041, 0.02, 0.042, 0.039, 0.015, 0.038, 0.038, 0.028, 0.036, 0.037, 0.039, 0.017, 0.021, 0.036, 0.034, 0.032, 0.033, 0.038, 0.039, 0.041, 0.042, 0.036, 0.032, 0.034, 0.038, 0.036, 0.028, 0.034, 0.042, 0.032, 0.042, 0.042, 0.035, 0.042, 0.038, 0.036, 0.039, 0.03, 0.039, 0.035, 0.041, 0.023, 0.037, 0.041, 0.043, 0.041, 0.043, 0.027, 0.041, 0.037, 0.04] ,
    1986 : [0.043, 0.031, 0.04, 0.041, 0.018, 0.038, 0.038, 0.038, 0.035, 0.037, 0.046, 0.016, 0.025, 0.033, 0.039, 0.028, 0.032, 0.04, 0.04, 0.038, 0.04, 0.035, 0.031, 0.031, 0.041, 0.035, 0.023, 0.03, 0.044, 0.031, 0.04, 0.041, 0.035, 0.043, 0.037, 0.035, 0.038, 0.032, 0.036, 0.037, 0.044, 0.027, 0.037, 0.04, 0.04, 0.038, 0.04, 0.028, 0.039, 0.036, 0.047] ,
    1987 : [0.039, 0.037, 0.042, 0.043, 0.021, 0.04, 0.039, 0.042, 0.034, 0.041, 0.048, 0.023, 0.029, 0.036, 0.04, 0.025, 0.034, 0.012, 0.044, 0.039, 0.043, 0.035, 0.034, 0.035, 0.044, 0.038, 0.019, 0.03, 0.045, 0.033, 0.041, 0.043, 0.038, 0.045, 0.038, 0.036, 0.042, 0.037, 0.039, 0.036, 0.045, 0.031, 0.041, 0.043, 0.042, 0.038, 0.044, 0.03, 0.04, 0.039, 0.047] ,
    1988 : [0.044, 0.033, 0.043, 0.043, 0.023, 0.041, 0.04, 0.042, 0.031, 0.04, 0.048, 0.01, 0.033, 0.039, 0.042, 0.031, 0.036, 0.014, 0.043, 0.043, 0.043, 0.039, 0.039, 0.039, 0.046, 0.042, 0.032, 0.037, 0.045, 0.039, 0.042, 0.044, 0.039, 0.048, 0.041, 0.038, 0.046, 0.029, 0.04, 0.04, 0.045, 0.04, 0.044, 0.043, 0.043, 0.043, 0.044, 0.026, 0.041, 0.042, 0.044] ,
    1989 : [0.036, 0.033, 0.042, 0.041, 0.026, 0.039, 0.042, 0.038, 0.029, 0.038, 0.039, 0.015, 0.033, 0.036, 0.038, 0.03, 0.033, 0.016, 0.038, 0.039, 0.037, 0.036, 0.041, 0.037, 0.039, 0.037, 0.033, 0.034, 0.04, 0.036, 0.038, 0.044, 0.036, 0.044, 0.038, 0.036, 0.044, 0.031, 0.036, 0.041, 0.04, 0.035, 0.038, 0.039, 0.042, 0.046, 0.039, 0.025, 0.035, 0.04, 0.042] ,
    1990 : [0.041, 0.032, 0.043, 0.043, 0.029, 0.038, 0.038, 0.034, 0.028, 0.039, 0.046, 0.017, 0.027, 0.034, 0.041, 0.027, 0.031, 0.036, 0.041, 0.039, 0.039, 0.035, 0.038, 0.033, 0.043, 0.035, 0.036, 0.032, 0.038, 0.037, 0.038, 0.043, 0.035, 0.048, 0.036, 0.035, 0.043, 0.033, 0.035, 0.037, 0.045, 0.035, 0.043, 0.039, 0.038, 0.043, 0.041, 0.028, 0.037, 0.036, 0.036] ,
    1991 : [0.035, 0.032, 0.043, 0.038, 0.045, 0.026, 0.041, 0.038, 0.032, 0.036, 0.041, 0.021, 0.031, 0.035, 0.042, 0.03, 0.035, 0.015, 0.036, 0.039, 0.043, 0.035, 0.04, 0.034, 0.039, 0.037, 0.036, 0.032, 0.04, 0.037, 0.041, 0.043, 0.038, 0.05, 0.036, 0.039, 0.042, 0.034, 0.039, 0.039, 0.04, 0.036, 0.041, 0.037, 0.04, 0.043, 0.042, 0.029, 0.038, 0.031, 0.042] ,
    1992 : [0.039, 0.033, 0.044, 0.037, 0.044, 0.023, 0.038, 0.023, 0.029, 0.027, 0.042, 0.024, 0.041, 0.017, 0.035, 0.03, 0.033, 0.034, 0.038, 0.037, 0.038, 0.034, 0.035, 0.033, 0.042, 0.034, 0.033, 0.028, 0.041, 0.035, 0.035, 0.043, 0.034, 0.029, 0.035, 0.035, 0.039, 0.036, 0.023, 0.035, 0.039, 0.039, 0.019, 0.038, 0.043, 0.035, 0.019, 0.028, 0.034, 0.037, 0.042] ,
    1993 : [0.04, 0.033, 0.044, 0.038, 0.028, 0.038, 0.036, 0.038, 0.032, 0.038, 0.047, 0.029, 0.042, 0.031, 0.036, 0.029, 0.032, 0.038, 0.039, 0.037, 0.041, 0.036, 0.036, 0.033, 0.041, 0.036, 0.031, 0.027, 0.04, 0.034, 0.011, 0.041, 0.036, 0.045, 0.034, 0.036, 0.038, 0.035, 0.037, 0.031, 0.043, 0.042, 0.041, 0.036, 0.046, 0.041, 0.041, 0.027, 0.036, 0.036, 0.042] ,
    1994 : [0.037, 0.033, 0.046, 0.039, 0.028, 0.04, 0.041, 0.039, 0.032, 0.036, 0.041, 0.029, 0.047, 0.034, 0.04, 0.029, 0.036, 0.042, 0.038, 0.038, 0.04, 0.038, 0.037, 0.035, 0.039, 0.04, 0.036, 0.029, 0.044, 0.038, 0.039, 0.043, 0.038, 0.045, 0.037, 0.038, 0.042, 0.035, 0.037, 0.039, 0.039, 0.038, 0.041, 0.039, 0.049, 0.045, 0.039, 0.031, 0.04, 0.037, 0.045] ,
    1995 : [0.013, 0.032, 0.033, 0.014, 0.029, 0.019, 0.04, 0.012, 0.034, 0.026, 0.021, 0.028, 0.042, 0.035, 0.04, 0.032, 0.034, 0.043, 0.042, 0.037, 0.041, 0.037, 0.037, 0.036, 0.041, 0.041, 0.031, 0.025, 0.041, 0.037, 0.038, 0.042, 0.037, 0.045, 0.036, 0.04, 0.043, 0.03, 0.038, 0.041, 0.04, 0.035, 0.044, 0.044, 0.047, 0.043, 0.04, 0.03, 0.041, 0.039, 0.044] ,
    1996 : [0.039, 0.034, 0.046, 0.04, 0.044, 0.019, 0.042, 0.037, 0.032, 0.038, 0.043, 0.02, 0.045, 0.033, 0.044, 0.031, 0.038, 0.026, 0.039, 0.037, 0.04, 0.037, 0.039, 0.037, 0.041, 0.045, 0.035, 0.03, 0.044, 0.038, 0.038, 0.044, 0.036, 0.046, 0.038, 0.038, 0.042, 0.038, 0.036, 0.041, 0.04, 0.032, 0.044, 0.039, 0.051, 0.044, 0.038, 0.031, 0.039, 0.039, 0.043] ,
    1997 : [0.021, 0.036, 0.048, 0.04, 0.017, 0.04, 0.041, 0.038, 0.036, 0.04, 0.047, 0.025, 0.044, 0.034, 0.043, 0.035, 0.041, 0.04, 0.041, 0.038, 0.042, 0.038, 0.035, 0.037, 0.043, 0.039, 0.028, 0.031, 0.041, 0.037, 0.039, 0.03, 0.037, 0.047, 0.038, 0.039, 0.042, 0.035, 0.037, 0.038, 0.042, 0.029, 0.045, 0.039, 0.048, 0.041, 0.041, 0.029, 0.039, 0.039, 0.043] ,
    1998 : [0.041, 0.036, 0.048, 0.042, 0.02, 0.043, 0.042, 0.018, 0.038, 0.042, 0.053, 0.027, 0.046, 0.034, 0.046, 0.035, 0.042, 0.044, 0.04, 0.04, 0.044, 0.039, 0.04, 0.038, 0.046, 0.037, 0.035, 0.024, 0.045, 0.036, 0.041, 0.046, 0.038, 0.049, 0.038, 0.043, 0.044, 0.035, 0.039, 0.037, 0.045, 0.026, 0.047, 0.04, 0.051, 0.04, 0.043, 0.03, 0.042, 0.041, 0.046] ,
    1999 : [0.045, 0.033, 0.05, 0.043, 0.023, 0.041, 0.041, 0.04, 0.038, 0.041, 0.052, 0.025, 0.047, 0.036, 0.047, 0.041, 0.042, 0.047, 0.043, 0.04, 0.043, 0.037, 0.041, 0.037, 0.048, 0.043, 0.035, 0.032, 0.046, 0.039, 0.041, 0.047, 0.038, 0.049, 0.037, 0.045, 0.043, 0.034, 0.039, 0.041, 0.046, 0.034, 0.05, 0.042, 0.052, 0.046, 0.043, 0.032, 0.044, 0.041, 0.049] ,
    2000 : [0.043, 0.029, 0.047, 0.042, 0.026, 0.022, 0.038, 0.038, 0.031, 0.042, 0.053, 0.021, 0.046, 0.033, 0.043, 0.038, 0.043, 0.041, 0.044, 0.036, 0.039, 0.035, 0.037, 0.037, 0.053, 0.04, 0.033, 0.034, 0.044, 0.037, 0.038, 0.046, 0.035, 0.046, 0.036, 0.045, 0.042, 0.03, 0.035, 0.039, 0.045, 0.034, 0.046, 0.04, 0.049, 0.038, 0.039, 0.032, 0.04, 0.039, 0.047] ,
    2001 : [0.039, 0.036, 0.046, 0.042, 0.042, 0.023, 0.04, 0.039, 0.035, 0.039, 0.046, 0.023, 0.045, 0.035, 0.042, 0.036, 0.043, 0.042, 0.04, 0.039, 0.042, 0.039, 0.039, 0.035, 0.043, 0.038, 0.033, 0.035, 0.045, 0.037, 0.041, 0.046, 0.038, 0.046, 0.038, 0.041, 0.043, 0.035, 0.038, 0.042, 0.045, 0.04, 0.045, 0.04, 0.049, 0.04, 0.042, 0.021, 0.041, 0.04, 0.048] ,
    2002 : [0.039, 0.035, 0.047, 0.04, 0.044, 0.025, 0.041, 0.039, 0.019, 0.038, 0.042, 0.024, 0.048, 0.036, 0.042, 0.037, 0.038, 0.041, 0.038, 0.038, 0.042, 0.039, 0.04, 0.037, 0.039, 0.04, 0.034, 0.036, 0.045, 0.036, 0.041, 0.046, 0.039, 0.044, 0.037, 0.042, 0.042, 0.035, 0.039, 0.045, 0.043, 0.046, 0.044, 0.039, 0.04, 0.04, 0.045, 0.031, 0.041, 0.039, 0.047] ,
    2003 : [0.038, 0.036, 0.047, 0.027, 0.043, 0.027, 0.039, 0.038, 0.033, 0.037, 0.04, 0.024, 0.044, 0.035, 0.045, 0.041, 0.039, 0.04, 0.04, 0.038, 0.039, 0.038, 0.04, 0.039, 0.041, 0.04, 0.036, 0.034, 0.045, 0.04, 0.038, 0.048, 0.038, 0.042, 0.039, 0.044, 0.043, 0.037, 0.036, 0.043, 0.04, 0.043, 0.042, 0.041, 0.05, 0.039, 0.041, 0.034, 0.038, 0.04, 0.047] ,
    2004 : [0.038, 0.033, 0.045, 0.038, 0.043, 0.029, 0.037, 0.037, 0.033, 0.039, 0.043, 0.022, 0.045, 0.034, 0.043, 0.036, 0.036, 0.038, 0.039, 0.036, 0.03, 0.037, 0.038, 0.034, 0.042, 0.04, 0.034, 0.038, 0.044, 0.04, 0.037, 0.028, 0.035, 0.041, 0.035, 0.041, 0.04, 0.035, 0.035, 0.041, 0.04, 0.04, 0.042, 0.039, 0.047, 0.037, 0.038, 0.031, 0.036, 0.036, 0.043] ,
    2005 : [0.039, 0.034, 0.045, 0.043, 0.041, 0.031, 0.039, 0.039, 0.035, 0.04, 0.045, 0.023, 0.047, 0.037, 0.045, 0.04, 0.04, 0.042, 0.043, 0.036, 0.041, 0.039, 0.04, 0.037, 0.047, 0.04, 0.036, 0.039, 0.044, 0.039, 0.039, 0.045, 0.038, 0.043, 0.036, 0.035, 0.043, 0.033, 0.038, 0.044, 0.035, 0.041, 0.03, 0.042, 0.041, 0.021, 0.017, 0.031, 0.039, 0.04, 0.046] ,
    2006 : [0.025, 0.034, 0.031, 0.033, 0.028, 0.033, 0.03, 0.018, 0.038, 0.031, 0.048, 0.018, 0.05, 0.034, 0.039, 0.031, 0.029, 0.018, 0.043, 0.032, 0.041, 0.037, 0.035, 0.037, 0.047, 0.046, 0.038, 0.034, 0.046, 0.043, 0.039, 0.046, 0.036, 0.043, 0.038, 0.041, 0.045, 0.038, 0.036, 0.044, 0.044, 0.042, 0.046, 0.041, 0.047, 0.036, 0.04, 0.034, 0.015, 0.025, 0.047] ,
    2007 : [0.042, 0.035, 0.046, 0.04, 0.042, 0.034, 0.041, 0.04, 0.04, 0.041, 0.047, 0.017, 0.05, 0.037, 0.045, 0.04, 0.038, 0.044, 0.04, 0.036, 0.042, 0.038, 0.039, 0.036, 0.043, 0.049, 0.036, 0.039, 0.045, 0.039, 0.04, 0.045, 0.038, 0.045, 0.036, 0.044, 0.04, 0.034, 0.02, 0.044, 0.045, 0.04, 0.049, 0.038, 0.046, 0.038, 0.043, 0.032, 0.043, 0.038, 0.047] ,
    2008 : [0.039, 0.033, 0.047, 0.039, 0.027, 0.047, 0.039, 0.039, 0.037, 0.038, 0.045, 0.023, 0.045, 0.034, 0.04, 0.042, 0.037, 0.041, 0.039, 0.035, 0.04, 0.037, 0.038, 0.035, 0.039, 0.045, 0.036, 0.037, 0.045, 0.035, 0.039, 0.044, 0.037, 0.042, 0.038, 0.04, 0.041, 0.03, 0.037, 0.038, 0.043, 0.039, 0.046, 0.039, 0.045, 0.037, 0.042, 0.031, 0.039, 0.037, 0.047] ,
    2009 : [0.035, 0.036, 0.046, 0.038, 0.027, 0.044, 0.036, 0.038, 0.036, 0.037, 0.037, 0.025, 0.044, 0.034, 0.036, 0.039, 0.038, 0.038, 0.038, 0.034, 0.038, 0.036, 0.035, 0.035, 0.04, 0.043, 0.037, 0.033, 0.024, 0.024, 0.036, 0.031, 0.036, 0.031, 0.036, 0.037, 0.032, 0.029, 0.028, 0.037, 0.037, 0.033, 0.042, 0.032, 0.033, 0.036, 0.031, 0.034, 0.037, 0.034, 0.045] ,
    2010 : [0.039, 0.032, 0.047, 0.041, 0.042, 0.03, 0.04, 0.044, 0.023, 0.039, 0.04, 0.026, 0.041, 0.038, 0.036, 0.041, 0.04, 0.043, 0.041, 0.029, 0.043, 0.038, 0.038, 0.037, 0.042, 0.043, 0.037, 0.037, 0.045, 0.038, 0.041, 0.044, 0.038, 0.043, 0.038, 0.04, 0.041, 0.03, 0.023, 0.042, 0.041, 0.039, 0.043, 0.039, 0.047, 0.038, 0.044, 0.031, 0.04, 0.039, 0.046] ,
    2011 : [0.032, 0.031, 0.048, 0.03, 0.033, 0.032, 0.038, 0.04, 0.039, 0.037, 0.042, 0.028, 0.044, 0.037, 0.022, 0.037, 0.042, 0.034, 0.041, 0.035, 0.041, 0.037, 0.038, 0.036, 0.033, 0.04, 0.039, 0.037, 0.045, 0.037, 0.038, 0.037, 0.037, 0.044, 0.038, 0.039, 0.046, 0.028, 0.038, 0.039, 0.043, 0.034, 0.036, 0.041, 0.047, 0.037, 0.043, 0.028, 0.039, 0.026, 0.047] ,
    2012 : [0.031, 0.028, 0.048, 0.043, 0.033, 0.049, 0.04, 0.039, 0.04, 0.037, 0.04, 0.027, 0.042, 0.039, 0.039, 0.038, 0.044, 0.043, 0.04, 0.035, 0.042, 0.036, 0.04, 0.036, 0.04, 0.041, 0.037, 0.039, 0.047, 0.038, 0.038, 0.047, 0.037, 0.041, 0.037, 0.041, 0.045, 0.032, 0.025, 0.039, 0.039, 0.041, 0.044, 0.028, 0.047, 0.038, 0.042, 0.034, 0.041, 0.039, 0.047] ,
    2013 : [0.029, 0.027, 0.033, 0.029, 0.033, 0.039, 0.037, 0.031, 0.036, 0.03, 0.027, 0.027, 0.028, 0.027, 0.025, 0.028, 0.031, 0.032, 0.024, 0.035, 0.033, 0.036, 0.033, 0.024, 0.029, 0.027, 0.031, 0.037, 0.045, 0.038, 0.037, 0.046, 0.036, 0.038, 0.038, 0.037, 0.041, 0.029, 0.038, 0.04, 0.036, 0.041, 0.04, 0.038, 0.048, 0.038, 0.039, 0.032, 0.038, 0.038, 0.045] ,
    2014 : [0.028, 0.025, 0.046, 0.037, 0.033, 0.045, 0.039, 0.038, 0.038, 0.037, 0.038, 0.027, 0.04, 0.036, 0.037, 0.035, 0.039, 0.039, 0.037, 0.034, 0.04, 0.036, 0.037, 0.034, 0.022, 0.038, 0.037, 0.029, 0.028, 0.036, 0.037, 0.044, 0.036, 0.03, 0.036, 0.027, 0.04, 0.031, 0.038, 0.038, 0.037, 0.038, 0.04, 0.037, 0.045, 0.038, 0.04, 0.034, 0.038, 0.038, 0.044] ,
    2015 : [0.036, 0.015, 0.045, 0.037, 0.033, 0.045, 0.04, 0.039, 0.038, 0.035, 0.036, 0.028, 0.031, 0.037, 0.036, 0.036, 0.029, 0.039, 0.037, 0.034, 0.04, 0.037, 0.038, 0.035, 0.036, 0.036, 0.037, 0.037, 0.044, 0.037, 0.038, 0.044, 0.036, 0.04, 0.037, 0.038, 0.027, 0.03, 0.038, 0.039, 0.038, 0.038, 0.04, 0.037, 0.045, 0.038, 0.04, 0.035, 0.038, 0.038, 0.044] ,
    2016 : [0.04, 0.028, 0.046, 0.038, 0.033, 0.046, 0.04, 0.04, 0.018, 0.038, 0.04, 0.029, 0.04, 0.037, 0.037, 0.035, 0.038, 0.039, 0.022, 0.034, 0.041, 0.029, 0.038, 0.032, 0.038, 0.037, 0.036, 0.036, 0.045, 0.037, 0.037, 0.046, 0.037, 0.041, 0.034, 0.039, 0.04, 0.029, 0.039, 0.039, 0.04, 0.038, 0.043, 0.037, 0.044, 0.037, 0.04, 0.034, 0.038, 0.037, 0.043] ,
    2017 : [0.037, 0.03, 0.048, 0.039, 0.033, 0.046, 0.039, 0.039, 0.035, 0.037, 0.038, 0.029, 0.041, 0.038, 0.032, 0.036, 0.038, 0.039, 0.037, 0.036, 0.04, 0.038, 0.037, 0.034, 0.036, 0.039, 0.04, 0.036, 0.019, 0.037, 0.036, 0.047, 0.037, 0.037, 0.037, 0.038, 0.04, 0.032, 0.038, 0.039, 0.038, 0.038, 0.041, 0.038, 0.046, 0.037, 0.039, 0.036, 0.037, 0.038, 0.046] ,
    2018 : [0.034, 0.023, 0.034, 0.038, 0.033, 0.036, 0.038, 0.037, 0.032, 0.036, 0.037, 0.028, 0.039, 0.033, 0.032, 0.036, 0.038, 0.037, 0.036, 0.031, 0.038, 0.037, 0.037, 0.034, 0.036, 0.032, 0.032, 0.036, 0.019, 0.036, 0.036, 0.048, 0.036, 0.039, 0.029, 0.032, 0.039, 0.03, 0.03, 0.04, 0.037, 0.038, 0.039, 0.037, 0.033, 0.036, 0.037, 0.034, 0.036, 0.038, 0.045]
});


export default dataMap