import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Visualisation from "./containers/Visualisation";
import Analysis from "./containers/Analysis";
import Prediction from "./containers/Prediction";

export default () =>
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/Visualisation" exact component={Visualisation} />
    <Route path="/Analysis" exact component={Analysis} />
    <Route path="/Prediction" exact component={Prediction} />
  </Switch>;