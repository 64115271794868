import React, { Component } from "react";
import ScrollUpButton from "react-scroll-up-button"; 
// import "./Analysis.css";
import ReactEcharts from "echarts-for-react";
import AutoFitImage from 'react-image-autofit-frame';
// import Figure from 'react-bootstrap/Figure';
// import FigureImage from 'react-bootstrap/FigureImage';
// import FigureCaption from 'react-bootstrap/FigureCaption'

// 50 1e-4
import np1281 from  "./ml/1/1. NP128.png";
import np2561 from  "./ml/1/1. NP256.png";
import anpl1 from  "./ml/1/1. ANP128(50)l.png";
import anpd1 from  "./ml/1/1. ANP128(50)d.png";
import anpm1 from  "./ml/1/1. ANP128(50)m.png";
import loss1 from  "./ml/1/1. loss (1).png";
// 50 5e-5
import np1283 from  "./ml/3/NP128.png";
import np2563 from  "./ml/3/NP256.png";
import anpl3 from  "./ml/3/ANP128(50)l.png";
import anpd3 from  "./ml/3/ANP128(50)d.png";
import anpm3 from  "./ml/3/ANP128(50)m.png";
import loss3 from  "./ml/3/loss (1).png";

// 100 5e-5
import np1282 from  "./ml/2/NP128.png";
import np2562 from  "./ml/2/NP256.png";
import anpl2 from  "./ml/2/ANP128(50)l.png";
import anpd2 from  "./ml/2/ANP128(50)d.png";
import anpm2 from  "./ml/2/ANP128(50)m.png";
import loss2 from  "./ml/2/loss.png";

import ImageZoom from 'react-medium-image-zoom';
// import ImageLayout from 'react-image-layout';
// import { render } from 'react-dom';
// import Gallery from 'react-grid-gallery';
// import ScrollUpButton from "react-scroll-up-button"; 
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import mapDataUS from './us-all.js';

// insert image
console.log(np1281);console.log(np2561);
console.log(anpl1);console.log(anpd1);
console.log(anpm1);console.log(loss1);

console.log(np1283);console.log(np2563);
console.log(anpl3);console.log(anpd3);
console.log(anpm3);console.log(loss3);

console.log(np1282);console.log(np2562);
console.log(anpl2);console.log(anpd2);
console.log(anpm2);console.log(loss2);


export class Prediction extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        }
    }


componentDidMount() {
    // this.echartsInstance = this.echartsReactRef.getEchartsInstance();
    // this.zr = this.echartsInstance.getZr();
    //this.zr.on('click', this.onChartClick);
}

onChartClick = (...rest) => {
    console.log('App:onClickChart', rest);
};
    render() {
        return (    
            <div className="Analysis">
            <div className="lander">
                <header className="App-header">
                <h1>Machine Learning-Based Air Quality Prediction</h1>
                
                <h4>Inspired by DeepMind <a href="https://arxiv.org/pdf/1807.01622.pdf">Neural Processes</a> and <a href="https://arxiv.org/pdf/1901.05761.pdf">Attentive Neural Processes</a>, 
                source code is available on GitHub <a href="https://github.com/deepmind/neural-processes">The Neural Process Family</a>.</h4>  
                <h4>Model 1. Neural Processes with hidden size = 128 and 256
                <br />Model 2. Attentive Neural Processes with Laplace, DotProduct, and Multihead attentions</h4>
                <h3>Experiment 1. Context points = 50, Learning rate = 1e-4</h3>
                <h5>Left: loss, Middle: Neural Process (hidden size =128), Right: Neural Process (hidden size =256)</h5>
                <ImageZoom
                        image={{
                        src: loss1,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: loss1,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: np1281,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: np1281,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                    <ImageZoom
                        image={{
                        src: np2561,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: np2561,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                <h5>Left: ANP - Laplace, Middle: ANP - DotProduct, Right: ANP - Multihead</h5>
                    <ImageZoom
                        image={{
                        src: anpl1,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: anpl1,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                    <ImageZoom
                        image={{
                        src: anpd1,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: anpd1,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                    <ImageZoom
                        image={{
                        src: anpm1,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: anpm1,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />                     
                <h3>Experiment 2. Context points = 50, Learning rate = 5e-5</h3>
                <h5>Left: loss, Middle: Neural Process (hidden size =128), Right: Neural Process (hidden size =256)</h5>
                <ImageZoom
                        image={{
                        src: loss3,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: loss3,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: np1283,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: np1283,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                    <ImageZoom
                        image={{
                        src: np2563,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: np2563,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <h5>Left: ANP - Laplace, Middle: ANP - DotProduct, Right: ANP - Multihead</h5>
                    <ImageZoom
                        image={{
                        src: anpl3,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: anpl3,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                    <ImageZoom
                        image={{
                        src: anpd3,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: anpd3,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                    <ImageZoom
                        image={{
                        src: anpm3,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: anpm3,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />            
                <h3>Experiment 3. Context points = 100, Learning rate = 5e-5</h3>
                <h5>Left: loss, Middle: Neural Process (hidden size =128), Right: Neural Process (hidden size =256)</h5>
                <ImageZoom
                        image={{
                        src: loss2,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: loss2,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <ImageZoom
                        image={{
                        src: np1282,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: np1282,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                    <ImageZoom
                        image={{
                        src: np2562,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: np2562,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />
                    <h5>Left: ANP - Laplace, Middle: ANP - DotProduct, Right: ANP - Multihead</h5>
                    <ImageZoom
                        image={{
                        src: anpl2,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: anpl2,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                    <ImageZoom
                        image={{
                        src: anpd2,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: anpd2,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    /> 
                    <ImageZoom
                        image={{
                        src: anpm2,
                        // alt: 'no2histb',
                        className: 'img',
                        style: { width: '22em' , margin: '12px',height:'15em'}
                        }}
                        zoomImage={{
                        src: anpm2,
                        // alt: 'no2histb'
                        }}
                        shouldRespectMaxDimension= {true}
                    />   
                    <ScrollUpButton style={{width: 25,height:25}} ToggledStyle={{right: 10}}/>
                    <h3>Related work</h3>
                    <h4>Neural Processes: 
                    <br/>Garnelo, M., Schwarz, J., Rosenbaum, D., Viola, F., Rezende, D.J., Eslami, S.M. and Teh, Y.W. Neural processes. ICML Workshop on Theoretical Foundations and Applications of Deep Generative Models 2018.</h4>
                    <h4>Attentive Neural Processes: 
                    <br/>Kim, H., Mnih, A., Schwarz, J., Garnelo, M., Eslami, A., Rosenbaum, D., Vinyals, O. and Teh, Y.W. Attentive Neural Processes. In International Conference on Learning Representations 2019.</h4>             
          </header>
        </div>
    </div>
    );
  }
}
export default Prediction;
