export default {
  s3: {
    REGION: "eu-west-2",
    BUCKET: "baileys"
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: "https://oza5vcpjw2.execute-api.eu-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_zs4auN15e",
    APP_CLIENT_ID: "62cjuneilc4lft7jcvtfr3giua",
    IDENTITY_POOL_ID: "eu-west-2:ee79fdf1-e11b-4527-b333-f238c68fd2e1"
  }
};
