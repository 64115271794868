import React, { Component } from "react";
import "./Home.css";
import ReactEcharts from "echarts-for-react";
import echarts from 'echarts';
import styled, { css } from 'styled-components';
import front from  "./figure/front.jpg";
import ScrollUpButton from "react-scroll-up-button";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import mapDataUS from './us-all.js';
import USA1 from './USA1.json';
// import {Motion, spring} from 'react-motion';

require('highcharts/modules/map')(Highcharts);

echarts.registerMap('USA1', USA1, {
  Alaska: {              // 把阿拉斯加移到美国主大陆左下方
      left: -131,
      top: 25,
      width: 15
  },
  Hawaii: {
      left: -110,        // 夏威夷
      top: 28,
      width: 5
  },
  'Puerto Rico': {       // 波多黎各
      left: -76,
      top: 26,
      width: 2
  }
});


var sectionStyle = {
  // position: 'center bottom',
  // top: 0,
  // left: 0,
  width: "100%",
  height: "750px",
  backgroundImage: `url(${front})`,
  padding: 0,
  paddingVertical: 0,
  // position: 'absolute',
  resizeMode: "cover",
  style: 'StyleSheet.absoluteFill',
  // alignSelf: "flex-end"

};


// var data = [
//   {
//       name: '2009',
//       data: [['us-ma',230.770357142857],
//       ['us-wa',297.073494236156],
//       ['us-ca',192.717617644456],
//       ['us-or',321.524],
//       ['us-wi',112.876428571429],
//       ['us-me',171.13],
//       ['us-mi',133.856395104895],
//       ['us-nv',290.970333333333],
//       ['us-nm',204.76873015873],
//       ['us-co',236.550781829282],
//       ['us-wy',0.334937946596465],
//       ['us-ks',155.185666666666],
//       ['us-ne',137.909666666667],
//       ['us-ok',163.786666666667],
//       ['us-mo',186.370129148629],
//       ['us-il',201.047681096681],
//       ['us-in',235.801666666667],
//       ['us-vt',99.7900000000001],
//       ['us-ar',13.0172959805116],
//       ['us-tx',102.024497014423],
//       ['us-ri',246.99],
//       ['us-al',159.905],
//       ['us-ms',106.61],
//       ['us-nc',270.529333333333],
//       ['us-va',134.466476190476],
//       ['us-ia',187.515666666667],
//       ['us-md',160.603333333333],
//       ['us-de',143.08],
//       ['us-pa',228.718568074288],
//       ['us-nj',202.646687763344],
//       ['us-ny',144.455745038072],
//       ['us-id',351.9],
//       ['us-sd',0.247962877030162],
//       ['us-ct',315.094],
//       ['us-nh',276.813333333333],
//       ['us-ky',161.850757076257],
//       ['us-oh',165.685909144777],
//       ['us-tn',210.163976190476],
//       ['us-wv',120.03],
//       ['us-dc',208.43],
//       ['us-la',39.4847290640394],
//       ['us-fl',156.803858223734],
//       ['us-ga',216.175],
//       ['us-sc',171.756666666667],
//       ['us-mn',274.20096031746],
//       ['us-mt',228.704],
//       ['us-nd',2.16808222883419],
//       ['us-az',239.23871978022],
//       ['us-ut',223.708666666667],
//       ['us-hi',19.93],
//       ['us-ak',235.445880952381],
//       ['us-pr',0]]
//   }, 
//   {
//       name: '2010',
//       data: [['us-ma',230.770357142857],
//       ['us-wa',297.073494236156],
//       ['us-ca',192.717617644456],
//       ['us-or',321.524],
//       ['us-wi',112.876428571429],
//       ['us-me',171.13],
//       ['us-mi',133.856395104895],
//       ['us-nv',290.970333333333],
//       ['us-nm',204.76873015873],
//       ['us-co',236.550781829282],
//       ['us-wy',0.334937946596465],
//       ['us-ks',155.185666666666],
//       ['us-ne',137.909666666667],
//       ['us-ok',163.786666666667],
//       ['us-mo',186.370129148629],
//       ['us-il',201.047681096681],
//       ['us-in',235.801666666667],
//       ['us-vt',99.7900000000001],
//       ['us-ar',13.0172959805116],
//       ['us-tx',102.024497014423],
//       ['us-ri',246.99],
//       ['us-al',159.905],
//       ['us-ms',106.61],
//       ['us-nc',270.529333333333],
//       ['us-va',134.466476190476],
//       ['us-ia',187.515666666667],
//       ['us-md',160.603333333333],
//       ['us-de',143.08],
//       ['us-pa',228.718568074288],
//       ['us-nj',202.646687763344],
//       ['us-ny',144.455745038072],
//       ['us-id',351.9],
//       ['us-sd',0.247962877030162],
//       ['us-ct',315.094],
//       ['us-nh',276.813333333333],
//       ['us-ky',161.850757076257],
//       ['us-oh',165.685909144777],
//       ['us-tn',210.163976190476],
//       ['us-wv',120.03],
//       ['us-dc',208.43],
//       ['us-la',39.4847290640394],
//       ['us-fl',156.803858223734],
//       ['us-ga',216.175],
//       ['us-sc',171.756666666667],
//       ['us-mn',274.20096031746],
//       ['us-mt',228.704],
//       ['us-nd',2.16808222883419],
//       ['us-az',239.23871978022],
//       ['us-ut',223.708666666667],
//       ['us-hi',19.93],
//       ['us-ak',235.445880952381],
//       ['us-pr',0]]
//   }
// ];


export class Home extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        map2option:{
          grid: {
          left:0,
          right:'10%',
          top: 0,
          bottom: 0,
          // tooltip: {
          //     trigger: 'axis',
          //     axisPointer: {
          //         type: 'shadow',
          //         label: {
          //             show: true,
          //             formatter: function (params) {
          //                 return params.value.replace('\n', '');
          //             }
          //         }
          //     }
          // }
      },
          timeline:{
            data:[
              '1999', '2000', '2001', '2002', '2003', '2004', '2005', '2006', '2007', '2008', '2009', '2010', '2011', '2012', '2013', '2014', '2015', '2016', '2017', '2018'
            ],
            label: {
              formatter : function(s) {
                  return (new Date(s)).getFullYear();
              },
              rotate: 30
          },
            // label : {
            //   tooltip: {
            //     trigger: 'item',
            //     showDelay: 0,
            //     transitionDuration: 0.2,
            //     formatter: function (params) {
            //         var value = (params.value + '').split('.');
            //         value = value[0].replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, '$1,');
            //         return params.seriesName + '<br/>' + params.name + ': ' + value;
            //     }
            // },
            //   },
            autoPlay : true,
            playInterval : 1000
        },
        options:[
            {
                title : {
                    'text':'1999 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)',
                    'subtext':'Data Source: United States Environmental Protection Agency'
                },
                tooltip : {'trigger':'item'},
                // toolbox : {
                //     'show':true, 
                //     'feature':{
                //         'mark':{'show':true},
                //         'dataView':{'show':true,'readOnly':false},
                //         'restore':{'show':true},
                //         'saveAsImage':{'show':true}
                //     }
                // },
                dataRange: {
                    min: 0,
                    max : 50.4,
                    text:['High','Low'],           // 文本，默认为数值文本
                    calculable : true,
                    x: 'left',
                    splitList: [
                      // {start: 30.5, label: 'Hazardous'},
                      // {start: 15.5, end: 30.4, label: 'Very unhealthy'},
                      // {start: 12.5, end: 15.4, label: 'Unhealthy'},
                      // {start: 9.5, end: 12.4, label: 'Unhealthy forSensitive Groups'},
                      {start: 30.1, end: 40, label: 'Moderate'},
                      {start: 20.1, end: 30, label: 'Moderate'},
                      {start: 12.1, end: 20, label: 'Moderate'},
                      {end: 12, label: 'Good'},
                  ],
                  color: ['#eff3ff','#bdd7e7','#6baed6','#08519c']
                    // colorAxis: {
                    //   min: 0,
                    //   stops: [[0.1, '#00e402'], [0.2,'#ffff04'],[0.3,'#ff7e01'],[0.4,'#ff0201'],[0.6, '#8f3f97'], [1, '#7e0023']]
                    // },
                    // color: ['orangered','yellow','lightskyblue']
                },
                'series': [{
                  'name':'PM2.5',
                  'type':'map',
                  'map': 'USA1',
                  itemStyle:{
                    normal:{
                        label:{
                            show:true,
                            textStyle: {
                               color: "#252525"
                            }
                        }
                    },
                    emphasis:{label:{show:true}}
                },
                  'data': [{'name': 'Alabama', 'value': 20.468},
     {'name': 'Alaska', 'value': 5.207},
     {'name': 'Arizona', 'value': 10.468},
     {'name': 'Arkansas', 'value': 12.775},
     {'name': 'California', 'value': 17.163},
     {'name': 'Colorado', 'value': 6.542},
     {'name': 'Connecticut', 'value': 12.648},
     {'name': 'Delaware', 'value': 15.005},
     {'name': 'District Of Columbia', 'value': 15.971},
     {'name': 'Florida', 'value': 12.424},
     {'name': 'Georgia', 'value': 19.437},
     {'name': 'Hawaii', 'value': 4.773},
     {'name': 'Idaho', 'value': 22.427},
     {'name': 'Illinois', 'value': 17.306},
     {'name': 'Indiana', 'value': 16.528},
     {'name': 'Iowa', 'value': 11.721},
     {'name': 'Kansas', 'value': 12.443},
     {'name': 'Kentucky', 'value': 16.008},
     {'name': 'Louisiana', 'value': 14.513},
     {'name': 'Maine', 'value': 6.298},
     {'name': 'Maryland', 'value': 14.244},
     {'name': 'Massachusetts', 'value': 11.571},
     {'name': 'Michigan', 'value': 13.488},
     {'name': 'Minnesota', 'value': 9.008},
     {'name': 'Mississippi', 'value': 14.392},
     {'name': 'Missouri', 'value': 13.934},
     {'name': 'Montana', 'value': 7.061},
     {'name': 'Nebraska', 'value': 10.759},
     {'name': 'Nevada', 'value': 9.181},
     {'name': 'New Hampshire', 'value': 10.617},
     {'name': 'New Jersey', 'value': 12.461},
     {'name': 'New Mexico', 'value': 8.34},
     {'name': 'New York', 'value': 11.397},
     {'name': 'North Carolina', 'value': 15.535},
     {'name': 'North Dakota', 'value': 7.904},
     {'name': 'Ohio', 'value': 17.614},
     {'name': 'Oklahoma', 'value': 10.529},
     {'name': 'Oregon', 'value': 7.942},
     {'name': 'Pennsylvania', 'value': 14.303},
     {'name': 'Rhode Island', 'value': 9.068},
     {'name': 'South Carolina', 'value': 10.73},
     {'name': 'South Dakota', 'value': 14.151},
     {'name': 'Tennessee', 'value': 7.753},
     {'name': 'Texas', 'value': 16.648},
     {'name': 'Utah', 'value': 11.338},
     {'name': 'Vermont', 'value': 8.616},
     {'name': 'Virginia', 'value': 8.828},
     {'name': 'Washington', 'value': 13.777},
     {'name': 'West Virginia', 'value': 9.533},
     {'name': 'Wisconsin', 'value': 14.56},
     {'name': 'Wyoming', 'value': 13.351},
     {'name': 'Puerto Rico', 'value': 5.423}]}]},
     {'title': {'text': '2000 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 19.481},
        {'name': 'Alaska', 'value': 5.194},
        {'name': 'Arizona', 'value': 10.402},
        {'name': 'Arkansas', 'value': 14.337},
        {'name': 'California', 'value': 15.809},
        {'name': 'Colorado', 'value': 8.022},
        {'name': 'Connecticut', 'value': 13.123},
        {'name': 'Delaware', 'value': 15.259},
        {'name': 'District Of Columbia', 'value': 15.836},
        {'name': 'Florida', 'value': 12.438},
        {'name': 'Georgia', 'value': 16.933},
        {'name': 'Hawaii', 'value': 4.166},
        {'name': 'Idaho', 'value': 17.592},
        {'name': 'Illinois', 'value': 16.393},
        {'name': 'Indiana', 'value': 16.458},
        {'name': 'Iowa', 'value': 11.382},
        {'name': 'Kansas', 'value': 11.399},
        {'name': 'Kentucky', 'value': 16.084},
        {'name': 'Louisiana', 'value': 12.568},
        {'name': 'Maine', 'value': 4.931},
        {'name': 'Maryland', 'value': 16.076},
        {'name': 'Massachusetts', 'value': 11.304},
        {'name': 'Michigan', 'value': 12.734},
        {'name': 'Minnesota', 'value': 10.247},
        {'name': 'Mississippi', 'value': 14.086},
        {'name': 'Missouri', 'value': 14.982},
        {'name': 'Montana', 'value': 8.306},
        {'name': 'Nebraska', 'value': 10.529},
        {'name': 'Nevada', 'value': 8.397},
        {'name': 'New Hampshire', 'value': 8.76},
        {'name': 'New Jersey', 'value': 14.357},
        {'name': 'New Mexico', 'value': 7.051},
        {'name': 'New York', 'value': 12.661},
        {'name': 'North Carolina', 'value': 15.312},
        {'name': 'North Dakota', 'value': 6.198},
        {'name': 'Ohio', 'value': 16.967},
        {'name': 'Oklahoma', 'value': 11.385},
        {'name': 'Oregon', 'value': 8.606},
        {'name': 'Pennsylvania', 'value': 14.621},
        {'name': 'Rhode Island', 'value': 7.773},
        {'name': 'South Carolina', 'value': 10.636},
        {'name': 'South Dakota', 'value': 14.413},
        {'name': 'Tennessee', 'value': 6.775},
        {'name': 'Texas', 'value': 16.167},
        {'name': 'Utah', 'value': 11.247},
        {'name': 'Vermont', 'value': 9.212},
        {'name': 'Virginia', 'value': 7.4},
        {'name': 'Washington', 'value': 13.826},
        {'name': 'West Virginia', 'value': 10.876},
        {'name': 'Wisconsin', 'value': 13.736},
        {'name': 'Wyoming', 'value': 12.711},
        {'name': 'Puerto Rico', 'value': 5.749}]}]},
    {'title': {'text': '2001 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 11.825},
        {'name': 'Alaska', 'value': 4.701},
        {'name': 'Arizona', 'value': 9.03},
        {'name': 'Arkansas', 'value': 13.591},
        {'name': 'California', 'value': 15.154},
        {'name': 'Colorado', 'value': 9.077},
        {'name': 'Connecticut', 'value': 14.016},
        {'name': 'Delaware', 'value': 16.512},
        {'name': 'District Of Columbia', 'value': 15.1},
        {'name': 'Florida', 'value': 10.55},
        {'name': 'Georgia', 'value': 16.177},
        {'name': 'Hawaii', 'value': 3.977},
        {'name': 'Idaho', 'value': 10.585},
        {'name': 'Illinois', 'value': 16.109},
        {'name': 'Indiana', 'value': 16.815},
        {'name': 'Iowa', 'value': 12.126},
        {'name': 'Kansas', 'value': 12.599},
        {'name': 'Kentucky', 'value': 16.7},
        {'name': 'Louisiana', 'value': 11.413},
        {'name': 'Maine', 'value': 6.066},
        {'name': 'Maryland', 'value': 16.512},
        {'name': 'Massachusetts', 'value': 12.775},
        {'name': 'Michigan', 'value': 13.633},
        {'name': 'Minnesota', 'value': 9.581},
        {'name': 'Mississippi', 'value': 12.69},
        {'name': 'Missouri', 'value': 14.111},
        {'name': 'Montana', 'value': 6.546},
        {'name': 'Nebraska', 'value': 10.741},
        {'name': 'Nevada', 'value': 8.78},
        {'name': 'New Hampshire', 'value': 8.315},
        {'name': 'New Jersey', 'value': 14.542},
        {'name': 'New Mexico', 'value': 6.884},
        {'name': 'New York', 'value': 12.995},
        {'name': 'North Carolina', 'value': 14.117},
        {'name': 'North Dakota', 'value': 6.692},
        {'name': 'Ohio', 'value': 17.287},
        {'name': 'Oklahoma', 'value': 11.706},
        {'name': 'Oregon', 'value': 8.09},
        {'name': 'Pennsylvania', 'value': 16.485},
        {'name': 'Rhode Island', 'value': 8.46},
        {'name': 'South Carolina', 'value': 11.65},
        {'name': 'South Dakota', 'value': 13.226},
        {'name': 'Tennessee', 'value': 6.508},
        {'name': 'Texas', 'value': 15.039},
        {'name': 'Utah', 'value': 10.696},
        {'name': 'Vermont', 'value': 10.595},
        {'name': 'Virginia', 'value': 9.025},
        {'name': 'Washington', 'value': 13.868},
        {'name': 'West Virginia', 'value': 9.754},
        {'name': 'Wisconsin', 'value': 13.183},
        {'name': 'Wyoming', 'value': 12.949},
        {'name': 'Puerto Rico', 'value': 5.385}]}]},
    {'title': {'text': '2002 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 15.478},
        {'name': 'Alaska', 'value': 5.079},
        {'name': 'Arizona', 'value': 7.582},
        {'name': 'Arkansas', 'value': 12.27},
        {'name': 'California', 'value': 19.029},
        {'name': 'Colorado', 'value': 8.283},
        {'name': 'Connecticut', 'value': 12.929},
        {'name': 'Delaware', 'value': 14.913},
        {'name': 'District Of Columbia', 'value': 15.301},
        {'name': 'Florida', 'value': 9.7},
        {'name': 'Georgia', 'value': 14.055},
        {'name': 'Hawaii', 'value': 3.796},
        {'name': 'Idaho', 'value': 8.865},
        {'name': 'Illinois', 'value': 14.833},
        {'name': 'Indiana', 'value': 14.904},
        {'name': 'Iowa', 'value': 11.337},
        {'name': 'Kansas', 'value': 11.542},
        {'name': 'Kentucky', 'value': 16.041},
        {'name': 'Louisiana', 'value': 10.309},
        {'name': 'Maine', 'value': 6.068},
        {'name': 'Maryland', 'value': 15.333},
        {'name': 'Massachusetts', 'value': 12.062},
        {'name': 'Michigan', 'value': 10.798},
        {'name': 'Minnesota', 'value': 9.352},
        {'name': 'Mississippi', 'value': 11.923},
        {'name': 'Missouri', 'value': 13.925},
        {'name': 'Montana', 'value': 6.254},
        {'name': 'Nebraska', 'value': 9.613},
        {'name': 'Nevada', 'value': 9.739},
        {'name': 'New Hampshire', 'value': 9.217},
        {'name': 'New Jersey', 'value': 14.114},
        {'name': 'New Mexico', 'value': 7.112},
        {'name': 'New York', 'value': 11.796},
        {'name': 'North Carolina', 'value': 13.451},
        {'name': 'North Dakota', 'value': 5.994},
        {'name': 'Ohio', 'value': 16.064},
        {'name': 'Oklahoma', 'value': 10.712},
        {'name': 'Oregon', 'value': 9.325},
        {'name': 'Pennsylvania', 'value': 14.688},
        {'name': 'Rhode Island', 'value': 8.325},
        {'name': 'South Carolina', 'value': 10.756},
        {'name': 'South Dakota', 'value': 12.421},
        {'name': 'Tennessee', 'value': 6.51},
        {'name': 'Texas', 'value': 14.436},
        {'name': 'Utah', 'value': 10.614},
        {'name': 'Vermont', 'value': 10.593},
        {'name': 'Virginia', 'value': 5.147},
        {'name': 'Washington', 'value': 13.006},
        {'name': 'West Virginia', 'value': 9.509},
        {'name': 'Wisconsin', 'value': 12.737},
        {'name': 'Wyoming', 'value': 10.989},
        {'name': 'Puerto Rico', 'value': 4.713}]}]},
    {'title': {'text': '2003 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 15.602},
        {'name': 'Alaska', 'value': 4.644},
        {'name': 'Arizona', 'value': 6.784},
        {'name': 'Arkansas', 'value': 12.171},
        {'name': 'California', 'value': 15.765},
        {'name': 'Colorado', 'value': 8.591},
        {'name': 'Connecticut', 'value': 13.134},
        {'name': 'Delaware', 'value': 14.853},
        {'name': 'District Of Columbia', 'value': 13.197},
        {'name': 'Florida', 'value': 9.928},
        {'name': 'Georgia', 'value': 14.072},
        {'name': 'Hawaii', 'value': 4.337},
        {'name': 'Idaho', 'value': 7.9},
        {'name': 'Illinois', 'value': 14.642},
        {'name': 'Indiana', 'value': 14.573},
        {'name': 'Iowa', 'value': 11.042},
        {'name': 'Kansas', 'value': 10.525},
        {'name': 'Kentucky', 'value': 14.906},
        {'name': 'Louisiana', 'value': 11.558},
        {'name': 'Maine', 'value': 5.901},
        {'name': 'Maryland', 'value': 14.967},
        {'name': 'Massachusetts', 'value': 12.126},
        {'name': 'Michigan', 'value': 13.203},
        {'name': 'Minnesota', 'value': 10.336},
        {'name': 'Mississippi', 'value': 12.556},
        {'name': 'Missouri', 'value': 13.352},
        {'name': 'Montana', 'value': 7.434},
        {'name': 'Nebraska', 'value': 9.375},
        {'name': 'Nevada', 'value': 9.56},
        {'name': 'New Hampshire', 'value': 8.569},
        {'name': 'New Jersey', 'value': 15.013},
        {'name': 'New Mexico', 'value': 7.236},
        {'name': 'New York', 'value': 11.796},
        {'name': 'North Carolina', 'value': 12.969},
        {'name': 'North Dakota', 'value': 6.666},
        {'name': 'Ohio', 'value': 15.407},
        {'name': 'Oklahoma', 'value': 10.69},
        {'name': 'Oregon', 'value': 10.184},
        {'name': 'Pennsylvania', 'value': 14.621},
        {'name': 'Rhode Island', 'value': 7.904},
        {'name': 'South Carolina', 'value': 11.16},
        {'name': 'South Dakota', 'value': 12.146},
        {'name': 'Tennessee', 'value': 6.742},
        {'name': 'Texas', 'value': 14.535},
        {'name': 'Utah', 'value': 11.578},
        {'name': 'Vermont', 'value': 8.227},
        {'name': 'Virginia', 'value': 8.161},
        {'name': 'Washington', 'value': 13.096},
        {'name': 'West Virginia', 'value': 8.49},
        {'name': 'Wisconsin', 'value': 12.521},
        {'name': 'Wyoming', 'value': 10.061},
        {'name': 'Puerto Rico', 'value': 4.929}]}]},
    {'title': {'text': '2004 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 15.593},
        {'name': 'Alaska', 'value': 11.545},
        {'name': 'Arizona', 'value': 6.289},
        {'name': 'Arkansas', 'value': 11.672},
        {'name': 'California', 'value': 13.668},
        {'name': 'Colorado', 'value': 7.609},
        {'name': 'Connecticut', 'value': 12.179},
        {'name': 'Delaware', 'value': 14.507},
        {'name': 'District Of Columbia', 'value': 13.379},
        {'name': 'Florida', 'value': 10.826},
        {'name': 'Georgia', 'value': 14.554},
        {'name': 'Hawaii', 'value': 3.68},
        {'name': 'Idaho', 'value': 8.065},
        {'name': 'Illinois', 'value': 14.027},
        {'name': 'Indiana', 'value': 14.25},
        {'name': 'Iowa', 'value': 10.228},
        {'name': 'Kansas', 'value': 9.283},
        {'name': 'Kentucky', 'value': 13.888},
        {'name': 'Louisiana', 'value': 11.118},
        {'name': 'Maine', 'value': 5.338},
        {'name': 'Maryland', 'value': 15.395},
        {'name': 'Massachusetts', 'value': 11.425},
        {'name': 'Michigan', 'value': 12.145},
        {'name': 'Minnesota', 'value': 9.565},
        {'name': 'Mississippi', 'value': 12.333},
        {'name': 'Missouri', 'value': 12.16},
        {'name': 'Montana', 'value': 5.43},
        {'name': 'Nebraska', 'value': 8.75},
        {'name': 'Nevada', 'value': 8.433},
        {'name': 'New Hampshire', 'value': 8.391},
        {'name': 'New Jersey', 'value': 13.698},
        {'name': 'New Mexico', 'value': 6.159},
        {'name': 'New York', 'value': 10.877},
        {'name': 'North Carolina', 'value': 13.334},
        {'name': 'North Dakota', 'value': 5.903},
        {'name': 'Ohio', 'value': 14.865},
        {'name': 'Oklahoma', 'value': 9.753},
        {'name': 'Oregon', 'value': 9.678},
        {'name': 'Pennsylvania', 'value': 14.079},
        {'name': 'Rhode Island', 'value': 6.748},
        {'name': 'South Carolina', 'value': 10.336},
        {'name': 'South Dakota', 'value': 13.264},
        {'name': 'Tennessee', 'value': 6.477},
        {'name': 'Texas', 'value': 13.704},
        {'name': 'Utah', 'value': 11.131},
        {'name': 'Vermont', 'value': 12.299},
        {'name': 'Virginia', 'value': 7.991},
        {'name': 'Washington', 'value': 13.095},
        {'name': 'West Virginia', 'value': 8.645},
        {'name': 'Wisconsin', 'value': 13.382},
        {'name': 'Wyoming', 'value': 10.189},
        {'name': 'Puerto Rico', 'value': 4.583}]}]},
    {'title': {'text': '2005 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 16.883},
        {'name': 'Alaska', 'value': 5.525},
        {'name': 'Arizona', 'value': 6.943},
        {'name': 'Arkansas', 'value': 14.102},
        {'name': 'California', 'value': 13.192},
        {'name': 'Colorado', 'value': 8.126},
        {'name': 'Connecticut', 'value': 12.283},
        {'name': 'Delaware', 'value': 14.658},
        {'name': 'District Of Columbia', 'value': 13.561},
        {'name': 'Florida', 'value': 10.807},
        {'name': 'Georgia', 'value': 14.91},
        {'name': 'Hawaii', 'value': 4.22},
        {'name': 'Idaho', 'value': 8.591},
        {'name': 'Illinois', 'value': 15.358},
        {'name': 'Indiana', 'value': 15.986},
        {'name': 'Iowa', 'value': 11.971},
        {'name': 'Kansas', 'value': 10.797},
        {'name': 'Kentucky', 'value': 15.873},
        {'name': 'Louisiana', 'value': 12.717},
        {'name': 'Maine', 'value': 5.323},
        {'name': 'Maryland', 'value': 15.671},
        {'name': 'Massachusetts', 'value': 11.146},
        {'name': 'Michigan', 'value': 13.611},
        {'name': 'Minnesota', 'value': 9.522},
        {'name': 'Mississippi', 'value': 13.9},
        {'name': 'Missouri', 'value': 14.681},
        {'name': 'Montana', 'value': 6.76},
        {'name': 'Nebraska', 'value': 10.826},
        {'name': 'Nevada', 'value': 8.253},
        {'name': 'New Hampshire', 'value': 8.602},
        {'name': 'New Jersey', 'value': 14.274},
        {'name': 'New Mexico', 'value': 6.044},
        {'name': 'New York', 'value': 11.347},
        {'name': 'North Carolina', 'value': 13.881},
        {'name': 'North Dakota', 'value': 5.992},
        {'name': 'Ohio', 'value': 16.876},
        {'name': 'Oklahoma', 'value': 11.07},
        {'name': 'Oregon', 'value': 9.212},
        {'name': 'Pennsylvania', 'value': 14.728},
        {'name': 'Rhode Island', 'value': 7.303},
        {'name': 'South Carolina', 'value': 10.463},
        {'name': 'South Dakota', 'value': 14.203},
        {'name': 'Tennessee', 'value': 7.966},
        {'name': 'Texas', 'value': 15.78},
        {'name': 'Utah', 'value': 11.79},
        {'name': 'Vermont', 'value': 10.452},
        {'name': 'Virginia', 'value': 8.434},
        {'name': 'Washington', 'value': 13.322},
        {'name': 'West Virginia', 'value': 8.298},
        {'name': 'Wisconsin', 'value': 14.874},
        {'name': 'Wyoming', 'value': 11.093},
        {'name': 'Puerto Rico', 'value': 4.21}]}]},
    {'title': {'text': '2006 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 16.384},
        {'name': 'Alaska', 'value': 9.582},
        {'name': 'Arizona', 'value': 6.631},
        {'name': 'Arkansas', 'value': 11.923},
        {'name': 'California', 'value': 12.877},
        {'name': 'Colorado', 'value': 7.819},
        {'name': 'Connecticut', 'value': 11.184},
        {'name': 'Delaware', 'value': 14.273},
        {'name': 'District Of Columbia', 'value': 12.413},
        {'name': 'Florida', 'value': 10.301},
        {'name': 'Georgia', 'value': 14.97},
        {'name': 'Hawaii', 'value': 3.859},
        {'name': 'Idaho', 'value': 7.523},
        {'name': 'Illinois', 'value': 13.627},
        {'name': 'Indiana', 'value': 13.514},
        {'name': 'Iowa', 'value': 10.076},
        {'name': 'Kansas', 'value': 4.762},
        {'name': 'Kentucky', 'value': 14.245},
        {'name': 'Louisiana', 'value': 12.411},
        {'name': 'Maine', 'value': 6.546},
        {'name': 'Maryland', 'value': 14.097},
        {'name': 'Massachusetts', 'value': 9.854},
        {'name': 'Michigan', 'value': 12.051},
        {'name': 'Minnesota', 'value': 8.183},
        {'name': 'Mississippi', 'value': 12.894},
        {'name': 'Missouri', 'value': 12.077},
        {'name': 'Montana', 'value': 8.903},
        {'name': 'Nebraska', 'value': 9.897},
        {'name': 'Nevada', 'value': 8.299},
        {'name': 'New Hampshire', 'value': 7.692},
        {'name': 'New Jersey', 'value': 12.984},
        {'name': 'New Mexico', 'value': 6.121},
        {'name': 'New York', 'value': 10.095},
        {'name': 'North Carolina', 'value': 13.537},
        {'name': 'North Dakota', 'value': 6.541},
        {'name': 'Ohio', 'value': 13.411},
        {'name': 'Oklahoma', 'value': 9.515},
        {'name': 'Oregon', 'value': 7.788},
        {'name': 'Pennsylvania', 'value': 14.976},
        {'name': 'Rhode Island', 'value': 7.08},
        {'name': 'South Carolina', 'value': 9.841},
        {'name': 'South Dakota', 'value': 14.31},
        {'name': 'Tennessee', 'value': 7.583},
        {'name': 'Texas', 'value': 14.202},
        {'name': 'Utah', 'value': 10.907},
        {'name': 'Vermont', 'value': 9.146},
        {'name': 'Virginia', 'value': 7.12},
        {'name': 'Washington', 'value': 12.597},
        {'name': 'West Virginia', 'value': 7.761},
        {'name': 'Wisconsin', 'value': 14.746},
        {'name': 'Wyoming', 'value': 11.612},
        {'name': 'Puerto Rico', 'value': 4.727}]}]},
    {'title': {'text': '2007 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 15.905},
        {'name': 'Alaska', 'value': 8.473},
        {'name': 'Arizona', 'value': 7.235},
        {'name': 'Arkansas', 'value': 12.232},
        {'name': 'California', 'value': 13.723},
        {'name': 'Colorado', 'value': 8.881},
        {'name': 'Connecticut', 'value': 10.676},
        {'name': 'Delaware', 'value': 14.02},
        {'name': 'District Of Columbia', 'value': 10.769},
        {'name': 'Florida', 'value': 10.087},
        {'name': 'Georgia', 'value': 15.008},
        {'name': 'Hawaii', 'value': 3.664},
        {'name': 'Idaho', 'value': 8.181},
        {'name': 'Illinois', 'value': 13.696},
        {'name': 'Indiana', 'value': 14.104},
        {'name': 'Iowa', 'value': 12.364},
        {'name': 'Kansas', 'value': 10.986},
        {'name': 'Kentucky', 'value': 13.73},
        {'name': 'Louisiana', 'value': 11.148},
        {'name': 'Maine', 'value': 6.264},
        {'name': 'Maryland', 'value': 13.571},
        {'name': 'Massachusetts', 'value': 10.816},
        {'name': 'Michigan', 'value': 11.397},
        {'name': 'Minnesota', 'value': 9.151},
        {'name': 'Mississippi', 'value': 12.582},
        {'name': 'Missouri', 'value': 13.055},
        {'name': 'Montana', 'value': 9.293},
        {'name': 'Nebraska', 'value': 11.804},
        {'name': 'Nevada', 'value': 8.298},
        {'name': 'New Hampshire', 'value': 7.298},
        {'name': 'New Jersey', 'value': 12.391},
        {'name': 'New Mexico', 'value': 6.512},
        {'name': 'New York', 'value': 11.281},
        {'name': 'North Carolina', 'value': 10.956},
        {'name': 'North Dakota', 'value': 6.871},
        {'name': 'Ohio', 'value': 14.634},
        {'name': 'Oklahoma', 'value': 11.527},
        {'name': 'Oregon', 'value': 7.983},
        {'name': 'Pennsylvania', 'value': 15.21},
        {'name': 'Rhode Island', 'value': 6.789},
        {'name': 'South Carolina', 'value': 9.481},
        {'name': 'South Dakota', 'value': 12.883},
        {'name': 'Tennessee', 'value': 7.225},
        {'name': 'Texas', 'value': 14.4},
        {'name': 'Utah', 'value': 11.044},
        {'name': 'Vermont', 'value': 11.18},
        {'name': 'Virginia', 'value': 7.769},
        {'name': 'Washington', 'value': 12.145},
        {'name': 'West Virginia', 'value': 7.834},
        {'name': 'Wisconsin', 'value': 14.962},
        {'name': 'Wyoming', 'value': 13.065},
        {'name': 'Puerto Rico', 'value': 4.689}]}]},
    {'title': {'text': '2008 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 8.859},
        {'name': 'Alaska', 'value': 8.554},
        {'name': 'Arizona', 'value': 7.033},
        {'name': 'Arkansas', 'value': 11.149},
        {'name': 'California', 'value': 14.09},
        {'name': 'Colorado', 'value': 7.256},
        {'name': 'Connecticut', 'value': 10.095},
        {'name': 'Delaware', 'value': 12.876},
        {'name': 'District Of Columbia', 'value': 10.544},
        {'name': 'Florida', 'value': 8.771},
        {'name': 'Georgia', 'value': 12.731},
        {'name': 'Hawaii', 'value': 9.343},
        {'name': 'Idaho', 'value': 8.495},
        {'name': 'Illinois', 'value': 11.825},
        {'name': 'Indiana', 'value': 12.919},
        {'name': 'Iowa', 'value': 11.068},
        {'name': 'Kansas', 'value': 10.344},
        {'name': 'Kentucky', 'value': 10.976},
        {'name': 'Louisiana', 'value': 10.203},
        {'name': 'Maine', 'value': 3.939},
        {'name': 'Maryland', 'value': 12.28},
        {'name': 'Massachusetts', 'value': 10.104},
        {'name': 'Michigan', 'value': 10.445},
        {'name': 'Minnesota', 'value': 8.844},
        {'name': 'Mississippi', 'value': 11.296},
        {'name': 'Missouri', 'value': 12.072},
        {'name': 'Montana', 'value': 7.026},
        {'name': 'Nebraska', 'value': 11.201},
        {'name': 'Nevada', 'value': 8.059},
        {'name': 'New Hampshire', 'value': 7.438},
        {'name': 'New Jersey', 'value': 11.666},
        {'name': 'New Mexico', 'value': 6.917},
        {'name': 'New York', 'value': 10.53},
        {'name': 'North Carolina', 'value': 7.92},
        {'name': 'North Dakota', 'value': 6.404},
        {'name': 'Ohio', 'value': 12.519},
        {'name': 'Oklahoma', 'value': 10.698},
        {'name': 'Oregon', 'value': 8.446},
        {'name': 'Pennsylvania', 'value': 14.246},
        {'name': 'Rhode Island', 'value': 5.523},
        {'name': 'South Carolina', 'value': 9.041},
        {'name': 'South Dakota', 'value': 11.704},
        {'name': 'Tennessee', 'value': 6.842},
        {'name': 'Texas', 'value': 12.604},
        {'name': 'Utah', 'value': 10.866},
        {'name': 'Vermont', 'value': 10.528},
        {'name': 'Virginia', 'value': 7.812},
        {'name': 'Washington', 'value': 10.884},
        {'name': 'West Virginia', 'value': 7.443},
        {'name': 'Wisconsin', 'value': 12.564},
        {'name': 'Wyoming', 'value': 11.75},
        {'name': 'Puerto Rico', 'value': 4.381}]}]},
    {'title': {'text': '2009 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 10.954},
        {'name': 'Alaska', 'value': 9.62},
        {'name': 'Arizona', 'value': 6.45},
        {'name': 'Arkansas', 'value': 10.385},
        {'name': 'California', 'value': 12.14},
        {'name': 'Colorado', 'value': 6.885},
        {'name': 'Connecticut', 'value': 8.797},
        {'name': 'Delaware', 'value': 10.949},
        {'name': 'District Of Columbia', 'value': 9.232},
        {'name': 'Florida', 'value': 8.138},
        {'name': 'Georgia', 'value': 11.193},
        {'name': 'Hawaii', 'value': 7.011},
        {'name': 'Idaho', 'value': 8.486},
        {'name': 'Illinois', 'value': 11.783},
        {'name': 'Indiana', 'value': 13.023},
        {'name': 'Iowa', 'value': 11.045},
        {'name': 'Kansas', 'value': 9.252},
        {'name': 'Kentucky', 'value': 9.085},
        {'name': 'Louisiana', 'value': 9.195},
        {'name': 'Maine', 'value': 5.558},
        {'name': 'Maryland', 'value': 10.446},
        {'name': 'Massachusetts', 'value': 9.162},
        {'name': 'Michigan', 'value': 10.061},
        {'name': 'Minnesota', 'value': 8.671},
        {'name': 'Mississippi', 'value': 10.188},
        {'name': 'Missouri', 'value': 11.035},
        {'name': 'Montana', 'value': 7.31},
        {'name': 'Nebraska', 'value': 8.584},
        {'name': 'Nevada', 'value': 6.971},
        {'name': 'New Hampshire', 'value': 7.689},
        {'name': 'New Jersey', 'value': 9.741},
        {'name': 'New Mexico', 'value': 6.642},
        {'name': 'New York', 'value': 9.239},
        {'name': 'North Carolina', 'value': 9.709},
        {'name': 'North Dakota', 'value': 6.388},
        {'name': 'Ohio', 'value': 12.316},
        {'name': 'Oklahoma', 'value': 10.574},
        {'name': 'Oregon', 'value': 8.236},
        {'name': 'Pennsylvania', 'value': 12.523},
        {'name': 'Rhode Island', 'value': 5.833},
        {'name': 'South Carolina', 'value': 7.715},
        {'name': 'South Dakota', 'value': 10.24},
        {'name': 'Tennessee', 'value': 6.458},
        {'name': 'Texas', 'value': 10.933},
        {'name': 'Utah', 'value': 10.09},
        {'name': 'Vermont', 'value': 10.471},
        {'name': 'Virginia', 'value': 7.061},
        {'name': 'Washington', 'value': 9.172},
        {'name': 'West Virginia', 'value': 7.985},
        {'name': 'Wisconsin', 'value': 11.35},
        {'name': 'Wyoming', 'value': 10.609},
        {'name': 'Puerto Rico', 'value': 3.97}]}]},
    {'title': {'text': '2010 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 12.113},
        {'name': 'Alaska', 'value': 8.155},
        {'name': 'Arizona', 'value': 6.986},
        {'name': 'Arkansas', 'value': 11.652},
        {'name': 'California', 'value': 10.405},
        {'name': 'Colorado', 'value': 6.975},
        {'name': 'Connecticut', 'value': 8.297},
        {'name': 'Delaware', 'value': 10.969},
        {'name': 'District Of Columbia', 'value': 10.099},
        {'name': 'Florida', 'value': 8.456},
        {'name': 'Georgia', 'value': 12.076},
        {'name': 'Hawaii', 'value': 7.176},
        {'name': 'Idaho', 'value': 8.639},
        {'name': 'Illinois', 'value': 12.427},
        {'name': 'Indiana', 'value': 13.459},
        {'name': 'Iowa', 'value': 11.968},
        {'name': 'Kansas', 'value': 9.65},
        {'name': 'Kentucky', 'value': 10.052},
        {'name': 'Louisiana', 'value': 9.887},
        {'name': 'Maine', 'value': 5.666},
        {'name': 'Maryland', 'value': 10.926},
        {'name': 'Massachusetts', 'value': 8.91},
        {'name': 'Michigan', 'value': 10.166},
        {'name': 'Minnesota', 'value': 8.658},
        {'name': 'Mississippi', 'value': 11.278},
        {'name': 'Missouri', 'value': 11.549},
        {'name': 'Montana', 'value': 7.832},
        {'name': 'Nebraska', 'value': 9.844},
        {'name': 'Nevada', 'value': 6.105},
        {'name': 'New Hampshire', 'value': 6.903},
        {'name': 'New Jersey', 'value': 9.283},
        {'name': 'New Mexico', 'value': 5.605},
        {'name': 'New York', 'value': 9.187},
        {'name': 'North Carolina', 'value': 10.443},
        {'name': 'North Dakota', 'value': 8.273},
        {'name': 'Ohio', 'value': 13.1},
        {'name': 'Oklahoma', 'value': 10.633},
        {'name': 'Oregon', 'value': 6.129},
        {'name': 'Pennsylvania', 'value': 12.798},
        {'name': 'Rhode Island', 'value': 10.145},
        {'name': 'South Carolina', 'value': 7.538},
        {'name': 'South Dakota', 'value': 10.232},
        {'name': 'Tennessee', 'value': 6.905},
        {'name': 'Texas', 'value': 12.51},
        {'name': 'Utah', 'value': 9.941},
        {'name': 'Vermont', 'value': 8.919},
        {'name': 'Virginia', 'value': 6.9},
        {'name': 'Washington', 'value': 9.78},
        {'name': 'West Virginia', 'value': 6.114},
        {'name': 'Wisconsin', 'value': 12.784},
        {'name': 'Wyoming', 'value': 10.116},
        {'name': 'Puerto Rico', 'value': 3.657}]}]},
    {'title': {'text': '2011 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 11.874},
        {'name': 'Alaska', 'value': 7.2},
        {'name': 'Arizona', 'value': 8.893},
        {'name': 'Arkansas', 'value': 11.341},
        {'name': 'California', 'value': 13.223},
        {'name': 'Colorado', 'value': 7.029},
        {'name': 'Connecticut', 'value': 9.514},
        {'name': 'Delaware', 'value': 18.896},
        {'name': 'District Of Columbia', 'value': 11.018},
        {'name': 'Florida', 'value': 8.784},
        {'name': 'Georgia', 'value': 12.21},
        {'name': 'Hawaii', 'value': 6.01},
        {'name': 'Idaho', 'value': 7.735},
        {'name': 'Illinois', 'value': 12.173},
        {'name': 'Indiana', 'value': 12.461},
        {'name': 'Iowa', 'value': 10.652},
        {'name': 'Kansas', 'value': 11.205},
        {'name': 'Kentucky', 'value': 10.463},
        {'name': 'Louisiana', 'value': 9.63},
        {'name': 'Maine', 'value': 5.924},
        {'name': 'Maryland', 'value': 10.999},
        {'name': 'Massachusetts', 'value': 9.531},
        {'name': 'Michigan', 'value': 9.426},
        {'name': 'Minnesota', 'value': 8.184},
        {'name': 'Mississippi', 'value': 7.71},
        {'name': 'Missouri', 'value': 11.014},
        {'name': 'Montana', 'value': 7.87},
        {'name': 'Nebraska', 'value': 9.341},
        {'name': 'Nevada', 'value': 6.485},
        {'name': 'New Hampshire', 'value': 7.548},
        {'name': 'New Jersey', 'value': 9.62},
        {'name': 'New Mexico', 'value': 6.388},
        {'name': 'New York', 'value': 9.393},
        {'name': 'North Carolina', 'value': 10.115},
        {'name': 'North Dakota', 'value': 6.939},
        {'name': 'Ohio', 'value': 8.247},
        {'name': 'Oklahoma', 'value': 10.759},
        {'name': 'Oregon', 'value': 7.299},
        {'name': 'Pennsylvania', 'value': 12.243},
        {'name': 'Rhode Island', 'value': 5.399},
        {'name': 'South Carolina', 'value': 8.37},
        {'name': 'South Dakota', 'value': 10.236},
        {'name': 'Tennessee', 'value': 6.218},
        {'name': 'Texas', 'value': 11.886},
        {'name': 'Utah', 'value': 10.338},
        {'name': 'Vermont', 'value': 7.704},
        {'name': 'Virginia', 'value': 8.354},
        {'name': 'Washington', 'value': 9.244},
        {'name': 'West Virginia', 'value': 6.812},
        {'name': 'Wisconsin', 'value': 11.652},
        {'name': 'Wyoming', 'value': 9.711},
        {'name': 'Puerto Rico', 'value': 3.872}]}]},
    {'title': {'text': '2012 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 10.309},
        {'name': 'Alaska', 'value': 8.158},
        {'name': 'Arizona', 'value': 8.112},
        {'name': 'Arkansas', 'value': 10.207},
        {'name': 'California', 'value': 17.041},
        {'name': 'Colorado', 'value': 7.473},
        {'name': 'Connecticut', 'value': 8.405},
        {'name': 'Delaware', 'value': 10.169},
        {'name': 'District Of Columbia', 'value': 10.822},
        {'name': 'Florida', 'value': 7.826},
        {'name': 'Georgia', 'value': 10.593},
        {'name': 'Hawaii', 'value': 7.006},
        {'name': 'Idaho', 'value': 9.941},
        {'name': 'Illinois', 'value': 11.029},
        {'name': 'Indiana', 'value': 11.39},
        {'name': 'Iowa', 'value': 10.027},
        {'name': 'Kansas', 'value': 10.044},
        {'name': 'Kentucky', 'value': 9.706},
        {'name': 'Louisiana', 'value': 8.955},
        {'name': 'Maine', 'value': 5.584},
        {'name': 'Maryland', 'value': 9.792},
        {'name': 'Massachusetts', 'value': 8.424},
        {'name': 'Michigan', 'value': 9.082},
        {'name': 'Minnesota', 'value': 7.124},
        {'name': 'Mississippi', 'value': 10.453},
        {'name': 'Missouri', 'value': 10.418},
        {'name': 'Montana', 'value': 9.082},
        {'name': 'Nebraska', 'value': 8.776},
        {'name': 'Nevada', 'value': 7.251},
        {'name': 'New Hampshire', 'value': 5.86},
        {'name': 'New Jersey', 'value': 8.711},
        {'name': 'New Mexico', 'value': 6.424},
        {'name': 'New York', 'value': 8.372},
        {'name': 'North Carolina', 'value': 9.075},
        {'name': 'North Dakota', 'value': 6.405},
        {'name': 'Ohio', 'value': 10.934},
        {'name': 'Oklahoma', 'value': 9.808},
        {'name': 'Oregon', 'value': 6.895},
        {'name': 'Pennsylvania', 'value': 11.197},
        {'name': 'Rhode Island', 'value': 6.337},
        {'name': 'South Carolina', 'value': 7.387},
        {'name': 'South Dakota', 'value': 9.443},
        {'name': 'Tennessee', 'value': 6.707},
        {'name': 'Texas', 'value': 11.11},
        {'name': 'Utah', 'value': 10.085},
        {'name': 'Vermont', 'value': 7.588},
        {'name': 'Virginia', 'value': 7.705},
        {'name': 'Washington', 'value': 8.386},
        {'name': 'West Virginia', 'value': 6.749},
        {'name': 'Wisconsin', 'value': 11.538},
        {'name': 'Wyoming', 'value': 8.738},
        {'name': 'Puerto Rico', 'value': 5.033}]}]},
    {'title': {'text': '2013 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 12.027},
        {'name': 'Alaska', 'value': 9.104},
        {'name': 'Arizona', 'value': 7.461},
        {'name': 'Arkansas', 'value': 9.606},
        {'name': 'California', 'value': 11.086},
        {'name': 'Colorado', 'value': 6.388},
        {'name': 'Connecticut', 'value': 9.774},
        {'name': 'Delaware', 'value': 8.975},
        {'name': 'District Of Columbia', 'value': 9.791},
        {'name': 'Florida', 'value': 7.106},
        {'name': 'Georgia', 'value': 9.568},
        {'name': 'Hawaii', 'value': 6.075},
        {'name': 'Idaho', 'value': 9.403},
        {'name': 'Illinois', 'value': 10.2},
        {'name': 'Indiana', 'value': 10.983},
        {'name': 'Iowa', 'value': 9.782},
        {'name': 'Kansas', 'value': 10.487},
        {'name': 'Kentucky', 'value': 9.17},
        {'name': 'Louisiana', 'value': 8.189},
        {'name': 'Maine', 'value': 6.601},
        {'name': 'Maryland', 'value': 8.943},
        {'name': 'Massachusetts', 'value': 8.452},
        {'name': 'Michigan', 'value': 11.152},
        {'name': 'Minnesota', 'value': 6.743},
        {'name': 'Mississippi', 'value': 9.591},
        {'name': 'Missouri', 'value': 9.79},
        {'name': 'Montana', 'value': 6.919},
        {'name': 'Nebraska', 'value': 8.942},
        {'name': 'Nevada', 'value': 8.944},
        {'name': 'New Hampshire', 'value': 6.822},
        {'name': 'New Jersey', 'value': 8.73},
        {'name': 'New Mexico', 'value': 6.001},
        {'name': 'New York', 'value': 8.175},
        {'name': 'North Carolina', 'value': 8.624},
        {'name': 'North Dakota', 'value': 5.943},
        {'name': 'Ohio', 'value': 10.345},
        {'name': 'Oklahoma', 'value': 9.382},
        {'name': 'Oregon', 'value': 8.913},
        {'name': 'Pennsylvania', 'value': 10.727},
        {'name': 'Rhode Island', 'value': 5.341},
        {'name': 'South Carolina', 'value': 7.257},
        {'name': 'South Dakota', 'value': 8.444},
        {'name': 'Tennessee', 'value': 7.184},
        {'name': 'Texas', 'value': 9.719},
        {'name': 'Utah', 'value': 9.579},
        {'name': 'Vermont', 'value': 10.342},
        {'name': 'Virginia', 'value': 6.469},
        {'name': 'Washington', 'value': 7.682},
        {'name': 'West Virginia', 'value': 7.252},
        {'name': 'Wisconsin', 'value': 10.517},
        {'name': 'Wyoming', 'value': 8.204},
        {'name': 'Puerto Rico', 'value': 3.704}]}]},
    {'title': {'text': '2014 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 10.565},
        {'name': 'Alaska', 'value': 9.925},
        {'name': 'Arizona', 'value': 7.586},
        {'name': 'Arkansas', 'value': 9.39},
        {'name': 'California', 'value': 22.342},
        {'name': 'Colorado', 'value': 6.448},
        {'name': 'Connecticut', 'value': 8.493},
        {'name': 'Delaware', 'value': 9.235},
        {'name': 'District Of Columbia', 'value': 6.535},
        {'name': 'Florida', 'value': 7.563},
        {'name': 'Georgia', 'value': 10.1},
        {'name': 'Hawaii', 'value': 5.39},
        {'name': 'Idaho', 'value': 8.495},
        {'name': 'Illinois', 'value': 10.219},
        {'name': 'Indiana', 'value': 11.583},
        {'name': 'Iowa', 'value': 9.818},
        {'name': 'Kansas', 'value': 10.206},
        {'name': 'Kentucky', 'value': 9.412},
        {'name': 'Louisiana', 'value': 8.797},
        {'name': 'Maine', 'value': 6.592},
        {'name': 'Maryland', 'value': 9.034},
        {'name': 'Massachusetts', 'value': 7.821},
        {'name': 'Michigan', 'value': 9.17},
        {'name': 'Minnesota', 'value': 6.145},
        {'name': 'Mississippi', 'value': 10.071},
        {'name': 'Missouri', 'value': 9.804},
        {'name': 'Montana', 'value': 6.622},
        {'name': 'Nebraska', 'value': 7.964},
        {'name': 'Nevada', 'value': 7.21},
        {'name': 'New Hampshire', 'value': 6.861},
        {'name': 'New Jersey', 'value': 8.613},
        {'name': 'New Mexico', 'value': 5.352},
        {'name': 'New York', 'value': 7.672},
        {'name': 'North Carolina', 'value': 8.877},
        {'name': 'North Dakota', 'value': 4.947},
        {'name': 'Ohio', 'value': 10.719},
        {'name': 'Oklahoma', 'value': 9.0},
        {'name': 'Oregon', 'value': 6.801},
        {'name': 'Pennsylvania', 'value': 10.927},
        {'name': 'Rhode Island', 'value': 5.382},
        {'name': 'South Carolina', 'value': 6.262},
        {'name': 'South Dakota', 'value': 8.738},
        {'name': 'Tennessee', 'value': 5.494},
        {'name': 'Texas', 'value': 9.553},
        {'name': 'Utah', 'value': 9.422},
        {'name': 'Vermont', 'value': 6.948},
        {'name': 'Virginia', 'value': 6.084},
        {'name': 'Washington', 'value': 7.722},
        {'name': 'West Virginia', 'value': 6.473},
        {'name': 'Wisconsin', 'value': 10.451},
        {'name': 'Wyoming', 'value': 8.243},
        {'name': 'Puerto Rico', 'value': 3.727}]}]},
    {'title': {'text': '2015 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 10.161},
        {'name': 'Alaska', 'value': 10.448},
        {'name': 'Arizona', 'value': 6.701},
        {'name': 'Arkansas', 'value': 8.862},
        {'name': 'California', 'value': 16.905},
        {'name': 'Colorado', 'value': 6.339},
        {'name': 'Connecticut', 'value': 8.271},
        {'name': 'Delaware', 'value': 8.522},
        {'name': 'District Of Columbia', 'value': 6.672},
        {'name': 'Florida', 'value': 7.311},
        {'name': 'Georgia', 'value': 8.893},
        {'name': 'Hawaii', 'value': 6.173},
        {'name': 'Idaho', 'value': 9.14},
        {'name': 'Illinois', 'value': 9.597},
        {'name': 'Indiana', 'value': 10.048},
        {'name': 'Iowa', 'value': 9.208},
        {'name': 'Kansas', 'value': 8.391},
        {'name': 'Kentucky', 'value': 9.593},
        {'name': 'Louisiana', 'value': 9.157},
        {'name': 'Maine', 'value': 6.273},
        {'name': 'Maryland', 'value': 8.623},
        {'name': 'Massachusetts', 'value': 7.42},
        {'name': 'Michigan', 'value': 8.937},
        {'name': 'Minnesota', 'value': 5.939},
        {'name': 'Mississippi', 'value': 9.313},
        {'name': 'Missouri', 'value': 8.816},
        {'name': 'Montana', 'value': 8.558},
        {'name': 'Nebraska', 'value': 7.785},
        {'name': 'Nevada', 'value': 6.536},
        {'name': 'New Hampshire', 'value': 7.066},
        {'name': 'New Jersey', 'value': 8.653},
        {'name': 'New Mexico', 'value': 5.194},
        {'name': 'New York', 'value': 7.558},
        {'name': 'North Carolina', 'value': 8.074},
        {'name': 'North Dakota', 'value': 5.824},
        {'name': 'Ohio', 'value': 9.621},
        {'name': 'Oklahoma', 'value': 8.017},
        {'name': 'Oregon', 'value': 7.544},
        {'name': 'Pennsylvania', 'value': 11.125},
        {'name': 'Rhode Island', 'value': 6.144},
        {'name': 'South Carolina', 'value': 7.307},
        {'name': 'South Dakota', 'value': 8.018},
        {'name': 'Tennessee', 'value': 6.194},
        {'name': 'Texas', 'value': 9.037},
        {'name': 'Utah', 'value': 8.871},
        {'name': 'Vermont', 'value': 6.745},
        {'name': 'Virginia', 'value': 5.834},
        {'name': 'Washington', 'value': 7.546},
        {'name': 'West Virginia', 'value': 6.997},
        {'name': 'Wisconsin', 'value': 9.377},
        {'name': 'Wyoming', 'value': 6.783},
        {'name': 'Puerto Rico', 'value': 3.967}]}]},
    {'title': {'text': '2016 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 9.438},
        {'name': 'Alaska', 'value': 7.784},
        {'name': 'Arizona', 'value': 7.029},
        {'name': 'Arkansas', 'value': 8.583},
        {'name': 'California', 'value': 11.461},
        {'name': 'Colorado', 'value': 5.869},
        {'name': 'Connecticut', 'value': 6.816},
        {'name': 'Delaware', 'value': 7.749},
        {'name': 'District Of Columbia', 'value': 9.031},
        {'name': 'Florida', 'value': 7.45},
        {'name': 'Georgia', 'value': 8.87},
        {'name': 'Hawaii', 'value': 6.317},
        {'name': 'Idaho', 'value': 7.059},
        {'name': 'Illinois', 'value': 8.37},
        {'name': 'Indiana', 'value': 8.587},
        {'name': 'Iowa', 'value': 8.218},
        {'name': 'Kansas', 'value': 6.581},
        {'name': 'Kentucky', 'value': 9.239},
        {'name': 'Louisiana', 'value': 8.766},
        {'name': 'Maine', 'value': 5.185},
        {'name': 'Maryland', 'value': 7.255},
        {'name': 'Massachusetts', 'value': 6.873},
        {'name': 'Michigan', 'value': 8.058},
        {'name': 'Minnesota', 'value': 5.32},
        {'name': 'Mississippi', 'value': 8.519},
        {'name': 'Missouri', 'value': 7.558},
        {'name': 'Montana', 'value': 7.214},
        {'name': 'Nebraska', 'value': 7.071},
        {'name': 'Nevada', 'value': 6.329},
        {'name': 'New Hampshire', 'value': 4.797},
        {'name': 'New Jersey', 'value': 8.539},
        {'name': 'New Mexico', 'value': 5.458},
        {'name': 'New York', 'value': 6.368},
        {'name': 'North Carolina', 'value': 7.954},
        {'name': 'North Dakota', 'value': 3.758},
        {'name': 'Ohio', 'value': 8.15},
        {'name': 'Oklahoma', 'value': 7.718},
        {'name': 'Oregon', 'value': 5.661},
        {'name': 'Pennsylvania', 'value': 10.128},
        {'name': 'Rhode Island', 'value': 7.089},
        {'name': 'South Carolina', 'value': 6.48},
        {'name': 'South Dakota', 'value': 8.255},
        {'name': 'Tennessee', 'value': 4.523},
        {'name': 'Texas', 'value': 6.62},
        {'name': 'Utah', 'value': 8.218},
        {'name': 'Vermont', 'value': 6.06},
        {'name': 'Virginia', 'value': 4.57},
        {'name': 'Washington', 'value': 6.806},
        {'name': 'West Virginia', 'value': 5.649},
        {'name': 'Wisconsin', 'value': 8.212},
        {'name': 'Wyoming', 'value': 6.523},
        {'name': 'Puerto Rico', 'value': 3.473}]}]},
    {'title': {'text': '2017 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 9.502},
        {'name': 'Alaska', 'value': 8.208},
        {'name': 'Arizona', 'value': 7.622},
        {'name': 'Arkansas', 'value': 8.993},
        {'name': 'California', 'value': 10.618},
        {'name': 'Colorado', 'value': 6.207},
        {'name': 'Connecticut', 'value': 6.066},
        {'name': 'Delaware', 'value': 7.602},
        {'name': 'District Of Columbia', 'value': 9.579},
        {'name': 'Florida', 'value': 8.031},
        {'name': 'Georgia', 'value': 8.71},
        {'name': 'Hawaii', 'value': 5.469},
        {'name': 'Idaho', 'value': 9.983},
        {'name': 'Illinois', 'value': 8.695},
        {'name': 'Indiana', 'value': 8.27},
        {'name': 'Iowa', 'value': 8.427},
        {'name': 'Kansas', 'value': 7.043},
        {'name': 'Kentucky', 'value': 8.975},
        {'name': 'Louisiana', 'value': 8.369},
        {'name': 'Maine', 'value': 5.525},
        {'name': 'Maryland', 'value': 7.223},
        {'name': 'Massachusetts', 'value': 7.907},
        {'name': 'Michigan', 'value': 8.005},
        {'name': 'Minnesota', 'value': 5.763},
        {'name': 'Mississippi', 'value': 8.741},
        {'name': 'Missouri', 'value': 7.731},
        {'name': 'Montana', 'value': 13.46},
        {'name': 'Nebraska', 'value': 8.346},
        {'name': 'Nevada', 'value': 6.127},
        {'name': 'New Hampshire', 'value': 4.656},
        {'name': 'New Jersey', 'value': 8.268},
        {'name': 'New Mexico', 'value': 5.081},
        {'name': 'New York', 'value': 6.391},
        {'name': 'North Carolina', 'value': 7.806},
        {'name': 'North Dakota', 'value': 5.323},
        {'name': 'Ohio', 'value': 8.108},
        {'name': 'Oklahoma', 'value': 8.202},
        {'name': 'Oregon', 'value': 9.427},
        {'name': 'Pennsylvania', 'value': 10.324},
        {'name': 'Rhode Island', 'value': 10.087},
        {'name': 'South Carolina', 'value': 6.035},
        {'name': 'South Dakota', 'value': 7.689},
        {'name': 'Tennessee', 'value': 5.307},
        {'name': 'Texas', 'value': 7.912},
        {'name': 'Utah', 'value': 8.402},
        {'name': 'Vermont', 'value': 6.704},
        {'name': 'Virginia', 'value': 4.933},
        {'name': 'Washington', 'value': 6.751},
        {'name': 'West Virginia', 'value': 7.905},
        {'name': 'Wisconsin', 'value': 9.419},
        {'name': 'Wyoming', 'value': 5.971},
        {'name': 'Puerto Rico', 'value': 4.519}]}]},
    {'title': {'text': '2018 Annual Mean of Daily PM2.5 Concentration (Unit: μg/m³)'},
     'series': [{'data': [{'name': 'Alabama', 'value': 8.97},
        {'name': 'Alaska', 'value': 6.47},
        {'name': 'Arizona', 'value': 6.572},
        {'name': 'Arkansas', 'value': 6.22},
        {'name': 'California', 'value': 32.418},
        {'name': 'Colorado', 'value': 7.124},
        {'name': 'Connecticut', 'value': 6.892},
        {'name': 'Delaware', 'value': 7.362},
        {'name': 'District Of Columbia', 'value': 8.867},
        {'name': 'Florida', 'value': 7.571},
        {'name': 'Georgia', 'value': 8.459},
        {'name': 'Hawaii', 'value': 5.412},
        {'name': 'Idaho', 'value': 8.315},
        {'name': 'Illinois', 'value': 9.435},
        {'name': 'Indiana', 'value': 8.79},
        {'name': 'Iowa', 'value': 8.495},
        {'name': 'Kansas', 'value': 7.803},
        {'name': 'Kentucky', 'value': 8.918},
        {'name': 'Louisiana', 'value': 8.875},
        {'name': 'Maine', 'value': 5.092},
        {'name': 'Maryland', 'value': 7.146},
        {'name': 'Massachusetts', 'value': 15.2},
        {'name': 'Michigan', 'value': 8.372},
        {'name': 'Minnesota', 'value': 6.426},
        {'name': 'Mississippi', 'value': 8.73},
        {'name': 'Missouri', 'value': 8.045},
        {'name': 'Montana', 'value': 7.741},
        {'name': 'Nebraska', 'value': 7.646},
        {'name': 'Nevada', 'value': 6.724},
        {'name': 'New Hampshire', 'value': 4.746},
        {'name': 'New Jersey', 'value': 7.905},
        {'name': 'New Mexico', 'value': 5.786},
        {'name': 'New York', 'value': 6.382},
        {'name': 'North Carolina', 'value': 7.507},
        {'name': 'North Dakota', 'value': 5.732},
        {'name': 'Ohio', 'value': 8.341},
        {'name': 'Oklahoma', 'value': 5.483},
        {'name': 'Oregon', 'value': 8.837},
        {'name': 'Pennsylvania', 'value': 9.111},
        {'name': 'Rhode Island', 'value': 12.576},
        {'name': 'South Carolina', 'value': 6.818},
        {'name': 'South Dakota', 'value': 7.065},
        {'name': 'Tennessee', 'value': 5.279},
        {'name': 'Texas', 'value': 7.898},
        {'name': 'Utah', 'value': 8.691},
        {'name': 'Vermont', 'value': 5.904},
        {'name': 'Virginia', 'value': 5.775},
        {'name': 'Washington', 'value': 6.699},
        {'name': 'West Virginia', 'value': 7.715},
        {'name': 'Wisconsin', 'value': 8.556},
        {'name': 'Wyoming', 'value': 6.852},
        {'name': 'Puerto Rico', 'value': 4.212}]}]}
      ]},
        
        graphOption: {
            xAxis: {
                type: 'category',
                data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: [820, 932, 901, 934, 1290, 1330, 1320],
                type: 'line'
            }]
        }
    }
}

componentDidMount() {
    this.echartsInstance = this.echartsReactRef.getEchartsInstance();
    this.zr = this.echartsInstance.getZr();
    this.zr.on('click', this.onChartClick);
}

onChartClick = (...rest) => {
    console.log('App:onClickChart', rest);
};
  render() {
    return (    
      <div className="Home">
        <div className="lander">
        <header className="App-header">
          {/* <h1>AQI Matters</h1>
          <p>A serverless air quality visualising app</p> */}
          <section style={sectionStyle}>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <h1>Air Quality Matters</h1>
          <br></br>
          <p>A serverless air quality visualising app</p>
          <p>Created and maintained by <a href="https://www.linkedin.com/in/chen-tian/">Chen Tian</a></p>
      </section>
          {/* <div>
                    <HighchartsReact
                    style={{height: '80vh', width: '80vw',position: "relative"}}
                    options={this.state.mapOptions}
                    constructorType={'mapChart'}
                    highcharts={Highcharts}
                    />
                </div> */}                
              <ScrollUpButton style={{width: 25,height:25}} ToggledStyle={{right: 10}}/>
          </header>
          <h2>Why do we need to care about air quality?</h2>
          <h4>According to <a href="https://www.who.int/airpollution/en/">WHO</a>, 91% of the world's population lives in polluted air.
          <br />Exposure to ambient (outdoor) air pollution leads to 4.2 million deaths every year</h4>
          <h2>What kinds of air pollutants are harmful?</h2>
        <h4>particulate matter (PM), ozone (O3), nitrogen dioxide (NO2), sulfur dioxide (SO2), 
        <br />carbon monoxide (CO), and black carbon are proved to cause health effects.</h4>
        <h2>How is the air quality in the U.S.?</h2>
        <h4>Take a look at the map chart that indicates how PM2.5 concentration of 50 states of the U.S., District of Columbia, and Puerto Rico changes from 1999 to 2018.</h4>
        </div>
        <div>
        <ReactEcharts
                        style={{height: '60vh', width: '1200px', position:'pullCenter'}}
                        ref={(e) => {
                            this.echartsReactRef = e;
                        }}
                        notMerge={true}
                        lazyUpdate={true}
                        option={this.state.map2option}
                        onChartReady={this.onChartReadyCallback}
                        // onEvents={dataFormatter}
                    />
        </div>
        <div className="notes">
              <h6>Copyright [ECharts] Licensed under the Apache License, Version 2.0 (the "License").
              <br />You may not use this file except in compliance with the License. You may obtain a copy of the License at <a rel="license" href="http://www.apache.org/licenses/LICENSE-2.0">http://www.apache.org/licenses/LICENSE-2.0</a> 
              <br />Unless required by applicable law or agreed to in writing, software distributed under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. 
              See the License for the specific language governing permissions and limitations under the License.</h6>
                {/* <a rel="license" href="http://creativecommons.org/licenses/by-nc/3.0/">
                <img alt={"Creative Commons License"} style={{borderwidth:"0"}} src={"https://i.creativecommons.org/l/by-nc/3.0/88x31.png"} /></a> */}
                {/* <br />This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc/3.0/">Creative Commons Attribution-NonCommercial 3.0 Unported License</a>.
              <p>map data source https://www.echartsjs.com/gallery/data/asset/geo/USA.json</p>
          <p>https://www.highcharts.com/blog/tutorials/176-charts-in-motion/</p>
          <p>https://codesandbox.io/s/6j359qpr6r</p>
          <p>add highcharts license https://creativecommons.org/choose/results-one?license_code=by-nc&amp;jurisdiction=&amp;version=3.0&amp;lang=en</p> */}
          <h6>Favicon Source: Vector Designed By 588ku from <a href="https://pngtree.com/freepng/hand-drawn-cartoon-anti-fog-mask-hand-painted-cartoon-anti-fog_3861847.html">Pngtree.com</a>
          <br />Frontcover Source: Image by <a href="https://pixabay.com/users/pixel2013-2364555/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3767893">S. Hermann &amp; F. Richter</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3767893">Pixabay</a></h6>
          </div>
      </div>
    );
  }
}

export default Home;

