import React, {  Component} from "react";
import { Link } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
//import { NavbarHeader, NavbarItems } from "react-bootstrap-navbar";
import { LinkContainer } from "react-router-bootstrap";
import "./App.css";
import Routes from "./Routes";
import logo from "./containers/figure/favicon-32x32.png";

class App extends Component {
  render() {
    return (
      <div className="App container">
      {/* <Navbar bg="dark" variant="dark"> */}
        <Navbar fluid collapseOnSelect bg="dark" variant="dark">
          <Navbar.Header>
            <Navbar.Brand>
            {/* <img
              alt=""
              src="./containers/figure/favicon-32x32.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
      />
      {'Home'} */}
            <Link to="/">Home</Link>            
            </Navbar.Brand>
            {/* <Navbar.Toggle /> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          </Navbar.Header>
          {/* <Navbar.Collapse> */}
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
            <Nav pullCenter>
            <LinkContainer to="/Visualisation">
                <NavItem>Visualisation</NavItem>
              </LinkContainer>
              <LinkContainer to="/Analysis">
                <NavItem>Analysis</NavItem>
              </LinkContainer>
            <LinkContainer to="/Prediction">
                <NavItem>Prediction</NavItem>
              </LinkContainer>


            </Nav>           
          </Navbar.Collapse>
        </Navbar>
        <Routes />
      </div>
    );
  }
}

export default App;
